import { createContext } from 'react';

const initialState: ProvidersContextState = {
  count: 0,
  next: null,
  previous: null,
  results: []
};

const reducer = (
  state: ProvidersContextState,
  action: ProvidersDispatchAction
): ProvidersContextState => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return action.payload;
    case 'SET_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type ProvidersContextType = {
  state: ProvidersContextState;
  dispatch: React.Dispatch<ProvidersDispatchAction>;
};

const Context = createContext<ProvidersContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'Providers';

export { Context, initialState, reducer };
