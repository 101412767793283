import React, { useEffect, useRef, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { Tooltip } from 'react-tooltip';

export enum ButtonSizing {
  LARGE,
  MEDIUM,
  SMALL,
  EXTRA_SMALL,
  ICON,
  ICON_OPTION
}

export enum ButtonVariant {
  PRIMARY,
  SECONDARY,
  PRIMARY_LIGHT,
  BLACK,
  PRIMARY_ELECTRIC,
  ICON_OPTION,
  ERROR
}

const Container = styled.button<{
  variant: ButtonVariant;
  sizing: ButtonSizing;
}>`
  height: 100%;
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: ${(props) => {
    if (props.sizing === ButtonSizing.LARGE) {
      return `${props.theme.spacing.xs}px ${props.theme.spacing.s}px;`;
    } else if (props.sizing === ButtonSizing.MEDIUM) {
      return `12px 20px;`;
    } else if (props.sizing === ButtonSizing.SMALL) {
      return `6px 16px;`;
    } else if (props.sizing === ButtonSizing.ICON) {
      return `0`;
    } else if (props.sizing === ButtonSizing.ICON_OPTION) {
      return `6px 16px;`;
    } else {
      return `0px 14px;`;
    }
  }};

  gap: ${(props) => props.theme.spacing.xxxs}px;
  background: ${(props) => {
    if (props.variant === ButtonVariant.PRIMARY) {
      return props.theme.colors.common.white;
    } else if (props.variant === ButtonVariant.SECONDARY) {
      return props.theme.colors.primary.main;
    } else if (props.variant === ButtonVariant.ICON_OPTION) {
      return props.theme.colors.grey.lighter;
    } else if (props.variant === ButtonVariant.PRIMARY_ELECTRIC) {
      return `#20A1FF`;
    } else {
      return props.theme.colors.common.white;
    }
  }};

  border: 1px solid
    ${(props) => {
      if (props.variant === ButtonVariant.PRIMARY) {
        return props.theme.colors.primary.main;
      } else if (props.variant === ButtonVariant.SECONDARY) {
        return props.theme.colors.primary.main;
      } else if (
        props.variant === ButtonVariant.PRIMARY_LIGHT ||
        props.variant === ButtonVariant.BLACK ||
        props.variant === ButtonVariant.ICON_OPTION ||
        props.variant === ButtonVariant.PRIMARY_ELECTRIC
      ) {
        return 'transparent';
      } else {
        return props.theme.colors.error.main;
      }
    }};
    
  border-radius: ${(props) => {
    if (props.variant === ButtonVariant.ICON_OPTION) {
      return '40px';
    } else {
      return '10px';
    }
  }};

  max-height: 50px;
  &:disabled {
    cursor: no-drop;
    background-color: rgb(242, 242, 242);
    color: color: hsl(0, 0%, 60%);
    border: 1px solid hsl(0, 0%, 90%);
  }
`;

const StyledText = styled.p<{
  variant: ButtonVariant;
  sizing: ButtonSizing;
  disabled: boolean;
}>`
  margin: 0;
  flex-shrink: 0;
  font-weight: 600;
  font-size: ${(props) => {
    if (props.sizing === ButtonSizing.LARGE) {
      return `15px;`;
    } else if (props.sizing === ButtonSizing.MEDIUM) {
      return `14px`;
    } else {
      return `13px`;
    }
  }};
  line-height: 1.5;
  color: ${(props) => {
    if (props.disabled) {
      return 'color: hsl(0, 0%, 60%);';
    }
    if (props.variant === ButtonVariant.BLACK || props.variant === ButtonVariant.ICON_OPTION) {
      return props.theme.colors.common.black;
    }
    if (props.variant === ButtonVariant.PRIMARY || props.variant === ButtonVariant.PRIMARY_LIGHT) {
      return props.theme.colors.primary.main;
    } else if (
      props.variant === ButtonVariant.SECONDARY ||
      props.variant === ButtonVariant.PRIMARY_ELECTRIC
    ) {
      return props.theme.colors.common.white;
    } else {
      return props.theme.colors.error.main;
    }
  }};
`;

const IconContainer = styled.div`
  display: contents;
  flex-shrink: 0;
`;

const TooltipGlobalStyle = createGlobalStyle`
  .react-tooltip {
    background: #eef4ff !important;
    opacity: 1 !important;
    box-shadow: 3px 4px 4px rgb(0 0 0 / 32%);

    & > div:not(:nth-last-child(-n+2)) {
      padding-bottom: 16px;
    }
  }
`;

type ButtonProps = {
  text?: string;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  variant?: ButtonVariant;
  sizing?: ButtonSizing;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  disabled?: boolean;
  tooltips?: JSX.Element[];
  isTooltipsDisplayed?: boolean;
};

const Button = ({
  text,
  onClick,
  type = 'button',
  variant = ButtonVariant.PRIMARY,
  sizing = ButtonSizing.LARGE,
  leftIcon,
  rightIcon,
  disabled = false,
  tooltips = [],
  isTooltipsDisplayed = false
}: ButtonProps) => {
  const tooltipId = Math.random().toString(36).substring(2, 11);
  const [isTooltipOpen, setIsTooltipOpen] = useState(isTooltipsDisplayed);
  const buttonRef = useRef(null);
  const tooltipRef = useRef(null);
  const isToolTipButton = !!tooltips.length;

  const handleOutsideClick = (e: Event) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(e.target) &&
      tooltipRef.current &&
      !tooltipRef.current.contains(e.target)
    ) {
      setIsTooltipOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleButtonClick = () => {
    onClick && onClick();
    setIsTooltipOpen((prevOpen) => !prevOpen);
  };

  return (
    <React.Fragment>
      <TooltipGlobalStyle />
      <Container
        variant={variant}
        sizing={sizing}
        onClick={handleButtonClick}
        disabled={disabled}
        type={type}
        data-tooltip-id={tooltipId}
        ref={buttonRef}
      >
        {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
        {text && (
          <StyledText variant={variant} sizing={sizing} disabled={disabled}>
            {text}
          </StyledText>
        )}
        {rightIcon && <IconContainer>{rightIcon}</IconContainer>}
      </Container>

      {isToolTipButton && (
        <div ref={tooltipRef}>
          <Tooltip id={tooltipId} isOpen={isTooltipOpen} openOnClick clickable place="right">
            {tooltips.map((tooltip, index) => (
              <div key={index}>{tooltip}</div>
            ))}
          </Tooltip>
        </div>
      )}
    </React.Fragment>
  );
};

export default Button;
