import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { getSelectStyles } from 'utils/style';
import { ButtonVariant } from 'components/common/Button';
import { bankOption, paymentMethodOption } from 'utils/sales';
import useApi from 'services/axios';
import {
  getCustomerCreditNote,
  getCustomerInvoices,
  patchCustomerPayment,
  postCustomerPayment
} from 'services/endpoints';
import { salesPaymentsInvoices } from 'components/array/template';
import { Array } from 'components/array';
import { BlueBoldTextContainer } from 'utils/array';
import { Overflow } from 'components/Tools';
import { AllCompaniesContext } from 'contexts';

import {
  Button,
  CustomDatePicker,
  CustomInput,
  CustomSelect,
  CustomTextArea,
  ModalSidebar,
  SmallLoader
} from '../common';
import { MainContainer } from '../styledComponent';

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const CustomContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Left = styled(Row)`
  justify-content: flex-end;
`;

const CreditNoteDetail = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  margin-top: 8px;
`;

const getFirstCreditNoteTransactionId = (
  data: Partial<Payment>,
  invoiceId: number
): number | null => {
  const index = data?.payments?.findIndex((payment) => payment.invoice_id === invoiceId);

  if (data?.payments?.length > 0 && data?.payments[index]?.credit_note_transactions?.length > 0) {
    return data.payments[index].credit_note_transactions[0].credit_note_id;
  }
  return null;
};

const getFirstCreditNoteTransactionAmount = (data: Partial<Payment>, invoiceId: number): string => {
  const index = data?.payments?.findIndex((payment) => payment.invoice_id === invoiceId);
  if (data?.payments?.length > 0 && data?.payments[index]?.credit_note_transactions?.length > 0) {
    return data.payments[index].credit_note_transactions[0].amount.toString();
  }
  return '0';
};

const getInputAmount = (data: Partial<Payment>, invoiceId: number): string => {
  const index = data?.payments?.findIndex((payment) => payment.invoice_id === invoiceId);
  if (data?.payments?.length > 0 && index !== -1) {
    return data.payments[index].amount.toString();
  }
  return '0';
};

const CustomCreditNoteSelectProcessing: React.FC<{
  filteredCreditNotes: Array<Partial<CreditNotes>>;
  setPaymentDetail: React.Dispatch<React.SetStateAction<Partial<Payment>>>;
  paymentDetail: Partial<Payment>;
  invoice: Partial<Invoice>;
}> = ({ filteredCreditNotes, setPaymentDetail, paymentDetail, invoice }) => {
  const creditNotesPreProcessing = filteredCreditNotes.map((note) => {
    return {
      label: note.note,
      value: note.id
    };
  });

  const creditNoteDefaultID = getFirstCreditNoteTransactionId(paymentDetail, invoice.id);
  const creditNoteDefaultAmount = getFirstCreditNoteTransactionAmount(paymentDetail, invoice.id);

  const [creditNote, setCreditNote] = useState<Option>(
    creditNotesPreProcessing.find((note) => note.value === creditNoteDefaultID)
  );

  const [creditNoteDetails, setCreditNoteDetails] = useState<Partial<CreditNotes>>();

  useEffect(() => {
    setCreditNoteDetails(filteredCreditNotes.find((note) => note.id === creditNote?.value));
  }, [creditNote]);

  const handleChangeAmount = (creditNoteId: number) => (value: string) => {
    const newPayments: paymentDetail[] = paymentDetail?.payments?.map((item) => {
      if (item.invoice_id === invoice.id) {
        return { ...item, credit_note_id: creditNoteId, credit_note_amount: Number(value) };
      } else {
        return item;
      }
    });
    setPaymentDetail({
      ...paymentDetail,
      payments: newPayments
    });
  };

  return (
    <>
      <Row>
        <CustomContainer>
          <TitleSelect>Credit Note</TitleSelect>
          <CustomSelect
            placeholder="-"
            value={creditNote}
            options={creditNotesPreProcessing}
            onChange={(e: Option) => {
              setCreditNote(e);
            }}
            isMulti={false}
            isSearchable={true}
            isDisabled={!!creditNoteDefaultID}
            styles={getSelectStyles(null, '150px', '50px')}
          />
        </CustomContainer>
        {creditNoteDetails && (
          <CustomContainer>
            <TitleSelect>Amount</TitleSelect>
            <CustomInput
              defaultValue={creditNoteDefaultAmount}
              placeholder="0"
              callBack={() => handleChangeAmount(creditNote.value)}
              width="100px"
            />
          </CustomContainer>
        )}
      </Row>
      {creditNoteDetails && (
        <CreditNoteDetail>
          <div>Amount: {creditNoteDetails.amount}</div>
          <div>Amount used: {creditNoteDetails.amount_used}</div>
        </CreditNoteDetail>
      )}
    </>
  );
};

type PaymentModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  payments?: Partial<Payment>;
  callback?: (newPayment: Payment, isNewNote: boolean) => void;
  preSelectedInvoices?: number[];
};

const PaymentModal: React.FC<PaymentModalProps> = ({
  isDisplayed,
  onClose,
  payments = null,
  callback,
  preSelectedInvoices = []
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const [isPreSelectedInvoicesLoading, setIsPreSelectedInvoicesLoading] = useState(true);
  const [paymentDetail, setPaymentDetail] = useState<Partial<Payment>>();
  const [invoices, setInvoices] = useState<Array<Invoice>>();
  const [creditNote, setCreditNote] = useState<Array<CreditNotes>>();
  const [filteredInvoices, setFilteredInvoices] = useState<Array<Partial<Invoice>>>([]);
  const [filteredCreditNotes, setFilteredCreditNotes] = useState<Array<Partial<CreditNotes>>>([]);
  const [selectedArrayElements, setSelectedArrayElements] = useState<number[]>([]);
  const [companyOption, setCompanyOption] = useState<Option>();
  const [paymentMethod, setPaymentMethod] = useState<Option>();
  const [bank, setBank] = useState<Option>();
  const [createdDate, setCreatedDate] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [note, setNote] = useState<string>();
  const { state: allCompaniesContext } = useContext(AllCompaniesContext.Context);
  const { post, patch, get } = useApi();

  const title = payments ? 'Update Payment' : 'New Payment';
  const button = payments ? `Save` : 'Create';

  const companyFormatted = useMemo(
    () =>
      allCompaniesContext.map((company) => ({
        label: company.company_name,
        value: company.id
      })),
    [allCompaniesContext]
  );

  const patchPayment = async () => {
    const newPaymentDetail = await patch(patchCustomerPayment(paymentDetail.id), paymentDetail);
    setPaymentDetail(newPaymentDetail.data);
    callback(newPaymentDetail.data, false);
    onClose();
  };

  const postPayment = async () => {
    const newPaymentDetail = await post(postCustomerPayment, paymentDetail);
    setPaymentDetail(newPaymentDetail.data);
    if (callback) {
      callback(newPaymentDetail.data, true);
    }
    onClose();
  };

  const handleSaveButtonClick = async () => {
    if (payments) {
      patchPayment();
    } else {
      postPayment();
    }
    onClose();
  };

  const handleSelectChange = (name: string, option: Option) => {
    setPaymentDetail({
      ...paymentDetail,
      [name]: option.value
    });
  };

  const handleChangeDatePicker = (name: string) => async (value: number) => {
    setPaymentDetail({
      ...paymentDetail,
      [name]: value
    });
  };

  const handleChange = (name: string) => (value: number) => {
    setPaymentDetail({
      ...paymentDetail,
      [name]: value
    });
  };

  const unselectedCreditNotes = (id: number) => {
    setSelectedArrayElements((prevSelected) => prevSelected.filter((obj) => obj !== id));
    setPaymentDetail({
      ...paymentDetail,
      payments: paymentDetail?.payments?.filter((note) => note.invoice_id !== id)
    });
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedCreditNotes(id);
    } else {
      if (
        !selectedArrayElements?.includes(id) &&
        filteredInvoices.find((filter) => filter.id === id)
      ) {
        setSelectedArrayElements(() => [...selectedArrayElements, id]);
        const currentPayments = paymentDetail?.payments || [];
        const newInvoices = filteredInvoices?.find((invoice) => invoice.id === id);
        setPaymentDetail((prevDetail) => ({
          ...prevDetail,
          payments: [
            ...currentPayments,
            {
              invoice_id: id,
              amount: Number(getInputAmount(payments, newInvoices.id)),
              invoice_reference: newInvoices.reference,
              credit_note_transactions: []
            }
          ]
        }));
      }
    }
  };

  const checkValidAmount = () => {
    if (paymentDetail && paymentDetail?.payments && paymentDetail?.amount !== 0) {
      const totalItemPayment = paymentDetail.payments.reduce(
        (acc, payment) => acc + payment.amount,
        0
      );
      return Number(totalItemPayment) === Number(paymentDetail.amount);
    }
    return false;
  };

  const isValidAmount = !checkValidAmount();

  const handleChangeAmount = (id: number) => (value: number) => {
    const newPayments: paymentDetail[] = paymentDetail?.payments?.map((item) => {
      if (item.invoice_id === id) {
        return { ...item, amount: Number(value) };
      } else {
        return item;
      }
    });

    setPaymentDetail({
      ...paymentDetail,
      payments: newPayments
    });
  };

  const invoicesPreProcessing = filteredInvoices?.map((invoice) => ({
    id: invoice.id,
    background: invoice.invoice_balance_amount <= 0 ? '#f0f0f0' : null,
    reference: {
      value: invoice.reference,
      display: <BlueBoldTextContainer>{invoice.reference}</BlueBoldTextContainer>
    },
    date: {
      value: invoice.invoice_date,
      display: <div>{new Date(invoice.invoice_date).toLocaleDateString('fr-FR')}</div>
    },
    down_payment: {
      value: invoice.down_payment ? invoice.down_payment?.toFixed(2) : '0',
      display: <div>{invoice.down_payment ? invoice.down_payment?.toFixed(2) : '0'}</div>
    },
    balance: {
      value: invoice.invoice_balance_amount ? invoice.invoice_balance_amount?.toFixed(2) : '0',
      display: (
        <div>
          {invoice.invoice_balance_amount ? invoice.invoice_balance_amount?.toFixed(2) : '0'}
        </div>
      )
    },
    price: {
      value: invoice.invoice_amount_with_tax ? invoice.invoice_amount_with_tax?.toFixed(2) : '0',
      display: (
        <div>
          {invoice.invoice_amount_with_tax ? invoice.invoice_amount_with_tax?.toFixed(2) : '0'}
        </div>
      )
    },
    amount: {
      value: getInputAmount(paymentDetail, invoice.id),
      display: (
        <CustomInput
          defaultValue={getInputAmount(paymentDetail, invoice.id)}
          placeholder="0"
          callBack={() => handleChangeAmount(invoice.id)}
          width="100px"
          isDisabled={!selectedArrayElements?.includes(invoice.id)}
        />
      )
    },
    extended: (
      <div>
        <CustomCreditNoteSelectProcessing
          filteredCreditNotes={filteredCreditNotes}
          setPaymentDetail={setPaymentDetail}
          paymentDetail={paymentDetail}
          invoice={invoice}
        />
      </div>
    )
  }));

  useEffect(() => {
    setCompanyOption(companyFormatted.find((company) => company.value === paymentDetail?.company));
    setPaymentMethod(
      paymentMethodOption.find((option) => option.value === paymentDetail?.payment_method)
    );
    setBank(bankOption.find((option) => option.value === paymentDetail?.bank));
    setCreatedDate(paymentDetail?.created_at);
    setAmount(
      payments?.payments
        ? payments?.payments?.reduce((acc, payment) => acc + payment.amount, 0).toString()
        : null
    );
    setNote(paymentDetail?.internal_note);
  }, [paymentDetail, payments]);

  useEffect(() => {
    if (companyOption && invoices && creditNote) {
      setFilteredInvoices(invoices.filter((note) => note.company === companyOption.value));
      setFilteredCreditNotes(creditNote.filter((note) => note.company === companyOption.value));
    }
  }, [companyOption, invoices, creditNote]);

  useEffect(() => {
    if (selectedInvoices.length > 0 && invoices?.length > 0) {
      const preSelectedCompanyName = invoices.find(
        (invoices) => invoices.id === (selectedInvoices.length > 0 ? selectedInvoices[0] : null)
      );

      setPaymentDetail((details) => ({
        ...details,
        company: companyFormatted.find(
          (company) => company.value === preSelectedCompanyName?.company
        )?.value
      }));
    }
  }, [selectedInvoices, invoices]);

  useEffect(() => {
    if (
      selectedInvoices.length > 0 &&
      filteredInvoices?.length > 0 &&
      isPreSelectedInvoicesLoading
    ) {
      selectedInvoices.forEach((id) => handleLineSelected(id, false));
      setIsPreSelectedInvoicesLoading(false);
    }
  }, [selectedInvoices, filteredInvoices, isPreSelectedInvoicesLoading]);

  useEffect(() => {
    if (preSelectedInvoices.length) {
      setIsPreSelectedInvoicesLoading(true);
      setSelectedInvoices(preSelectedInvoices);
    }
  }, [preSelectedInvoices]);

  useEffect(() => {
    (async () => {
      setPaymentDetail(payments);
      const invoices = await get(getCustomerInvoices);
      const creditNote = await get(getCustomerCreditNote);
      setCreditNote(creditNote.data.results);
      setInvoices(invoices.data.results);
      setSelectedArrayElements(
        payments?.payments?.map((checkedElement) => checkedElement.invoice_id) || []
      );
      setIsLoading(false);
    })();
  }, [payments]);

  useEffect(() => {
    if (!isDisplayed) {
      setPaymentDetail(null);
    }
  }, [isDisplayed]);

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={title}>
          {isLoading ? (
            <SmallLoader />
          ) : (
            <Content>
              <CustomContainer>
                <TitleSelect>Company</TitleSelect>
                <CustomSelect
                  placeholder="Company"
                  value={companyOption}
                  options={companyFormatted}
                  onChange={(e) => {
                    handleSelectChange('company', e);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  isDisabled={companyOption}
                  styles={getSelectStyles(null, '200px', '50px')}
                />
              </CustomContainer>
              <Row>
                <CustomContainer>
                  <TitleSelect>Payment method</TitleSelect>
                  <CustomSelect
                    placeholder="Transfer"
                    value={paymentMethod}
                    options={paymentMethodOption}
                    onChange={(e) => {
                      handleSelectChange('payment_method', e);
                    }}
                    isMulti={false}
                    isSearchable={true}
                    styles={getSelectStyles(null, '150px', '50px')}
                  />
                </CustomContainer>
                <CustomContainer>
                  <TitleSelect>Bank</TitleSelect>
                  <CustomSelect
                    placeholder="BNB"
                    value={bank}
                    options={bankOption}
                    onChange={(e) => {
                      handleSelectChange('bank', e);
                    }}
                    isMulti={false}
                    isSearchable={true}
                    styles={getSelectStyles(null, '200px', '50px')}
                  />
                </CustomContainer>
              </Row>
              <Row>
                <CustomContainer>
                  <CustomInput
                    title="Amount"
                    defaultValue={amount}
                    placeholder="0$"
                    callBack={() => handleChange('amount')}
                  />
                </CustomContainer>
                <CustomContainer>
                  <TitleSelect>Creation date</TitleSelect>
                  <CustomDatePicker
                    value={createdDate}
                    isDisabled={false}
                    updatedDate={handleChangeDatePicker('created_at')}
                  />
                </CustomContainer>
              </Row>
              {companyOption && (
                <Overflow>
                  <div>Select all Invoices concerned by this Payment</div>
                  <Array
                    template={salesPaymentsInvoices}
                    content={invoicesPreProcessing}
                    lineSelected={handleLineSelected}
                    extended={true}
                    checkedElements={selectedArrayElements}
                    isSearchable={false}
                  />
                </Overflow>
              )}
              <CustomTextArea
                title="Note (visible for client)"
                value={note}
                placeholder="Click to add note (visible by client)"
                callBack={() => handleChange('internal_note')}
              />
              <Left>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  text={button}
                  onClick={handleSaveButtonClick}
                  disabled={isValidAmount}
                />
              </Left>
            </Content>
          )}
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default PaymentModal;
