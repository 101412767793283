import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApi from 'services/axios';
import { delCompany, getCompanies, postCreateCompany } from 'services/endpoints';
import { AllCompaniesContext, CompaniesContext } from 'contexts';
import { Cross, Download, Trash } from 'svgs';
import { Button, SmallLoader, Tag } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { TagColor } from 'components/common/Tag';

import { Array, clientCompanies } from '../array';
import { MainContainer, Title } from '../styledComponent';
import AccountType from '../AccountType';
import { Overflow, capitalizeFirstLetter } from '../Tools';

enum CompanyType {
  CLIENT = 'client',
  PROVIDER = 'provider',
  PROSPECT = 'prospect'
}

const getStatusColor = (type: string) => {
  if (type === CompanyType.CLIENT) {
    return TagColor.GREEN;
  }
  if (type === CompanyType.PROVIDER) {
    return TagColor.ORANGE;
  }
  return TagColor.BLUE;
};

const Companies: React.FC = () => {
  const [selectedCompanies, setSelectedCompanies] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { state: companiesState, dispatch: companiesAction } = useContext(CompaniesContext.Context);
  const { state: allCompaniesState, dispatch: allCompaniesAction } = useContext(
    AllCompaniesContext.Context
  );
  const { post, del, get } = useApi();
  const navigate = useNavigate();

  const updateCustomerCompany = (newPage: any) => {
    companiesAction({
      type: 'UPDATE_DATA',
      payload: newPage
    });
  };

  const goToCompany = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    navigate(`/Clients-Companies/Company?companyId=${id}&companyView=settings`);
  };

  const companiesPreProcessing = companiesState.results.map((company) => ({
    id: company.id,
    company_name: {
      display: <div>{company.company_name}</div>,
      value: company.company_name
    },
    status: {
      display: (
        <Tag text={capitalizeFirstLetter(company.status)} color={getStatusColor(company.status)} />
      ),
      value: company.status
    },
    account_type: {
      display: <AccountType text={company.account_type} />,
      value: company.account_type
    }
  }));

  const createCompany = async () => {
    const newCompany = await post(postCreateCompany, {
      company_name: `New Company - ${allCompaniesState.length + 1}`
    });

    companiesAction({
      type: 'UPDATE_DATA',
      payload: {
        ...companiesState,
        count: companiesState.count + 1,
        results: [newCompany.data, ...companiesState.results]
      }
    });

    allCompaniesAction({
      type: 'UPDATE_ALL_DATA',
      payload: [...allCompaniesState, newCompany.data]
    });
  };

  const unSelectedCompanies = (id: number) => {
    setSelectedCompanies((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteCompany = () => {
    selectedCompanies.map((companyId) => {
      del(delCompany(companyId));
      unSelectedCompanies(companyId);
    });
    const newCompaniesState = companiesState.results.filter(
      (company) => !selectedCompanies.includes(company.id)
    );

    companiesAction({
      type: 'UPDATE_DATA',
      payload: {
        ...companiesState,
        count: companiesState.count - 1,
        results: newCompaniesState
      }
    });

    const newAllCompaniesState = allCompaniesState.filter(
      (company) => !selectedCompanies.includes(company.id)
    );

    allCompaniesAction({
      type: 'UPDATE_ALL_DATA',
      payload: newAllCompaniesState
    });
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedCompanies(id);
    } else {
      if (!selectedCompanies.includes(id)) {
        setSelectedCompanies((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const openNewWindowButtonClick = (id: string) => {
    window.open(`/Clients-Companies/Company?companyId=${id}&companyView=settings`, '_blank');
  };

  useEffect(() => {
    (async () => {
      const companies = await get(getCompanies);
      companiesAction({ type: 'SET_DATA', payload: companies.data });
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Client companies</Title>
      <Overflow>
        <Array
          template={clientCompanies}
          content={companiesPreProcessing}
          lineClicked={goToCompany}
          lineSelected={handleLineSelected}
          pagination={companiesState}
          updatedArrayData={updateCustomerCompany}
          url={getCompanies}
          newWindowUrl={openNewWindowButtonClick}
          tools={
            <>
              <Button
                variant={ButtonVariant.PRIMARY}
                text="Company"
                leftIcon={Cross()}
                onClick={createCompany}
              />
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
              {!!selectedCompanies.length && (
                <Button
                  variant={ButtonVariant.ERROR}
                  text={`Delete (${selectedCompanies.length}) rows`}
                  leftIcon={Trash()}
                  onClick={deleteCompany}
                />
              )}
            </>
          }
        />
      </Overflow>
    </MainContainer>
  );
};

export default Companies;
