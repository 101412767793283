import { createContext } from 'react';

const initialState: CurrencyContext = {
  count: 0,
  next: null,
  previous: null,
  results: []
};

const reducer = (state: CurrencyContext, action: CurrencyDispatchAction): CurrencyContext => {
  switch (action.type) {
    case 'SET_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type CurrencyContextType = {
  state: CurrencyContext;
  dispatch: React.Dispatch<CurrencyDispatchAction>;
};

const Context = createContext<CurrencyContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'Currency';

export { Context, initialState, reducer };
