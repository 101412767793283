import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { AdminsContext, AllProvidersContext } from 'contexts';
import { getSelectStyles } from 'utils/style';
import { ButtonVariant } from 'components/common/Button';
import useApi from 'services/axios';
import { patchProviderCreditNote, postProviderCreditNote } from 'services/endpoints';

import {
  Button,
  CustomDatePicker,
  CustomInput,
  CustomSelect,
  CustomTextArea,
  ModalSidebar
} from '../common';
import { MainContainer } from '../styledComponent';

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const CustomContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Left = styled(Row)`
  justify-content: flex-end;
`;

type ProviderCreditNoteModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  creditNotes: CreditNotes;
  callback?: (newNote: ProviderDeliveryNote, isNewNote: boolean) => void;
};

const ProviderCreditNoteModal: React.FC<ProviderCreditNoteModalProps> = ({
  isDisplayed,
  onClose,
  creditNotes,
  callback
}) => {
  const [creditNoteDetail, setCreditNoteDetail] = useState<Partial<ProviderCreditNotes>>();
  const [provider, setProvider] = useState<Option>();
  const [createdDate, setCreatedDate] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [note, setNote] = useState<string>();
  const [internalNote, setInternalNote] = useState<string>();
  const [additionalComments, setAdditionalComments] = useState<string>();
  const [reason, setReason] = useState<string>();
  const [salesMan, setSalesMan] = useState<Option>();
  const [admin, setAdmin] = useState<Option>();
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { state: allProvidersState } = useContext(AllProvidersContext.Context);
  const { post, patch } = useApi();
  const title = creditNotes ? 'Update Credit Note' : 'New Credit Note';
  const button = creditNotes ? `Save` : 'Create';

  const providerFormatted = allProvidersState.map((provider) => {
    return {
      label: provider.company_name,
      value: provider.id
    };
  });

  const adminFormatted = useMemo(
    () =>
      adminsState.map((admin) => ({
        label: `${admin.last_name} ${admin.first_name}`,
        value: admin.id
      })),
    [adminsState]
  );

  const patchDeliveryNote = async () => {
    const patchCreditNoteData = { ...creditNoteDetail };
    delete patchCreditNoteData.status;
    const newCreditNoteDetail = await patch(
      patchProviderCreditNote(creditNoteDetail.id),
      patchCreditNoteData
    );
    setCreditNoteDetail(newCreditNoteDetail.data);
    callback(newCreditNoteDetail.data, false);
    onClose();
  };

  const postDeliveryNote = async () => {
    if (!creditNoteDetail?.credit_note_date) {
      creditNoteDetail.credit_note_date = new Date();
    }
    const newCreditNoteDetail = await post(postProviderCreditNote(), creditNoteDetail);
    setCreditNoteDetail(newCreditNoteDetail.data);
    callback(newCreditNoteDetail.data, true);
    onClose();
  };

  const handleSaveButtonClick = async () => {
    if (creditNotes) {
      patchDeliveryNote();
    } else {
      postDeliveryNote();
    }
    onClose();
  };

  const handleSelectChange = (name: string, option: Option) => {
    setCreditNoteDetail({
      ...creditNoteDetail,
      [name]: option.value
    });
  };

  const handleChangeDatePicker = (name: string) => async (value: Date) => {
    setCreditNoteDetail({
      ...creditNoteDetail,
      [name]: value
    });
  };

  const handleChange = (name: string) => (value: number) => {
    setCreditNoteDetail({
      ...creditNoteDetail,
      [name]: value
    });
  };

  useEffect(() => {
    setProvider(
      providerFormatted.find((provider) => provider.value === creditNoteDetail?.provider)
    );
    setCreatedDate(creditNoteDetail?.credit_note_date);
    setAmount(creditNoteDetail?.amount);
    setNote(creditNoteDetail?.note);
    setInternalNote(creditNoteDetail?.internal_note);
    setReason(creditNoteDetail?.reason);
    setAdditionalComments(creditNoteDetail?.additional_comments);
    setSalesMan(adminFormatted.find((admin) => admin.value === creditNoteDetail?.sales_person));
    setAdmin(adminFormatted.find((admin) => admin.value === creditNoteDetail?.admin));
  }, [creditNoteDetail]);

  useEffect(() => {
    setCreditNoteDetail(creditNotes);
  }, [creditNotes]);

  useEffect(() => {
    if (!isDisplayed) {
      setCreditNoteDetail(null);
    }
  }, [isDisplayed]);

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={title}>
          <Content>
            <CustomContainer>
              <TitleSelect>Providers</TitleSelect>
              <CustomSelect
                placeholder="provider"
                value={provider}
                options={providerFormatted}
                onChange={(e) => {
                  handleSelectChange('provider', e);
                }}
                styles={getSelectStyles(null, '250px', '50px')}
              />
            </CustomContainer>
            <Row>
              <CustomContainer>
                <TitleSelect>Salesman</TitleSelect>
                <CustomSelect
                  placeholder="Salesman"
                  value={salesMan}
                  options={adminFormatted}
                  onChange={(e) => {
                    handleSelectChange('sales_person', e);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '200px', '50px')}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>Admin</TitleSelect>
                <CustomSelect
                  placeholder="Admin"
                  value={admin}
                  options={adminFormatted}
                  onChange={(e) => {
                    handleSelectChange('admin', e);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '200px', '50px')}
                />
              </CustomContainer>
            </Row>
            <Row>
              <CustomContainer>
                <CustomInput
                  title="Amount"
                  defaultValue={amount}
                  placeholder="0$"
                  callBack={() => handleChange('amount')}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>Creation date</TitleSelect>
                <CustomDatePicker
                  value={createdDate}
                  isDisabled={false}
                  updatedDate={handleChangeDatePicker('date')}
                />
              </CustomContainer>
            </Row>
            <CustomTextArea
              title="Note (visible for client)"
              value={note}
              placeholder="Click to add note (visible by client)"
              callBack={() => handleChange('note')}
            />
            <CustomTextArea
              title="Internal note"
              value={internalNote}
              placeholder="Click to add internal note"
              callBack={() => handleChange('internal_note')}
            />
            <CustomTextArea
              title="Reason"
              value={reason}
              placeholder="Click to add reason"
              callBack={() => handleChange('reason')}
            />
            <CustomTextArea
              title="Additional comments"
              value={additionalComments}
              placeholder="Click to add note (visible by client)"
              callBack={() => handleChange('additional_comments')}
            />
            <Left>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={button}
                onClick={handleSaveButtonClick}
              />
            </Left>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default ProviderCreditNoteModal;
