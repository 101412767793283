import React from 'react';
import styled from 'styled-components';

import { Array, purchaseItems } from 'components/array';
import ItemExtended from 'components/sales/ItemExtended';
import { Overflow } from 'components/Tools';

export const StyledButtonContainer = styled.div`
  display: inline-flex;
  gap: ${(props) => props.theme.spacing.xs}px;
`;

type PurchaseItemsProps = {
  purchase: Purchases;
};

const PurchaseItems: React.FC<PurchaseItemsProps> = ({ purchase }) => {
  const itemsPreProcessing = purchase.provider_purchase_items.map((item) => {
    return {
      id: item?.id,
      product_name: {
        value: item?.item_rendered?.product_name,
        display: item?.item_rendered?.product_name
      },
      reference: {
        value: item?.item_rendered?.product_information.reference,
        display: item?.item_rendered?.product_information.reference
      },
      quantity: { value: item?.quantity, display: item?.quantity },
      unit_cost: { value: item?.unit_cost, display: item?.unit_cost },
      tooling_costs: { value: item?.tooling_costs, display: item?.tooling_costs },
      other_costs: { value: item?.other_costs, display: item?.other_costs },
      total_buying_price: { value: item?.total_buying_price, display: item?.total_buying_price },
      delivery_delay: { value: item?.delivery_delay, display: item?.delivery_delay },
      manufacturing_days: { value: item?.manufacturing_days, display: item?.manufacturing_days },
      extended: <ItemExtended quotationItem={item} />
    };
  });

  return (
    <Overflow>
      <Array
        template={purchaseItems}
        content={itemsPreProcessing}
        extended={true}
        checkboxDisplayed={false}
        isSearchable={false}
      />
    </Overflow>
  );
};

export default PurchaseItems;
