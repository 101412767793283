import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApi from 'services/axios';
import { delProvider, getProviders, postCreateProvider } from 'services/endpoints';
import { AdminsContext, AllProvidersContext, ProvidersContext } from 'contexts';
import { Cross, Download, Trash } from 'svgs';
import { Button, SmallLoader, Tag } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { TagColor } from 'components/common/Tag';

import { Array, clientProviders } from '../array';
import { MainContainer, Title } from '../styledComponent';
import PictureTag from '../PictrureTag';
import AccountType from '../AccountType';
import { Overflow, capitalizeFirstLetter, getInitial } from '../Tools';

enum ProviderType {
  CLIENT = 'client',
  PROSPECT = 'prospect',
  PROVIDER = 'provider'
}

const getStatusColor = (type: string) => {
  if (type === ProviderType.CLIENT) {
    return TagColor.GREEN;
  }
  if (type === ProviderType.PROVIDER) {
    return TagColor.ORANGE;
  }
  return TagColor.BLUE;
};

const Providers: React.FC = () => {
  const [selectedProviders, setSelectedProviders] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { state: providersState, dispatch: providersAction } = useContext(ProvidersContext.Context);
  const { state: allProvidersState, dispatch: allProvidersAction } = useContext(
    AllProvidersContext.Context
  );
  const { state: adminsState } = useContext(AdminsContext.Context);
  const navigate = useNavigate();
  const { post, del, get } = useApi();

  const goToProvider = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    navigate(`/Clients-Providers/Provider?providerId=${id}&providerView=settings`);
  };

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const providersPreProcessing = providersState.results.map((provider) => ({
    id: provider.id,
    company_name: { value: provider.company_name, display: <div>{provider.company_name}</div> },
    status: {
      value: provider.status,
      display: (
        <Tag
          text={capitalizeFirstLetter(provider.status)}
          color={getStatusColor(provider.status)}
        />
      )
    },
    sales_person: {
      value: getAdminInital(provider.sales_person),
      display: <PictureTag text={getAdminInital(provider.sales_person)} imgUrl="" />
    },
    account_type: {
      value: provider.account_type,
      display: <AccountType text={provider.account_type} />
    }
  }));

  const createProvider = async () => {
    const newProvider = await post(postCreateProvider, {
      company_name: 'New Provider - Edit needed'
    });

    providersAction({
      type: 'UPDATE_DATA',
      payload: {
        ...providersState,
        count: providersState.count + 1,
        results: [newProvider.data, ...providersState.results]
      }
    });

    allProvidersAction({
      type: 'UPDATE_ALL_DATA',
      payload: [...allProvidersState, newProvider.data]
    });
  };

  const updateProvider = (newPage: any) => {
    providersAction({
      type: 'UPDATE_DATA',
      payload: newPage
    });
  };

  const unSelectedProviders = (id: number) => {
    setSelectedProviders((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteProvider = () => {
    selectedProviders.map((ProviderId) => {
      del(delProvider(ProviderId));
      unSelectedProviders(ProviderId);
    });
    const newProvidersState = providersState.results.filter(
      (provider) => !selectedProviders.includes(provider.id)
    );

    providersAction({
      type: 'UPDATE_DATA',
      payload: {
        ...providersState,
        count: providersState.count - 1,
        results: newProvidersState
      }
    });

    const newAllProvidersState = allProvidersState.filter(
      (provider) => !selectedProviders.includes(provider.id)
    );

    allProvidersAction({
      type: 'UPDATE_ALL_DATA',
      payload: newAllProvidersState
    });
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedProviders(id);
    } else {
      if (!selectedProviders.includes(id)) {
        setSelectedProviders((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const openNewWindowButtonClick = (id: string) => {
    window.open(`/Clients-Providers/Provider?providerId=${id}&providerView=settings`, '_blank');
  };

  useEffect(() => {
    (async () => {
      const providers = await get(getProviders);
      providersAction({ type: 'SET_DATA', payload: providers.data });
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Providers factories</Title>
      <Overflow>
        <Array
          template={clientProviders}
          content={providersPreProcessing}
          lineClicked={goToProvider}
          lineSelected={handleLineSelected}
          pagination={providersState}
          updatedArrayData={updateProvider}
          url={getProviders}
          newWindowUrl={openNewWindowButtonClick}
          tools={
            <>
              <Button
                variant={ButtonVariant.PRIMARY}
                text="Provider"
                leftIcon={Cross()}
                onClick={createProvider}
              />
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
              {!!selectedProviders.length && (
                <Button
                  variant={ButtonVariant.ERROR}
                  text={`Delete (${selectedProviders.length}) rows`}
                  leftIcon={Trash()}
                  onClick={deleteProvider}
                />
              )}
            </>
          }
        />
      </Overflow>
    </MainContainer>
  );
};

export default Providers;
