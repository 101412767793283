import React from 'react';

const ArrowDown = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9.48914L11.8232 15.3124C11.9209 15.41 12.0791 15.41 12.1768 15.3124L18 9.48914"
        stroke="#232C34"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowDown;
