import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Cross } from 'svgs';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import useApi from 'services/axios';
import { getCompanyActivities } from 'services/endpoints';
import { Array, activitiesCompany } from 'components/array';
import PictureTag from 'components/PictrureTag';
import { AdminsContext } from 'contexts';
import { getInitial } from 'components/Tools';

import { MainContainer } from '../../styledComponent';

const transformActivities = (activities: Activities): ActivityItem[] => {
  const result: ActivityItem[] = [];

  activities.forEach((activity) => {
    (Object.keys(activity) as (keyof Activity)[]).forEach((type) => {
      activity[type].forEach((detail) => {
        result.push({ ...detail, type });
      });
    });
  });

  return result;
};

type CompanyActivitiesProps = {
  companyDetail: Company;
};

const CompanyActivities: React.FC<CompanyActivitiesProps> = ({ companyDetail }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [activities, setActivities] = useState<Activities>();
  const [activitiesTransformed, setActivitiesTransformed] = useState<ActivityItem[]>([]);
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { get } = useApi();
  const navigate = useNavigate();

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const companyActivitiesPreProcessing = activitiesTransformed?.map((activity) => ({
    id: `${activity?.id}-${activity.type}${
      activity?.company_id && activity?.contact_id
        ? `-${activity?.company_id}-${activity?.contact_id}`
        : ''
    }`,
    type: { value: activity?.type, display: <div>{activity?.type}</div> },
    activities: { value: activity?.reference, display: <div>{activity?.reference}</div> },
    date: {
      value: activity?.date,
      display: <div>{new Date(activity?.date).toLocaleDateString('fr-FR')}</div>
    },
    sales_person: {
      value: getAdminInital(activity?.sales_person),
      display: <PictureTag text={getAdminInital(activity?.sales_person)} />
    },
    admin: {
      value: getAdminInital(activity?.admin),
      display: <PictureTag text={getAdminInital(activity?.admin)} />
    },
    status: { value: activity?.status, display: <div>{activity?.status}</div> },
    price: { value: activity?.price.toFixed(2), display: <div>{activity?.price.toFixed(2)}</div> }
  }));

  const goToQuotation = () => {
    navigate(`/Sales-Quotations/Quotation?quotationView=create`);
  };

  const goToOrder = () => {
    navigate(`/Sales-Orders`);
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const type = event.currentTarget.id.split('-')[2];

    switch (type) {
      case 'quotations': {
        const companyId = event.currentTarget.id.split('-')[3];
        const contactId = event.currentTarget.id.split('-')[4];
        navigate(
          `/Sales-Quotations/Quotation?quotationId=${id}&companyId=${companyId}&contactId=${contactId}&quotationView=updates`
        );
        break;
      }
      case 'customer_orders': {
        navigate(`/Sales-Orders/Order?OrderId=${id}`);
        break;
      }
      case 'customer_delivery_notes': {
        navigate(`/Sales-DeliveryNotes`);
        break;
      }
      default: {
        navigate(`/Sales-Invoices`);
        break;
      }
    }
  };

  useEffect(() => {
    if (activities) {
      setActivitiesTransformed(transformActivities(activities));
    }
  }, [activities]);

  useEffect(() => {
    (async () => {
      const companyActivities = await get(getCompanyActivities(companyDetail.id));
      setActivities(companyActivities.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Array
        template={activitiesCompany}
        content={companyActivitiesPreProcessing}
        lineClicked={handleLineClicked}
        checkboxDisplayed={false}
        isSearchable={false}
        tools={
          <>
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Order"
              leftIcon={Cross()}
              onClick={goToOrder}
            />
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Quotation"
              leftIcon={Cross()}
              onClick={goToQuotation}
            />
          </>
        }
      />
    </MainContainer>
  );
};

export default CompanyActivities;
