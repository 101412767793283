import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { AllCompaniesContactContext, AllCompaniesContext, QuotationsContext } from 'contexts';
import { getSelectStyles } from 'utils/style';
import useApi from 'services/axios';
import { getSpecificQuotation, postDuplicateQuotation } from 'services/endpoints';

import { Button, CustomSelect, Modal, SmallLoader } from '../common';
import { MainContainer } from '../styledComponent';

const CustomContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;

type DuplicateQuotationModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
};

const DuplicateQuotationModal: React.FC<DuplicateQuotationModalProps> = ({
  isDisplayed,
  onClose
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [companyOption, setCompanyOption] = useState<Option>();
  const [contact, setContact] = useState<Option>();
  const { state: allCompaniesContext } = useContext(AllCompaniesContext.Context);
  const { state: allCompaniesContactState } = useContext(AllCompaniesContactContext.Context);
  const { state: quotationsState, dispatch: quotationsAction } = useContext(
    QuotationsContext.Context
  );
  const navigate = useNavigate();
  const { get, post } = useApi();

  const companyFormatted = useMemo(
    () =>
      allCompaniesContext.map((company) => ({
        label: company.company_name,
        value: company.id
      })),
    [allCompaniesContext]
  );

  const companiesContactFormatted = useMemo(
    () =>
      allCompaniesContactState
        .filter((contact) => contact.company_id === companyOption?.value)
        .map((contact) => ({
          label: contact.first_name + ' ' + contact.last_name,
          value: contact.id
        })),
    [allCompaniesContactState, companyOption]
  );

  const handleSaveButton = async () => {
    setIsLoading(true);
    const hash = location.hash.substring(1);
    const params = new URLSearchParams(hash);
    const contentId = params.get('content_id');

    if (contentId) {
      const content_id = contentId.split('-')[0];
      const quotationDetail = await get(getSpecificQuotation(Number(content_id)));
      const { company_id, contact_id, id } = quotationDetail.data;

      const newQuotation = await post(postDuplicateQuotation(company_id, contact_id, id), {
        company_id: companyOption.value,
        contact_id: contact.value
      });

      quotationsState.results.unshift(newQuotation.data);

      quotationsAction({
        type: 'UPDATE_DATA',
        payload: {
          ...quotationsState,
          count: quotationsState.count + 1,
          results: quotationsState.results
        }
      });
      navigate(
        `/Sales-Quotations/Quotation?quotationId=${newQuotation.data?.id}&companyId=${newQuotation.data?.company_id}&contactId=${newQuotation.data?.contact_id}&quotationView=updates`
      );
    }
    onClose();
    setIsLoading(false);
  };

  const handleChangeCompany = (e: Option) => {
    if (contact?.value && e.value !== companyOption.value) {
      setContact(null);
    }
    setCompanyOption(e);
  };

  const handleChangeContact = (e: Option) => {
    setContact(e);
  };

  useEffect(() => {
    if (!isDisplayed) {
      setCompanyOption(null);
      setContact(null);
    }
  }, [isDisplayed]);

  return (
    <MainContainer>
      {isDisplayed && (
        <Modal
          isOpen={isDisplayed}
          onClose={onClose}
          title={<p>You are about to duplicate the quotation</p>}
        >
          <>
            <Row>
              <CustomContainer>
                <TitleSelect>Company</TitleSelect>
                <CustomSelect
                  placeholder="Company"
                  value={companyOption}
                  options={companyFormatted}
                  onChange={handleChangeCompany}
                  styles={getSelectStyles(null, '200px', '50px')}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>Contact</TitleSelect>
                <CustomSelect
                  placeholder="Contact"
                  value={contact}
                  options={companiesContactFormatted}
                  onChange={handleChangeContact}
                  styles={getSelectStyles(null, '200px', '50px')}
                />
              </CustomContainer>
            </Row>

            <ButtonWrapper>
              {!isLoading ? (
                <Button
                  text="Duplicate"
                  onClick={handleSaveButton}
                  variant={1}
                  sizing={1}
                  disabled={!contact?.value || !companyOption?.value}
                />
              ) : (
                <SmallLoader />
              )}
            </ButtonWrapper>
          </>
        </Modal>
      )}
    </MainContainer>
  );
};

export default DuplicateQuotationModal;
