import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import {
  Button,
  CustomDatePicker,
  CustomSelect,
  CustomTextArea,
  SmallLoader
} from 'components/common';
import useApi from 'services/axios';
import { getSpecificPurchase, patchPurchase, postProviderPurchasePDF } from 'services/endpoints';
import { Overflow } from 'components/Tools';
import { getSelectStyles } from 'utils/style';
import {
  AdminsContext,
  AllProvidersContactContext,
  AllProvidersContext,
  CurrencyContext
} from 'contexts';
import {
  addressSelectedTemplate,
  contactSelectedTemplate,
  paymentMethodOption,
  taxeRatesOption,
  termPaymentOption
} from 'utils/sales';
import { RelatedDocumentModal } from 'components/modal';
import SettingCard from 'components/SettingCard';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import { Dashboard, Eye, WhiteCross } from 'svgs';

import { MainContainer, Title } from '../../styledComponent';

import PurchaseItems from './PurchaseItems';
import PurchaseRecapPrice from './PurchaseRecapPrice';

export const StyledButtonContainer = styled.div`
  display: inline-flex;
  gap: ${(props) => props.theme.spacing.xs}px;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  gap: 8px;
`;

const CustomContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const Purchase: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseId, setPurchaseId] = useState<string>();
  const [purchase, setPurchase] = useState<Purchases>();
  const [admin, setAdmin] = useState<Option>();
  const [salesMan, setSalesMan] = useState<Option>();
  const [provider, setProviders] = useState<Option>();
  const [currency, setCurrency] = useState<Option>();
  const [paymentMethod, setPaymentMethod] = useState<Option>();
  const [termsPayment, setTermsPayment] = useState<NumberOption>();
  const [taxeRates, setTaxeRates] = useState<NumberOption>();
  const [shippingAddress, setShippingAddress] = useState<Option>();
  const [billingAddress, setBillingAddress] = useState<Option>();
  const [contact, setContact] = useState<Option>();
  const [addressOption, setAddressOption] = useState<Company>();
  const [modalRelatedDocumentDisplayed, setModalRelatedDocument] = useState<boolean>(false);
  const { state: allProvidersState } = useContext(AllProvidersContext.Context);
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { state: allProvidersContactState } = useContext(AllProvidersContactContext.Context);
  const { state: allProvidersContext } = useContext(AllProvidersContext.Context);
  const { state: currencyState } = useContext(CurrencyContext.Context);
  const { get, patch, post } = useApi();

  const adminFormatted = adminsState.map((admin) => ({
    label: `${admin.last_name} ${admin.first_name}`,
    value: admin.id
  }));

  const currencyOption = currencyState.results.map((curr: Currency) => {
    return {
      label: curr.name,
      value: curr.id
    };
  });

  const providerFormatted = allProvidersState.map((provider) => {
    return {
      label: provider.company_name,
      value: provider.id
    };
  });

  const shippingAddressOptionFormatted = useMemo(
    () =>
      addressOption?.addresses
        ? addressOption.addresses
            .filter((address) => address.address_type === 'shipping')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [addressOption]
  );

  const billingAddressOptionFormatted = useMemo(
    () =>
      addressOption?.addresses
        ? addressOption.addresses
            .filter((address) => address.address_type === 'billing')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [addressOption]
  );

  const providersContactFormatted = useMemo(
    () =>
      allProvidersContactState
        .filter((contact) => contact.provider_company_id === purchase?.provider)
        .map((contact) => ({
          label: contactSelectedTemplate(contact),
          value: contact.id
        })),
    [allProvidersContactState, purchase]
  );

  const onChangeBillingInformation = () => async (value: string | number) => {
    if (value) {
      const newPurchase = await patch(patchPurchase(purchase.id), {
        ...purchase,
        billing_information: value
      });
      setPurchase(newPurchase.data);
    }
  };

  const handleSelectChange = async (name: string, option: Option) => {
    const newPurchase = await patch(patchPurchase(purchase.id), {
      ...purchase,
      [name]: option.value
    });
    setPurchase(newPurchase.data);
  };

  const handleChangeDatePicker = (name: string) => async (value: Date) => {
    const newPurchase = await patch(patchPurchase(purchase.id), {
      ...purchase,
      [name]: value
    });
    setPurchase(newPurchase.data);
  };

  const handleCloseRelatedDocumentModal = () => {
    setModalRelatedDocument(false);
  };

  const handleDocumentButtonClick = () => {
    setModalRelatedDocument(true);
  };

  const handlePDFButtonClick = async () => {
    const PurchaseItemPDF = await post(postProviderPurchasePDF(Number(purchaseId)), {}, 'blob');
    const blob = new Blob([PurchaseItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `purchase-${purchase.reference}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  useEffect(() => {
    setPurchaseId(new URLSearchParams(window.location.search).get('PurchaseId'));
  }, [location]);

  useEffect(() => {
    if (purchase) {
      setAddressOption(allProvidersContext.find((provider) => provider.id === purchase?.provider));
      setProviders(providerFormatted.find((provider) => provider.value === purchase.provider));
      setCurrency(currencyOption.find((option) => option.label === purchase.provider_currency));
      setSalesMan(adminFormatted.find((admin) => admin.value === purchase.sales_person));
      setAdmin(adminFormatted.find((admin) => admin.value === purchase.admin));
      setTermsPayment(
        termPaymentOption.find((option) => option.value === purchase.terms_of_payment)
      );
      setPaymentMethod(
        paymentMethodOption.find((option) => option.value === purchase.payment_method)
      );
      setTaxeRates(taxeRatesOption.find((option) => option.value === purchase.tax_rate));
      setBillingAddress(
        billingAddressOptionFormatted.find((address) => address.value === purchase.billing_address)
      );
      setShippingAddress(
        shippingAddressOptionFormatted.find(
          (address) => address.value === purchase.delivery_address
        )
      );
      setContact(
        providersContactFormatted.find((contact) => contact.value === purchase.provider_contact)
      );
    }
  }, [purchase, billingAddressOptionFormatted, shippingAddressOptionFormatted]);

  useEffect(() => {
    if (purchaseId) {
      (async () => {
        const orderDetail = await get(getSpecificPurchase(Number(purchaseId)));
        setPurchase(orderDetail.data);
        setIsLoading(false);
      })();
    }
  }, [purchaseId]);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Purchase order #{purchase.reference}</Title>
      <InputRowContainer>
        <CustomContainer>
          <TitleSelect>Provider</TitleSelect>
          <CustomSelect
            placeholder="Provider"
            value={provider}
            isDisabled={true}
            styles={getSelectStyles(null, '250px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Salesman</TitleSelect>
          <CustomSelect
            placeholder="Salesman"
            value={salesMan}
            isDisabled={true}
            styles={getSelectStyles(null, '250px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Admin</TitleSelect>
          <CustomSelect
            placeholder="Admin"
            value={admin}
            isDisabled={true}
            styles={getSelectStyles(null, '250px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Purchase Date</TitleSelect>
          <CustomDatePicker
            value={purchase.purchase_date}
            isDisabled={false}
            updatedDate={handleChangeDatePicker('purchase_date')}
          />
        </CustomContainer>
      </InputRowContainer>
      <InputRowContainer>
        <CustomContainer>
          <TitleSelect>Method of payment</TitleSelect>
          <CustomSelect
            placeholder="Method of payment"
            value={paymentMethod}
            options={paymentMethodOption}
            onChange={(e) => {
              handleSelectChange('payment_method', e);
            }}
            styles={getSelectStyles(null, '250px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Terms of payment</TitleSelect>
          <CustomSelect
            placeholder="Terms of payment"
            options={termPaymentOption}
            onChange={(e) => {
              handleSelectChange('terms_of_payment', e);
            }}
            value={termsPayment}
            styles={getSelectStyles(null, '250px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Currency</TitleSelect>
          <CustomSelect
            placeholder="currency"
            value={currency}
            isDisabled={true}
            styles={getSelectStyles(null, '150px', '50px')}
            usePortalStyles={true}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Taxe rate</TitleSelect>
          <CustomSelect
            placeholder="Taxe rate"
            options={taxeRatesOption}
            onChange={(e) => {
              handleSelectChange('tax_rate', e);
            }}
            value={taxeRates}
            styles={getSelectStyles(null, '200px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Validity Date</TitleSelect>
          <CustomDatePicker
            value={purchase.validity_date}
            isDisabled={false}
            updatedDate={handleChangeDatePicker('validity_date')}
          />
        </CustomContainer>
      </InputRowContainer>
      <InputRowContainer>
        <CustomContainer>
          <TitleSelect>Select billing address</TitleSelect>
          <CustomSelect
            placeholder="Billing address (by Name)"
            value={billingAddress}
            options={billingAddressOptionFormatted}
            styles={getSelectStyles(null, '250px', '85px')}
            onChange={(e) => {
              handleSelectChange('billing_address', e);
            }}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Select shipping address</TitleSelect>
          <CustomSelect
            placeholder="Shipping address (by Name)"
            value={shippingAddress}
            options={shippingAddressOptionFormatted}
            styles={getSelectStyles(null, '250px', '85px')}
            onChange={(e) => {
              handleSelectChange('delivery_address', e);
            }}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Contact</TitleSelect>
          <CustomSelect
            placeholder="Contact"
            value={contact}
            options={providersContactFormatted}
            onChange={(e) => {
              handleSelectChange('provider_contact', e);
            }}
            styles={getSelectStyles(null, '250px', '85px')}
          />
        </CustomContainer>
        <CustomTextArea
          title="Billing information"
          value={purchase.billing_information}
          callBack={onChangeBillingInformation}
          placeholder="add comment"
        />
      </InputRowContainer>
      <Overflow>
        <SettingCard
          title="Order detail"
          icon={Dashboard()}
          buttons={
            <>
              <Button
                variant={ButtonVariant.PRIMARY_LIGHT}
                text="View documents"
                leftIcon={Eye()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handleDocumentButtonClick}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                text="Print client PDF"
                leftIcon={WhiteCross()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handlePDFButtonClick}
              />
            </>
          }
        >
          {Object.keys(purchase).length && <PurchaseItems purchase={purchase} />}
        </SettingCard>
      </Overflow>
      <PurchaseRecapPrice purchase={purchase} setPurchase={setPurchase} />
      <CustomTextArea
        title="General comment"
        value={purchase.general_comment}
        placeholder="Click to add comment"
        isDisabled={true}
        isBold={true}
      />
      <RelatedDocumentModal
        title="Purchase related document"
        queryParam={`provider_purchase_id=${purchaseId}`}
        isDisplayed={modalRelatedDocumentDisplayed}
        onClose={handleCloseRelatedDocumentModal}
      />
    </MainContainer>
  );
};

export default Purchase;
