import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { getSelectStyles } from 'utils/style';
import { AllCompaniesContext } from 'contexts';
import useApi from 'services/axios';
import { patchAddress, postCreateAddress } from 'services/endpoints';
import { AddressTypeOption } from 'utils/company';

import { Button, CustomInput, CustomSelect, CustomTextArea, ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const SelectedTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #434d56;
`;

const SelectWrapper = styled.div`
  max-width: 250px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type AddressModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  addressDetail: any;
};

const AddressModal: React.FC<AddressModalProps> = ({ addressDetail, isDisplayed, onClose }) => {
  const [address, setAddress] = useState<any>({ ...addressDetail });
  const [addressType, setAddressType] = useState<Option>();
  const { state: allCompaniesState, dispatch: allCompaniesAction } = useContext(
    AllCompaniesContext.Context
  );
  const companyId = new URLSearchParams(window.location.search).get('companyId');
  const { post, patch } = useApi();

  const modalTitle = addressDetail.id ? 'Update company address' : 'New company address';

  const isAddressCompleted = Boolean(
    address?.address_type &&
      address?.address_1 &&
      address?.address_name &&
      address?.postal_code &&
      address?.city &&
      address?.country
  );

  const createCompanyAddress = async () => {
    const newAddress = await post(postCreateAddress(Number(companyId)), {
      ...address
    });

    const newCompaniesState = [...allCompaniesState];

    newCompaniesState
      .find((company) => company.id === Number(companyId))
      .addresses.unshift(newAddress.data);

    allCompaniesAction({
      type: 'UPDATE_ALL_DATA',
      payload: newCompaniesState
    });

    onClose();
  };

  const updateCompanyAddress = () => {
    patch(patchAddress(Number(companyId), addressDetail.id), {
      ...address
    });

    const newCompaniesState = allCompaniesState.map((company) =>
      company.id === Number(companyId)
        ? {
            ...company,
            addresses: company.addresses.map((currentAddress) =>
              currentAddress.id === addressDetail.id
                ? { ...currentAddress, ...address }
                : currentAddress
            )
          }
        : company
    );

    allCompaniesAction({
      type: 'UPDATE_ALL_DATA',
      payload: newCompaniesState
    });

    onClose();
  };

  const handleSaveButton = addressDetail.id ? updateCompanyAddress : createCompanyAddress;

  const handleChangeSelect = (
    name: string,
    option: Option,
    updateState: (option: Option) => void
  ) => {
    setAddress({
      ...address,
      [name]: option.value
    });
    updateState(option);
  };

  const handleChange = (name: string) => (value: string | number) => {
    setAddress({
      ...address,
      [name]: value
    });
  };

  useEffect(() => {
    setAddress(addressDetail);
    if (addressDetail.address_type) {
      setAddressType(
        AddressTypeOption.find((option) => option.value === addressDetail.address_type)
      );
    }
  }, [addressDetail]);

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={modalTitle}>
          <Content>
            <Row>
              <SelectWrapper>
                <SelectedTitle>Type</SelectedTitle>
                <CustomSelect
                  placeholder="Type"
                  value={addressType}
                  options={AddressTypeOption}
                  onChange={(e) => {
                    handleChangeSelect('address_type', e, setAddressType);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </SelectWrapper>

              <CustomInput
                title="Name"
                defaultValue={addressDetail.address_name}
                placeholder="Address name"
                callBack={() => handleChange('address_name')}
              />
            </Row>
            <CustomTextArea
              title="Address"
              value={addressDetail.address_1}
              placeholder="Address"
              callBack={() => handleChange('address_1')}
            />
            <Row>
              <CustomInput
                title="Zip"
                defaultValue={addressDetail.postal_code}
                placeholder="Postal code"
                callBack={() => handleChange('postal_code')}
              />
              <CustomInput
                title="City"
                defaultValue={addressDetail.city}
                placeholder="City"
                callBack={() => handleChange('city')}
              />
            </Row>
            <Row>
              <CustomInput
                title="State"
                defaultValue={addressDetail.state}
                placeholder="State"
                callBack={() => handleChange('state')}
              />
              <CustomInput
                title="Country"
                defaultValue={addressDetail.country}
                placeholder="Country"
                callBack={() => handleChange('country')}
              />
            </Row>
            <ButtonWrapper>
              <Button
                text="Save"
                onClick={handleSaveButton}
                variant={1}
                sizing={1}
                disabled={!isAddressCompleted}
              />
            </ButtonWrapper>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default AddressModal;
