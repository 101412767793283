import React from 'react';

const BlueCheckMark = () => {
  return (
    <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.64018 0.277702C10.0645 0.631266 10.1218 1.26183 9.76822 1.68611L4.94348 7.4758C4.47292 8.04047 3.61907 8.07921 3.09932 7.55945L0.292893 4.75303C-0.0976311 4.36251 -0.0976311 3.72934 0.292893 3.33882C0.683417 2.94829 1.31658 2.94829 1.70711 3.33882L3.9328 5.56451L8.23178 0.405739C8.58534 -0.0185378 9.21591 -0.0758619 9.64018 0.277702Z"
        fill="#1975FF"
      />
    </svg>
  );
};

export default BlueCheckMark;
