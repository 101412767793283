import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AdminsContext } from 'contexts';
import { Download, Money, Send, Trash, WhiteCross } from 'svgs';
import { Button, SmallLoader, Tag } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import {
  delProviderInvoice,
  getProviderInvoices,
  postProviderInvoicePDF
} from 'services/endpoints';
import { ProviderPaymentModal, ProviderInvoiceCreditNoteModal } from 'components/modal';
import { TagColor } from 'components/common/Tag';
import PictureTag from 'components/PictrureTag';
import { capitalizeFirstLetter, getInitial, useProvidersState } from 'components/Tools';

import { Array, providersInvoices } from '../array';
import { MainContainer, Title } from '../styledComponent';

const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
`;

enum InvoiceStatus {
  PAID = 'paid',
  NOT_PAID = 'not paid'
}

enum InvoiceTypeStatus {
  ADVANCE = 'advance',
  CLASSIC = 'classic'
}

const getInvoiceStatusColor = (status: string): TagColor => {
  switch (status) {
    case InvoiceStatus.PAID:
      return TagColor.GREEN;

    case InvoiceStatus.NOT_PAID:
      return TagColor.RED;

    default:
      return TagColor.BLUE;
  }
};

const getInvoiceTypeStatusStatusColor = (status: string): TagColor => {
  switch (status) {
    case InvoiceTypeStatus.ADVANCE:
      return TagColor.DARKBLUE;

    case InvoiceTypeStatus.CLASSIC:
      return TagColor.ORANGE;

    default:
      return TagColor.BLUE;
  }
};

const ProviderInvoices: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalPaymentDisplayed, setModalPaymentDisplayed] = useState<boolean>(false);
  const [modalCreditNoteDisplayed, setModalCreditNoteDisplayed] = useState<boolean>(false);
  const [providerInvoices, setProviderInvoices] = useState<ProviderInvoiceContextState>();
  const [selectedProviderInvoices, setSelectedProviderInvoices] = useState<number[]>([]);
  const { findProviderById } = useProvidersState();
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { get, del, post } = useApi();
  const getProviderName = (providerId: number) => findProviderById(providerId)?.company_name;

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const handleClickRegisterPaymentButton = () => {
    setModalPaymentDisplayed(true);
  };

  const handleCloseSalesPaymentModal = () => {
    setModalPaymentDisplayed(false);
  };

  const handleClickNewCreditNoteButton = () => {
    setModalCreditNoteDisplayed(true);
  };

  const handleCloseCreditNoteModal = () => {
    setModalCreditNoteDisplayed(false);
  };

  const providerInvoicesPreProcessing = providerInvoices?.results.map((providerInvoices) => ({
    id: providerInvoices.id,
    invoice_type: {
      value: providerInvoices.invoice_type,
      display: (
        <Tag
          text={capitalizeFirstLetter(providerInvoices.invoice_type)}
          color={getInvoiceTypeStatusStatusColor(providerInvoices.invoice_type)}
        />
      )
    },
    company_name: {
      value: getProviderName(providerInvoices.provider),
      display: (
        <BlueBoldTextContainer>{getProviderName(providerInvoices.provider)}</BlueBoldTextContainer>
      )
    },
    order_reference: {
      value: providerInvoices.reference,
      display: <BlueBoldTextContainer>{providerInvoices.reference}</BlueBoldTextContainer>
    },
    reference: {
      value: providerInvoices.reference,
      display: <BlueBoldTextContainer>{providerInvoices.reference}</BlueBoldTextContainer>
    },
    sales_person: {
      value: getAdminInital(providerInvoices?.sales_person_id),
      display: <PictureTag text={getAdminInital(providerInvoices?.sales_person_id)} />
    },
    admin: {
      value: getAdminInital(providerInvoices?.admin_id),
      display: <PictureTag text={getAdminInital(providerInvoices?.admin_id)} />
    },
    margin: { value: providerInvoices.margin, display: <div>{providerInvoices.margin}</div> },
    status: {
      value: providerInvoices.status,
      display: (
        <Tag
          text={capitalizeFirstLetter(providerInvoices.status)}
          color={getInvoiceStatusColor(providerInvoices.status)}
        />
      )
    },
    total_price_without_tax: {
      value: providerInvoices.invoice_amount_with_change_rate,
      display: <div>{providerInvoices.invoice_amount_with_change_rate}</div>
    },
    total_price_with_tax: {
      value: providerInvoices.invoice_amount_without_change_rate,
      display: <div>{providerInvoices.invoice_amount_without_change_rate}</div>
    },
    down_payment: {
      value: providerInvoices.down_payment,
      display: <div>{providerInvoices.down_payment}</div>
    },
    invoice_balance_amount: {
      value: providerInvoices.invoice_balance_amount,
      display: <div>{providerInvoices.invoice_balance_amount}</div>
    },
    invoice_date: {
      value: providerInvoices.invoice_date,
      display: <div>{new Date(providerInvoices.invoice_date).toLocaleDateString('fr-FR')}</div>
    },
    invoice_reference: {
      value: providerInvoices.provider_purchases
        .map((provider) => provider.delivery_note_reference)
        .join('\n'),
      display: (
        <BlueBoldTextContainer>
          {providerInvoices.provider_purchases
            .map((provider) => provider.delivery_note_reference)
            .join('\n')}
        </BlueBoldTextContainer>
      )
    },
    extended: (
      <div>
        <b>general_comment:</b>{' '}
        {providerInvoices.general_comment
          ? providerInvoices.general_comment
          : 'no comment defined for this invoice'}
      </div>
    )
  }));

  const unselectedProviderInvoices = (id: number) => {
    setSelectedProviderInvoices((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteProviderInvoices = () => {
    selectedProviderInvoices.map((InvoiceId) => {
      del(delProviderInvoice(InvoiceId));
      unselectedProviderInvoices(InvoiceId);
    });
    const newProviderInvoicestate = providerInvoices.results.filter(
      (order) => !selectedProviderInvoices.includes(order.id)
    );
    providerInvoices.results = newProviderInvoicestate;
    setProviderInvoices(providerInvoices);
  };

  const getInvoiceDocument = async (id: number) => {
    const PurchaseItemPDF = await post(postProviderInvoicePDF(id), {}, 'blob');
    const blob = new Blob([PurchaseItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoices-${id}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const handlePDFButtonClick = () => {
    selectedProviderInvoices.map(async (InvoiceId) => {
      getInvoiceDocument(InvoiceId);
    });
  };

  const updateProviderInvoices = (newPage: any) => {
    setProviderInvoices(newPage);
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedProviderInvoices(id);
    } else {
      if (!selectedProviderInvoices.includes(id)) {
        setSelectedProviderInvoices((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      const providerInvoices = await get(getProviderInvoices);
      setProviderInvoices(providerInvoices.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Provider Invoices</Title>
      <Overflow>
        <Array
          template={providersInvoices}
          content={providerInvoicesPreProcessing}
          lineSelected={handleLineSelected}
          extended={true}
          pagination={providerInvoices}
          updatedArrayData={updateProviderInvoices}
          url={getProviderInvoices}
          tools={
            <>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Register payment`}
                leftIcon={Money()}
                onClick={handleClickRegisterPaymentButton}
                disabled={!selectedProviderInvoices.length}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Credit note`}
                leftIcon={WhiteCross()}
                onClick={handleClickNewCreditNoteButton}
                disabled={selectedProviderInvoices.length !== 1}
              />
              {!!selectedProviderInvoices.length && (
                <>
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedProviderInvoices.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteProviderInvoices}
                  />
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    text={`Download Provider Invoices`}
                    leftIcon={Download()}
                    onClick={handlePDFButtonClick}
                  />
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    text={`send by email`}
                    leftIcon={Send()}
                  />
                </>
              )}
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
      <ProviderPaymentModal
        isDisplayed={modalPaymentDisplayed}
        onClose={handleCloseSalesPaymentModal}
        preSelectedInvoices={selectedProviderInvoices}
      />
      <ProviderInvoiceCreditNoteModal
        isDisplayed={modalCreditNoteDisplayed}
        onClose={handleCloseCreditNoteModal}
        selectedInvoices={selectedProviderInvoices[0]}
      />
    </MainContainer>
  );
};

export default ProviderInvoices;
