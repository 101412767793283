import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`;

const Elips = styled.div<{
  color: string;
}>`
  width: 22px;
  height: 22px;
  background: ${(props) => props.color};
  border-radius: 35px;

  flex: none;
  order: 0;
  flex-grow: 0;

  margin-right: 10px;
`;

enum Status {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  BLOCKED = 'blocked',
  DORMANT = 'dormant'
}

const getAccountTypeColor = (status: string) => {
  if (status === Status.DORMANT) {
    return '#CC9D24';
  } else if (status === Status.INACTIVE) {
    return '#FF4242';
  } else if (status === Status.BLOCKED) {
    return '#FF4242';
  } else {
    return '#4A9D2D';
  }
};

type AccountTypeProps = {
  text: string;
};

const AccountType = ({ text }: AccountTypeProps) => {
  return (
    <Container>
      <Elips color={getAccountTypeColor(text)}></Elips>
      {text}
    </Container>
  );
};

export default AccountType;
