import React from 'react';

import { ProviderState } from 'utils/provider';

import ProviderContacts from './ProviderContacts';
import ProviderSettings from './ProviderSettings';
import ProviderActivities from './ProviderActivities';

type ProviderContentProps = {
  providerState: ProviderState;
  providerDetail: Provider;
  setProvidersDetails: React.Dispatch<React.SetStateAction<Provider>>;
};

const ProviderContent: React.FC<ProviderContentProps> = ({
  providerState,
  providerDetail,
  setProvidersDetails
}) => {
  if (providerState === ProviderState.ACTIVITIES) {
    return <ProviderActivities providerDetail={providerDetail} />;
  }
  if (providerState === ProviderState.CONTACTS) {
    return <ProviderContacts providerDetail={providerDetail} />;
  }

  return (
    <ProviderSettings providerDetail={providerDetail} setProvidersDetails={setProvidersDetails} />
  );
};

export default ProviderContent;
