import React from 'react';
import styled from 'styled-components';

import { Modal } from '../common';
import { MainContainer } from '../styledComponent';

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 30px;
  line-height: 1.5;
  color: red;
`;

type ErrorModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  text: JSX.Element;
};

const ErrorModal: React.FC<ErrorModalProps> = ({ isDisplayed, onClose, text }) => {
  return (
    <MainContainer>
      {isDisplayed && (
        <Modal
          isOpen={isDisplayed}
          onClose={onClose}
          title={<Title>⚠️ An error has occurred ⚠️</Title>}
        >
          {text}
        </Modal>
      )}
    </MainContainer>
  );
};

export default ErrorModal;
