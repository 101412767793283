import { createContext } from 'react';

const initialState: Company[] = [];

const reducer = (state: Company[], action: CompaniesDispatchAction): Company[] => {
  switch (action.type) {
    case 'UPDATE_ALL_DATA':
      return action.payload;
    case 'SET_ALL_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type CompaniesContextType = {
  state: Company[];
  dispatch: React.Dispatch<CompaniesDispatchAction>;
};

const Context = createContext<CompaniesContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'AllCompanies';

export { Context, initialState, reducer };
