import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AdminsContext } from 'contexts';
import { WhiteCross, Download, Trash } from 'svgs';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import useApi from 'services/axios';
import {
  delCreditNote,
  getProviderCreditNote,
  postProviderCreditNotePDF
} from 'services/endpoints';
import { ProviderCreditNoteModal } from 'components/modal';
import PictureTag from 'components/PictrureTag';
import { capitalizeFirstLetter, getInitial, useProvidersState } from 'components/Tools';
import Tag, { TagColor } from 'components/common/Tag';

import { Array, providerCreditNotes } from '../array';
import { MainContainer, Title } from '../styledComponent';

const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
`;

enum ProviderCreditNoteStatus {
  USED = 'used',
  PARTIALLY_USED = 'partially used',
  NOT_USED = 'not used'
}

const getCreditNoteProviderStatusColor = (status: string): TagColor => {
  switch (status) {
    case ProviderCreditNoteStatus.USED:
      return TagColor.GREEN;

    case ProviderCreditNoteStatus.PARTIALLY_USED:
      return TagColor.ORANGE;

    case ProviderCreditNoteStatus.NOT_USED:
      return TagColor.RED;

    default:
      return TagColor.BLUE; // Default color, which can be adjusted as needed
  }
};

const ProviderCreditNote: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [providerCreditNote, setProviderCreditNote] = useState<ProviderCreditNotesContext>();
  const [creditDetailModal, setCreditDetailModal] = useState<ProviderCreditNotes>(null);
  const [selectedProviderCreditNotes, setSelectedProviderCreditNotes] = useState<number[]>([]);
  const [modalProviderCreditNoteDisplayed, setModalProviderCreditNoteDisplayed] =
    useState<boolean>(false);
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { findProviderById } = useProvidersState();
  const { get, del, post } = useApi();
  const getProviderName = (providerId: number) => findProviderById(providerId)?.company_name;

  const handleProviderCreditNoteModal = () => {
    setCreditDetailModal(null);
    setModalProviderCreditNoteDisplayed(false);
  };

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const providerCreditNotePreProcessing = providerCreditNote?.results.map((creditNote) => ({
    id: creditNote.id,
    company: {
      value: getProviderName(creditNote.provider),
      display: <div>{getProviderName(creditNote.provider)}</div>
    },
    reference: { value: creditNote.reference, display: <div>{creditNote.reference}</div> },
    note: { value: creditNote.note, display: <div>{creditNote.note}</div> },
    credit_note_date: {
      value: creditNote.credit_note_date,
      display: <div>{new Date(creditNote.credit_note_date).toLocaleDateString('fr-FR')}</div>
    },
    sales_person: {
      value: getAdminInital(creditNote?.sales_person),
      display: <PictureTag text={getAdminInital(creditNote?.sales_person)} />
    },
    admin: {
      value: getAdminInital(creditNote?.admin),
      display: <PictureTag text={getAdminInital(creditNote?.admin)} />
    },
    status: {
      value: creditNote.status,
      display: (
        <Tag
          text={capitalizeFirstLetter(creditNote.status)}
          color={getCreditNoteProviderStatusColor(creditNote.status)}
        />
      )
    },
    amount: { value: creditNote.amount, display: <div>{creditNote.amount}</div> },
    amount_used: { value: creditNote.amount_used, display: <div>{creditNote.amount_used}</div> }
  }));

  const getCreditNoteDocument = async (id: number) => {
    const CreditNoteItemPDF = await post(postProviderCreditNotePDF(id), {}, 'blob');
    const blob = new Blob([CreditNoteItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `creditnote-${id}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const handlePDFButtonClick = () => {
    selectedProviderCreditNotes.map(async (noteId) => {
      getCreditNoteDocument(noteId);
    });
  };

  const unselectedProviderCreditNotes = (id: number) => {
    setSelectedProviderCreditNotes((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];

    const newProviderCreditNotesDetail = providerCreditNote?.results.find(
      (notes) => notes.id == id
    );
    setCreditDetailModal(newProviderCreditNotesDetail);
    setModalProviderCreditNoteDisplayed(true);
  };

  const updateProviderCreditNote = (newPage: any) => {
    setProviderCreditNote(newPage);
  };

  const updateCreditNote = (newNote: ProviderDeliveryNote, isNewNote: boolean) => {
    let newCreditNoteResult;

    if (isNewNote) {
      providerCreditNote.results.unshift(newNote);
      newCreditNoteResult = providerCreditNote.results;
    } else {
      newCreditNoteResult = providerCreditNote.results.map((note) =>
        note.id === newNote.id ? newNote : note
      );
    }

    setProviderCreditNote({ ...providerCreditNote, results: newCreditNoteResult });
  };

  const deleteProviderCreditNotes = () => {
    selectedProviderCreditNotes.map((CreditId) => {
      del(delCreditNote(CreditId));
      unselectedProviderCreditNotes(CreditId);
    });
    const newOrderState = providerCreditNote.results.filter(
      (credit) => !selectedProviderCreditNotes.includes(credit.id)
    );
    providerCreditNote.results = newOrderState;
    setProviderCreditNote(providerCreditNote);
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedProviderCreditNotes(id);
    } else {
      if (!selectedProviderCreditNotes.includes(id)) {
        setSelectedProviderCreditNotes((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const handleClickCreateNoteButton = () => {
    setModalProviderCreditNoteDisplayed(true);
  };

  useEffect(() => {
    (async () => {
      const providerCreditNote = await get(getProviderCreditNote);
      setProviderCreditNote(providerCreditNote.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Provider Credit note</Title>
      <Overflow>
        <Array
          template={providerCreditNotes}
          content={providerCreditNotePreProcessing}
          lineSelected={handleLineSelected}
          lineClicked={handleLineClicked}
          extended={true}
          pagination={providerCreditNote}
          updatedArrayData={updateProviderCreditNote}
          url={getProviderCreditNote}
          tools={
            <>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Credit Note`}
                leftIcon={WhiteCross()}
                onClick={handleClickCreateNoteButton}
              />
              {!!selectedProviderCreditNotes.length && (
                <>
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedProviderCreditNotes.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteProviderCreditNotes}
                  />
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    text={`Download Credit Note`}
                    leftIcon={Download()}
                    onClick={handlePDFButtonClick}
                  />
                </>
              )}
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
      <ProviderCreditNoteModal
        isDisplayed={modalProviderCreditNoteDisplayed}
        onClose={handleProviderCreditNoteModal}
        creditNotes={creditDetailModal}
        callback={updateCreditNote}
      />
    </MainContainer>
  );
};

export default ProviderCreditNote;
