import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #eef1f3;
  border-radius: 35px;
  justify-content: center;
  width: fit-content;
  padding: 8px 16px;
`;

type PictureTagProps = {
  text: string;
  imgUrl?: string;
};

const PictureTag = ({ text, imgUrl }: PictureTagProps) => {
  return (
    <Container>
      {imgUrl && (
        <div>
          <img src="" alt="img"></img>
        </div>
      )}
      {text}
    </Container>
  );
};

export default PictureTag;
