import { createContext } from 'react';

const initialState: ErrorContextState = {
  isErrorModalDisplayed: false,
  text: null
};

const reducer = (state: ErrorContextState, action: ErrorDispatchAction): ErrorContextState => {
  switch (action.type) {
    case 'SHOW_ERROR':
      return {
        isErrorModalDisplayed: true,
        text: action.payload
      };
    case 'HIDE_ERROR':
      return {
        isErrorModalDisplayed: false,
        text: null
      };
    default:
      throw new Error('unexpected action type');
  }
};

type ErrorContextType = {
  state: ErrorContextState;
  dispatch: React.Dispatch<ErrorDispatchAction>;
};

const Context = createContext<ErrorContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'Errors';

export { Context, initialState, reducer };
