import { debounce } from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 4px;
  margin-right: 4px;
`;

const TextArea = styled.textarea<{
  disabled: boolean;
  isBold: boolean;
  height: string;
}>`
  border: none;
  outline: none;
  box-shadow: none;
  background-color: ${(props) => (props.disabled ? '#f2f2f2;' : 'white')}
  width: 100%;
  min-height: ${(props) => props.height};
  padding: 12px;
  border: 1px solid #d9dee2;
  border-radius: 10px;
  font-weight: ${(props) => (props.isBold ? 'bold' : '400')};
  &:disabled {
    cursor: no-drop;
  }
`;

const Title = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #434d56;
`;

type CustomInputProps = {
  value: string;
  placeholder: string;
  title?: string;
  isDisabled?: boolean;
  isBold?: boolean;
  height?: string;
  debounceTime?: number;
  callBack?: (property?: string) => (value: string | number) => void;
};

const CustomTextArea = ({
  value,
  placeholder,
  title,
  isDisabled = false,
  isBold = false,
  height = '85px',
  debounceTime = 0,
  callBack
}: CustomInputProps) => {
  const [text, setText] = useState(value);

  useEffect(() => {
    setText(value);
  }, [value]);

  const debouncedExec = useCallback(
    debounce((input: string) => {
      if (callBack) {
        const exec = callBack();
        exec(input);
      }
    }, debounceTime),
    [callBack]
  );

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.target.value);
    debouncedExec(e.target.value);
  };

  return (
    <Container>
      {title && <Title>{title}</Title>}
      <TextArea
        placeholder={placeholder}
        value={text}
        disabled={isDisabled}
        isBold={isBold}
        onInput={handleChange}
        autoComplete="off"
        height={height}
      />
    </Container>
  );
};

export default CustomTextArea;
