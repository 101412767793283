import { useContext } from 'react';
import styled from 'styled-components';

import { AllCompaniesContext, AllProvidersContext } from 'contexts';

export const capitalizeFirstLetter = (text: string) => {
  if (text) {
    return text.replace(/^\w/, (str) => str.toUpperCase());
  }

  return '';
};

export const getInitial = (str1: string, str2: string): string => {
  const [firstLetter1] = str1;
  const [firstLetter2] = str2;
  return `${firstLetter1}.${firstLetter2}.`;
};

export const getValue = (value: string) => (value ? value : '-');

export const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
`;

export const useCompaniesState = () => {
  const { state: allCompaniesState } = useContext(AllCompaniesContext.Context);

  if (allCompaniesState === undefined) {
    throw new Error('useCompaniesState must be used within a AllCompaniesContext.Provider');
  }

  const findCompanyById = (id: number) => {
    return allCompaniesState.find((company) => company.id === id);
  };

  return {
    findCompanyById
  };
};

export const useProvidersState = () => {
  const { state: allProvidersState } = useContext(AllProvidersContext.Context);

  if (allProvidersState === undefined) {
    throw new Error('useProvidersState must be used within a AllProvidersContext.Provider');
  }

  const findProviderById = (id: number) => {
    return allProvidersState.find((provider) => provider.id === id);
  };

  return {
    findProviderById
  };
};

export const setToken = (userToken: string) => {
  const tokenObject = { token: userToken };
  localStorage.setItem('accessToken', JSON.stringify(tokenObject));
};

export const getToken = (): string | null => {
  const tokenString = localStorage.getItem('accessToken');
  const userToken = JSON.parse(tokenString);
  return userToken?.token;
};

export enum PCBSeparation {
  VCut = 'v-cut',
  VCutNoBorder = 'v-cut no border',
  Milling = 'milling',
  MillingScoring = 'milling + stamps',
  NoSeparation = 'no separation'
}
