import React from 'react';

const Download = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9 12L12 15M12 15L15 12M12 15V3"
        stroke="#1975FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9H7C4.79086 9 3 10.7909 3 13V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V13C21 10.7909 19.2091 9 17 9H16.5"
        stroke="#1975FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Download;
