import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { getSelectStyles } from 'utils/style';
import { AdminsContext, AllProvidersContactContext, AllProvidersContext } from 'contexts';
import { rfqStatusOption } from 'utils/sales';
import { ButtonVariant } from 'components/common/Button';
import useApi from 'services/axios';
import { patchRequestForQuotation, postRequestForQuotation } from 'services/endpoints';

import { Button, CustomDatePicker, CustomInput, CustomSelect, ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Left = styled(Row)`
  justify-content: flex-end;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

type RfqModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  quotation?: Partial<Quotation>;
  requestForQuotationsDetails?: Rfq;
  callBack?: (newRfq: Rfq) => void;
};

const RfqModal: React.FC<RfqModalProps> = ({
  isDisplayed,
  onClose,
  quotation,
  requestForQuotationsDetails,
  callBack
}) => {
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { state: allProvidersState } = useContext(AllProvidersContext.Context);
  const { state: allProvidersContactState } = useContext(AllProvidersContactContext.Context);
  const [admin, setAdmin] = useState<Option>();
  const [salesMan, setSalesMan] = useState<Option>();
  const [status, setStatus] = useState<Option>();
  const [provider, setProviders] = useState<Option>();
  const [contact, setContact] = useState<Option>();
  const [date, setDate] = useState<string>();
  const [reference, setReference] = useState<string>();
  const [discount, setDiscount] = useState<string>('0');
  const { post, patch } = useApi();

  const button = requestForQuotationsDetails ? `Save` : 'Create';
  const title = requestForQuotationsDetails
    ? `Update Request Quotation`
    : 'Create Request Quotation';

  const adminFormatted = adminsState.map((admin) => ({
    label: `${admin.last_name} ${admin.first_name}`,
    value: admin.id
  }));

  const providerFormatted = allProvidersState.map((provider) => {
    return {
      label: provider.company_name,
      value: provider.id
    };
  });

  const providerContactFormatted = useMemo(
    () =>
      allProvidersContactState
        .filter((contact) => contact.provider_company_id === provider?.value)
        .map((provider) => {
          return {
            label: provider.first_name + provider.last_name,
            value: provider.id
          };
        }),
    [allProvidersContactState, provider]
  );

  const handleChange =
    (setState: React.Dispatch<React.SetStateAction<string>>) => (value: string) => {
      setState(value);
    };

  const handleChangeDatePicker = () => async (value: Date) => {
    setDate(new Date(value).toString());
  };

  const handleSaveButtonClick = async () => {
    if (requestForQuotationsDetails && callBack) {
      const patchRfq = await patch(
        patchRequestForQuotation(
          requestForQuotationsDetails.company_id,
          requestForQuotationsDetails.contact_id,
          requestForQuotationsDetails.id
        ),
        {
          provider_id: provider.value,
          provider_contact: contact.value,
          admin: admin.value,
          sales_person: salesMan.value,
          reference: reference,
          status: status.value,
          rfq_date: new Date(date),
          provider_purchases: null,
          discount: discount
        }
      );
      callBack(patchRfq.data);
    } else {
      post(postRequestForQuotation(quotation.company_id, quotation.contact_id), {
        quotation_id: quotation.id,
        provider_id: provider.value,
        provider_contact: contact.value,
        admin: admin.value,
        sales_person: salesMan.value,
        reference: reference,
        status: status.value,
        rfq_date: date ? new Date(date) : new Date(),
        provider_purchases: null,
        discount: discount
      });
    }

    onClose();
  };

  useEffect(() => {
    if (requestForQuotationsDetails) {
      setProviders(
        providerFormatted.find(
          (provider) => provider.value === requestForQuotationsDetails.provider_id
        )
      );
      setSalesMan(
        adminFormatted.find((admin) => admin.value === requestForQuotationsDetails.sales_person)
      );
      setAdmin(adminFormatted.find((admin) => admin.value === requestForQuotationsDetails.admin));
      setStatus(
        rfqStatusOption.find((status) => status.value === requestForQuotationsDetails.status)
      );
      setContact(
        allProvidersContactState
          .filter(
            (contact) => contact.provider_company_id === requestForQuotationsDetails?.provider_id
          )
          .map((provider) => {
            return {
              label: provider.first_name + provider.last_name,
              value: provider.id
            };
          })
          .find((contact) => contact.value === requestForQuotationsDetails.provider_contact)
      );
      setDate(new Date(requestForQuotationsDetails.rfq_date).toString());
      setReference(requestForQuotationsDetails.reference);
      setDiscount(requestForQuotationsDetails?.discount?.toString());
    }
  }, [requestForQuotationsDetails]);

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={title}>
          <Content>
            {quotation?.reference && (
              <Row>
                <CustomContainer>
                  <CustomInput
                    title="Quotation Reference"
                    defaultValue={quotation.reference}
                    placeholder="-"
                    isDisabled={true}
                    width="250px"
                  />
                </CustomContainer>
              </Row>
            )}
            <Row>
              <CustomContainer>
                <TitleSelect>Providers</TitleSelect>
                <CustomSelect
                  placeholder="provider"
                  value={provider}
                  options={providerFormatted}
                  onChange={(e) => setProviders(e)}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>Contacts</TitleSelect>
                <CustomSelect
                  placeholder="contact"
                  value={contact}
                  options={providerContactFormatted}
                  isDisabled={!provider}
                  onChange={(e) => setContact(e)}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </CustomContainer>
            </Row>
            <Row>
              <CustomContainer>
                <TitleSelect>Admin</TitleSelect>
                <CustomSelect
                  placeholder="admin"
                  value={admin}
                  options={adminFormatted}
                  onChange={(e) => setAdmin(e)}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>Associated salesman</TitleSelect>
                <CustomSelect
                  placeholder="Salesman"
                  value={salesMan}
                  options={adminFormatted}
                  onChange={(e) => setSalesMan(e)}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </CustomContainer>
            </Row>
            <Row>
              <CustomContainer>
                <CustomInput
                  title="Reference"
                  defaultValue={reference}
                  placeholder="rfq-XXXXX"
                  width="250px"
                  callBack={() => handleChange(setReference)}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>RFQ date</TitleSelect>
                <CustomDatePicker
                  value={date}
                  isDisabled={false}
                  updatedDate={handleChangeDatePicker()}
                />
              </CustomContainer>
            </Row>
            <Row>
              <CustomContainer>
                <TitleSelect>Status</TitleSelect>
                <CustomSelect
                  placeholder="Draft"
                  value={status}
                  options={rfqStatusOption}
                  onChange={(e) => setStatus(e)}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </CustomContainer>
              <CustomContainer>
                <CustomInput
                  title="Discount"
                  defaultValue={discount?.toString()}
                  placeholder="0"
                  callBack={() => handleChange(setDiscount)}
                  width="250px"
                />
              </CustomContainer>
            </Row>
            <Left>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={button}
                onClick={handleSaveButtonClick}
              />
            </Left>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default RfqModal;
