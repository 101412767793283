import React from 'react';

const Trash = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 7.1459H5.77778M5.77778 7.1459H20M5.77778 7.1459V19.7459C5.77778 20.2233 5.96508 20.6811 6.29848 21.0187C6.63187 21.3563 7.08406 21.5459 7.55556 21.5459H16.4444C16.9159 21.5459 17.3681 21.3563 17.7015 21.0187C18.0349 20.6811 18.2222 20.2233 18.2222 19.7459V7.1459H5.77778ZM8.44444 7.1459V5.3459C8.44444 4.86851 8.63175 4.41067 8.96514 4.07311C9.29854 3.73554 9.75073 3.5459 10.2222 3.5459H13.7778C14.2493 3.5459 14.7015 3.73554 15.0349 4.07311C15.3683 4.41067 15.5556 4.86851 15.5556 5.3459V7.1459M10.2222 11.6459V17.0459M13.7778 11.6459V17.0459"
        stroke="#FF4242"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Trash;
