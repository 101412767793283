import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { CustomInput, SmallLoader } from 'components/common';
import { StyledButtonContainer } from 'utils/array';
import useApi from 'services/axios';

import Header from './Header';
import Body from './Body';
import Footer from './Footer';

const StyledTable = styled.table`
  margin-top: ${(props) => props.theme.spacing.xxxs}px;
  border-spacing: 0;
`;

const Col = styled.col<{ size: number }>`
  width: ${(props) => props.size}px;
`;

type ArrayProps = {
  content: any;
  template: Column[];
  lineClicked?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  lineSelected?: (id: number | string, isUnSelected?: boolean) => void;
  extended?: boolean;
  errorLineMessage?: boolean;
  sorted?: boolean;
  checkboxDisplayed?: boolean;
  checkedElements?: number[];
  pagination?: any;
  url?: string;
  newWindowUrl?: (id: string) => void;
  updatedArrayData?: (newPage: any) => void;
  handleDragAndDrop?: (newOrder: any) => void;
  tools?: JSX.Element;
  isSearchable?: boolean;
  isDragDisabled?: boolean;
  onLoading?: boolean;
  moreOptions?: JSX.Element[];
};

const Array = ({
  content,
  template,
  lineClicked,
  lineSelected,
  newWindowUrl,
  extended,
  errorLineMessage,
  sorted = true,
  checkboxDisplayed = true,
  checkedElements = [],
  pagination,
  url,
  isSearchable = true,
  updatedArrayData,
  handleDragAndDrop,
  tools,
  isDragDisabled = true,
  onLoading = false,
  moreOptions
}: ArrayProps) => {
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [sortingState, setSortingState] = useState<ColumnSortingState>({});
  const [searchField, setSearchField] = useState<string>('');
  const [arraySize, setArraySize] = useState<Option>({
    value: '20',
    label: '20'
  });
  const [isLoading, setIsLoading] = useState(onLoading);
  const isFirstRender = useRef(true);
  const { get } = useApi();

  const handleClickSelectedAll = () => {
    content.forEach((line: any) => lineSelected(line.id, isSelectedAll));
    setIsSelectedAll((selectedAll) => !selectedAll);
  };

  const handleChangeSearchField = () => (value: string | number) => {
    setSearchField(value as string);
  };

  useEffect(() => {
    if (url) {
      if (isFirstRender.current) {
        isFirstRender.current = false;
        return;
      }

      if (isSearchable) {
        (async () => {
          setIsLoading(true);
          const searchQuery = searchField
            ? '?search_query=' + searchField + `&page_size=${arraySize.value}`
            : `?page_size=${arraySize.value}`;
          const newData = await get(url + searchQuery);
          updatedArrayData(newData.data);
          setIsLoading(false);
        })();
      }
    }
  }, [searchField, isSearchable, url]);

  useEffect(() => {
    setIsLoading(onLoading);
  }, [onLoading]);

  return (
    <>
      <StyledButtonContainer>
        {isSearchable && (
          <CustomInput
            defaultValue={searchField}
            placeholder="Search"
            width="250px"
            callBack={handleChangeSearchField}
          />
        )}
        {!!tools && tools}
      </StyledButtonContainer>
      {isLoading ? (
        <SmallLoader />
      ) : (
        <StyledTable>
          <colgroup>
            {checkboxDisplayed && <Col key={'init-table-colgroup'} size={50}></Col>}
            {template.map((template, index) => {
              return <Col key={`colgroup-${index}`} size={template.size}></Col>;
            })}
          </colgroup>
          <Header
            isSelected={isSelectedAll}
            setIsSelected={handleClickSelectedAll}
            template={template}
            checkboxDisplayed={checkboxDisplayed}
            sorted={sorted}
            sortingState={sortingState}
            setSortingState={setSortingState}
          />
          <Body
            isSelected={isSelectedAll}
            content={content}
            template={template}
            lineClicked={lineClicked}
            lineSelected={lineSelected}
            extended={extended}
            errorLineMessage={errorLineMessage}
            sorted={sorted}
            handleDragAndDrop={handleDragAndDrop}
            checkboxDisplayed={checkboxDisplayed}
            checkedElements={checkedElements}
            sortingState={sortingState}
            isDragDisabled={isDragDisabled}
            moreOptions={moreOptions}
            newWindowUrl={newWindowUrl}
          />
          {pagination && (
            <Footer
              pagination={pagination}
              updatedArrayData={updatedArrayData}
              colSpan={checkboxDisplayed ? template.length + 1 : 0}
              arraySize={arraySize}
              setArraySize={setArraySize}
              url={url}
              searchField={searchField}
            />
          )}
        </StyledTable>
      )}
    </>
  );
};

export default Array;
