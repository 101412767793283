import React from 'react';
import styled from 'styled-components';

import { Tool, Logout } from 'svgs';

import avatar from '../assets/avatar.png';

import { Button, ButtonSizing, ButtonVariant } from './common';

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 100px;
  width: 100%;
  padding: 0 ${(props) => props.theme.spacing.l}px;
  background: ${(props) => props.theme.colors.common.white};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const ImgContainer = styled.div`
  margin-left: ${(props) => props.theme.spacing.xl}px;
`;

const logout = () => {
  localStorage.removeItem('accessToken');
  location.reload();
};

const settingsToolTip: JSX.Element[] = [
  <Button
    key="log-out"
    variant={ButtonVariant.BLACK}
    text="Log out"
    leftIcon={Logout()}
    sizing={ButtonSizing.EXTRA_SMALL}
    onClick={logout}
  />
];

const Header: React.FC = () => {
  return (
    <Container>
      <Button
        variant={ButtonVariant.PRIMARY_LIGHT}
        leftIcon={Tool()}
        sizing={ButtonSizing.ICON}
        tooltips={settingsToolTip}
      />
      <ImgContainer>
        <img src={avatar} alt="img"></img>
      </ImgContainer>
    </Container>
  );
};

export default Header;
