import React from 'react';

const Dashboard = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 4.8C3 3.80589 3.80589 3 4.8 3H8.4C9.39411 3 10.2 3.80589 10.2 4.8V8.4C10.2 9.39411 9.39411 10.2 8.4 10.2H4.8C3.80589 10.2 3 9.39411 3 8.4V4.8Z"
        stroke="#232C34"
        strokeWidth="1.5"
      />
      <path
        d="M13.8 15.6C13.8 14.6059 14.6059 13.8 15.6 13.8H19.2C20.1941 13.8 21 14.6059 21 15.6V19.2C21 20.1941 20.1941 21 19.2 21H15.6C14.6059 21 13.8 20.1941 13.8 19.2V15.6Z"
        stroke="#232C34"
        strokeWidth="1.5"
      />
      <path
        d="M13.8 4.8C13.8 3.80589 14.6059 3 15.6 3H19.2C20.1941 3 21 3.80589 21 4.8V8.4C21 9.39411 20.1941 10.2 19.2 10.2H15.6C14.6059 10.2 13.8 9.39411 13.8 8.4V4.8Z"
        stroke="#232C34"
        strokeWidth="1.5"
      />
      <path
        d="M3 15.6C3 14.6059 3.80589 13.8 4.8 13.8H8.4C9.39411 13.8 10.2 14.6059 10.2 15.6V19.2C10.2 20.1941 9.39411 21 8.4 21H4.8C3.80589 21 3 20.1941 3 19.2V15.6Z"
        stroke="#232C34"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Dashboard;
