import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { delProviderAddress } from 'services/endpoints';
import useApi from 'services/axios';
import { AdminsContext, CurrencyContext } from 'contexts';
import { Cross, Dashboard, Download, Info, Trash } from 'svgs';
import { getSelectStyles } from 'utils/style';
import SettingCard from 'components/SettingCard';
import { Button, CustomInput, CustomSelect, Tag } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { TagColor } from 'components/common/Tag';
import { HelperText } from 'utils/array';

import {
  accountStatusOption,
  accountTypeOption,
  paymentMethodOption,
  taxeRatesOption,
  termPaymentOption,
  useProviderHandler,
  paymentModalityOption
} from '../../../utils/provider';
import { addressProviders, Array } from '../../array';
import { ProviderAddressModal } from '../../modal';
import { capitalizeFirstLetter } from '../../Tools';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  gap: 24px;
`;

const FormRow = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const CustomContainer = styled.div`
  gap: 4px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export enum AddressType {
  BILLING = 'billing',
  SHIPPING = 'shiiping'
}

const getTypeColor = (status: string) => {
  if (status === AddressType.BILLING) {
    return TagColor.GREEN;
  }
  return TagColor.ORANGE;
};

type ProviderSettingsProps = {
  providerDetail: Provider;
  setProvidersDetails: React.Dispatch<React.SetStateAction<Provider>>;
};

const ProviderSettings: React.FC<ProviderSettingsProps> = ({
  providerDetail,
  setProvidersDetails
}) => {
  const [selectedAddress, setSelectedAddress] = useState<number[]>([]);
  const [modalAddressDisplayed, setModalAddressDisplayed] = useState<boolean>(false);
  const [addressDetail, setAddressDetail] = useState<any>({});
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { state: currencyState } = useContext(CurrencyContext.Context);
  const { del } = useApi();
  const onChangeProvider = useProviderHandler();

  const currencyOption = currencyState.results.map((curr: Currency) => {
    return {
      label: curr.name,
      value: curr.id
    };
  });
  const adminFormatted = adminsState.map((admin) => ({
    label: `${admin.last_name} ${admin.first_name}`,
    value: admin.id
  }));

  const providerSettingsPreProcessing = providerDetail?.addresses.map((provider) => {
    return {
      id: provider.id,
      address_1: { value: provider.address_1, display: <div>{provider.address_1}</div> },
      postal_code: { value: provider.postal_code, display: <div>{provider.postal_code}</div> },
      country: { value: provider.country, display: <div>{provider.country}</div> },
      city: { value: provider.city, display: <div>{provider.city}</div> },
      address_name: { value: provider.address_name, display: provider.address_name },
      state: { value: provider.state, display: <div>{provider.state}</div> },
      address_type: {
        value: provider.address_type,
        display: (
          <Tag
            text={capitalizeFirstLetter(provider.address_type)}
            color={getTypeColor(provider.address_type)}
          />
        )
      }
    };
  });

  const [accountStatus, setAccountStatus] = useState<Option>(
    accountStatusOption.find((option) => option.value === providerDetail.status)
  );
  const [accountType, setAccountType] = useState<Option>(
    accountTypeOption.find((option) => option.value === providerDetail.account_type)
  );
  const [currency, setCurrency] = useState<Option>(
    currencyOption.find((option: Option) => option.value === providerDetail.provider_currency)
  );
  const [paymentMethod, setPaymentMethod] = useState<Option>(
    paymentMethodOption.find((option) => option.value === providerDetail.payment_method)
  );
  const [paymentModality, setPaymentModality] = useState<Option>(
    paymentModalityOption.find((option) => option.value === providerDetail.payment_modality)
  );
  const [termsPayment, setTermsPayment] = useState<NumberOption>(
    termPaymentOption.find((option) => option.value === providerDetail.terms_of_payment)
  );
  const [taxeRates, setTaxeRates] = useState<NumberOption>(
    taxeRatesOption.find((option) => option.value === providerDetail.tax_rate)
  );

  const [salesMan, setSalesMan] = useState<Option>(
    adminFormatted.find((option) => option.value === providerDetail.sales_person)
  );

  const handleChangeCurrency = onChangeProvider('provider_currency', providerDetail, setCurrency);
  const handleChangePaymentMethod = onChangeProvider(
    'payment_method',
    providerDetail,
    setPaymentMethod
  );
  const handleChangeTermsPayment = onChangeProvider(
    'terms_of_payment',
    providerDetail,
    setTermsPayment
  );
  const handleChangePaymentModality = onChangeProvider(
    'payment_modality',
    providerDetail,
    setPaymentModality
  );
  const handleChangeTaxeRates = onChangeProvider('tax_rate', providerDetail, setTaxeRates);
  const handleChangeSalesPerson = onChangeProvider('sales_person', providerDetail, setSalesMan);
  const handleChangeAccountStatus = onChangeProvider('status', providerDetail, setAccountStatus);
  const handleChangeAccountType = onChangeProvider('account_type', providerDetail, setAccountType);
  const handleChangeHardFinancialLimit = () =>
    onChangeProvider('hard_financial_limit', providerDetail);

  const createProviderAddress = async () => {
    setAddressDetail({});
    setModalAddressDisplayed(true);
  };

  const unSelectedProviders = (id: number) => {
    setSelectedAddress((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };
  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedProviders(id);
    } else {
      if (!selectedAddress.includes(id)) {
        setSelectedAddress((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const deleteProviderAddress = () => {
    selectedAddress.map((addressId) => {
      del(delProviderAddress(providerDetail.id, addressId));
      unSelectedProviders(addressId);
    });

    setProvidersDetails({
      ...providerDetail,
      addresses: providerDetail.addresses.filter(
        (provider) => !selectedAddress.includes(provider.id)
      )
    });
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const contactDetails = providerDetail.addresses.find((address) => address.id === Number(id));
    setAddressDetail(contactDetails);
    setModalAddressDisplayed(true);
  };

  const handleCloseAddressModal = () => {
    setModalAddressDisplayed(false);
  };

  return (
    <Container>
      <SettingCard title="Payment information" icon={Info()}>
        <FormRow>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Associated salesman</TitleSelect>
              <CustomSelect
                placeholder="Salesman"
                value={salesMan}
                options={adminFormatted}
                onChange={handleChangeSalesPerson}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '250px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
          </InputRowContainer>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Currency</TitleSelect>
              <CustomSelect
                placeholder="$ USD"
                value={currency}
                options={currencyOption}
                onChange={handleChangeCurrency}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Payment method</TitleSelect>
              <CustomSelect
                placeholder="Transfer"
                value={paymentMethod}
                options={paymentMethodOption}
                onChange={handleChangePaymentMethod}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Payment modality</TitleSelect>
              <CustomSelect
                placeholder="Net"
                value={paymentModality}
                options={paymentModalityOption}
                onChange={handleChangePaymentModality}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Terms of payment</TitleSelect>
              <CustomSelect
                placeholder="Default"
                value={termsPayment}
                options={termPaymentOption}
                onChange={handleChangeTermsPayment}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Tax rate</TitleSelect>
              <CustomSelect
                placeholder="Tax rate"
                value={taxeRates}
                options={taxeRatesOption}
                onChange={handleChangeTaxeRates}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Change rate</TitleSelect>
              <CustomInput
                defaultValue={providerDetail?.change_rate?.toString()}
                placeholder="0"
                isDisabled={true}
                width="100px"
                debounceTime={1000}
              />
            </CustomContainer>
          </InputRowContainer>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Account status</TitleSelect>
              <CustomSelect
                placeholder="Active account"
                value={accountStatus}
                options={accountStatusOption}
                onChange={handleChangeAccountStatus}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '250px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Account Type</TitleSelect>
              <CustomSelect
                placeholder="Active account"
                value={accountType}
                options={accountTypeOption}
                onChange={handleChangeAccountType}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '250px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Current outstandings</TitleSelect>
              <CustomInput
                isDisabled={true}
                defaultValue={(
                  providerDetail.total_invoices_amount_with_tax -
                  providerDetail.total_invoices_amount_paid
                ).toString()}
                placeholder="ex: +$ 100000"
                debounceTime={1000}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Max financial outstandings</TitleSelect>
              <CustomInput
                defaultValue={providerDetail?.hard_financial_limit?.toString()}
                placeholder="ex: - 500"
                callBack={handleChangeHardFinancialLimit}
                debounceTime={1000}
              />
            </CustomContainer>
          </InputRowContainer>
        </FormRow>
      </SettingCard>
      <SettingCard title="Provider’s adresses" icon={Dashboard()}>
        <>
          <Array
            template={addressProviders}
            content={providerSettingsPreProcessing}
            lineSelected={handleLineSelected}
            lineClicked={handleLineClicked}
            isSearchable={false}
            tools={
              <>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  text="Address"
                  leftIcon={Cross()}
                  onClick={createProviderAddress}
                />
                <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
                {!!selectedAddress.length && (
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedAddress.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteProviderAddress}
                  />
                )}
              </>
            }
          />
          {!providerSettingsPreProcessing.length && (
            <HelperText>
              Add a billing and shipping address to start a business with this provider
            </HelperText>
          )}
          <ProviderAddressModal
            isDisplayed={modalAddressDisplayed}
            addressDetail={addressDetail}
            onClose={handleCloseAddressModal}
          />
        </>
      </SettingCard>
    </Container>
  );
};

export default ProviderSettings;
