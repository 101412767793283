import React, { useEffect, useReducer, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import useApi from 'services/axios';
import {
  CompaniesContext,
  CompaniesContactContext,
  AdminsContext,
  ProvidersContext,
  ProvidersContactContext,
  QuotationsContext,
  ErrorContext,
  SuccessContext,
  AllCompaniesContext,
  AllProvidersContext,
  CurrencyContext,
  AllCompaniesContactContext,
  AllProvidersContactContext
} from 'contexts';
import {
  getAdmin,
  getCompanies,
  getContacts,
  getProviderCurrency,
  getProviders,
  getProvidersContact
} from 'services/endpoints';
import { Companies, CompaniesContacts } from 'components/companies';
import { Company } from 'components/companies/company';
import NavigationBar from 'components/NavigationBar';
import { Providers, ProvidersContact } from 'components/providers';
import { Provider } from 'components/providers/provider';
import {
  Quotations,
  Quotation,
  Order,
  Orders,
  DeliveryNote,
  Invoices,
  CreditNote,
  Payment
} from 'components/sales';
import LoadingPage from 'components/LoadingPage';
import {
  Purchases,
  Purchase,
  RequestForQuotation,
  ProviderDeliveryNote,
  ProviderCreditNote,
  ProviderInvoices,
  ProviderPayment
} from 'components/purchases';
import { ErrorModal, Success } from 'components/modal';
import LoginPage from 'components/LoginPage';
import { getToken } from 'components/Tools';

import Header from './components/Header';

const MainContainer = styled.div`
  display: flex;
`;

const Left = styled.div`
  min-width: 300px;
  z-index: 1000;
`;

const Right = styled.div`
  width: calc(100vw - 300px);
  min-width: 900px;
`;

const PCBE = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAnimationFinished, setIsAnimationFinished] = useState<boolean>(false);
  const [errorState, errorDispatch] = useReducer(ErrorContext.reducer, ErrorContext.initialState);
  const [successState, successDispatch] = useReducer(
    SuccessContext.reducer,
    SuccessContext.initialState
  );
  const [adminsState, adminsDispatch] = useReducer(
    AdminsContext.reducer,
    AdminsContext.initialState
  );
  const [currencyState, currencyDispatch] = useReducer(
    CurrencyContext.reducer,
    CurrencyContext.initialState
  );

  const [allCompaniesState, allCompaniesDispatch] = useReducer(
    AllCompaniesContext.reducer,
    AllCompaniesContext.initialState
  );

  const [allProvidersState, allProvidersDispatch] = useReducer(
    AllProvidersContext.reducer,
    AllProvidersContext.initialState
  );

  const [companiesState, companiesDispatch] = useReducer(
    CompaniesContext.reducer,
    CompaniesContext.initialState
  );

  const [companiesContactState, companiesContactDispatch] = useReducer(
    CompaniesContactContext.reducer,
    CompaniesContactContext.initialState
  );
  const [allCompaniesContactState, allCompaniesContactDispatch] = useReducer(
    AllCompaniesContactContext.reducer,
    AllCompaniesContactContext.initialState
  );
  const [providersState, providersDispatch] = useReducer(
    ProvidersContext.reducer,
    ProvidersContext.initialState
  );
  const [providersContactState, providersContactDispatch] = useReducer(
    ProvidersContactContext.reducer,
    ProvidersContactContext.initialState
  );
  const [allProvidersContactState, allProvidersContactDispatch] = useReducer(
    AllProvidersContactContext.reducer,
    AllProvidersContactContext.initialState
  );
  const [quotationsState, quotationsDispatch] = useReducer(
    QuotationsContext.reducer,
    QuotationsContext.initialState
  );
  const accessToken = getToken();
  const { get } = useApi();

  const handleCloseModal = () => {
    errorDispatch({ type: 'HIDE_ERROR' });
  };

  const handleSuccessModal = () => {
    successDispatch({ type: 'HIDE_SUCCESS' });
  };

  useEffect(() => {
    (async () => {
      if (accessToken) {
        const allContacts = await get(getContacts + '?no_pagination=true');
        const allCompanies = await get(getCompanies + '?no_pagination=true');
        const allProviders = await get(getProviders + '?no_pagination=true');
        const allProvidersContact = await get(getProvidersContact + '?no_pagination=true');
        const currencyResult = await get(getProviderCurrency);
        const admins = await get(getAdmin);
        allCompaniesDispatch({ type: 'SET_ALL_DATA', payload: allCompanies.data });
        allProvidersDispatch({ type: 'SET_ALL_DATA', payload: allProviders.data });
        allCompaniesContactDispatch({ type: 'SET_ALL_DATA', payload: allContacts.data });
        allProvidersContactDispatch({ type: 'SET_ALL_DATA', payload: allProvidersContact.data });
        currencyDispatch({ type: 'SET_DATA', payload: currencyResult.data });
        adminsDispatch({ type: 'SET_DATA', payload: admins.data });
        setIsLoading(false);
      }
    })();
  }, []);

  if (!accessToken) {
    return <LoginPage />;
  }

  if (!isAnimationFinished) {
    return <LoadingPage callback={setIsAnimationFinished} isLoading={isLoading} />;
  }

  return (
    <SuccessContext.Context.Provider value={{ state: successState, dispatch: successDispatch }}>
      <ErrorContext.Context.Provider value={{ state: errorState, dispatch: errorDispatch }}>
        <AdminsContext.Context.Provider value={{ state: adminsState, dispatch: adminsDispatch }}>
          <CurrencyContext.Context.Provider
            value={{ state: currencyState, dispatch: currencyDispatch }}
          >
            <CompaniesContext.Context.Provider
              value={{ state: companiesState, dispatch: companiesDispatch }}
            >
              <AllCompaniesContext.Context.Provider
                value={{ state: allCompaniesState, dispatch: allCompaniesDispatch }}
              >
                <CompaniesContactContext.Context.Provider
                  value={{ state: companiesContactState, dispatch: companiesContactDispatch }}
                >
                  <AllCompaniesContactContext.Context.Provider
                    value={{
                      state: allCompaniesContactState,
                      dispatch: allCompaniesContactDispatch
                    }}
                  >
                    <AllProvidersContext.Context.Provider
                      value={{ state: allProvidersState, dispatch: allProvidersDispatch }}
                    >
                      <ProvidersContext.Context.Provider
                        value={{ state: providersState, dispatch: providersDispatch }}
                      >
                        <AllProvidersContactContext.Context.Provider
                          value={{
                            state: allProvidersContactState,
                            dispatch: allProvidersContactDispatch
                          }}
                        >
                          <ProvidersContactContext.Context.Provider
                            value={{
                              state: providersContactState,
                              dispatch: providersContactDispatch
                            }}
                          >
                            <QuotationsContext.Context.Provider
                              value={{ state: quotationsState, dispatch: quotationsDispatch }}
                            >
                              <MainContainer>
                                <Left>
                                  <NavigationBar />
                                </Left>
                                <Right>
                                  <Header />
                                  <Routes>
                                    <Route
                                      path="Clients-Contacts"
                                      element={<CompaniesContacts />}
                                    />
                                    <Route path="Clients-Companies" element={<Companies />} />
                                    <Route path="Providers-Factories" element={<Providers />} />
                                    <Route
                                      path="Providers-Contacts"
                                      element={<ProvidersContact />}
                                    />
                                    <Route
                                      path="Clients-Companies/Company/"
                                      element={<Company />}
                                    />
                                    <Route
                                      path="Clients-Providers/Provider"
                                      element={<Provider />}
                                    />
                                    <Route path="Sales-Quotations" element={<Quotations />} />
                                    <Route
                                      path="Sales-Quotations/Quotation"
                                      element={<Quotation />}
                                    />
                                    <Route path="Sales-Orders" element={<Orders />} />
                                    <Route path="Sales-Orders/Order" element={<Order />} />
                                    <Route path="Sales-DeliveryNotes" element={<DeliveryNote />} />
                                    <Route path="Sales-Invoices" element={<Invoices />} />
                                    <Route path="Sales-CreditNotes" element={<CreditNote />} />
                                    <Route path="Sales-Payments" element={<Payment />} />
                                    <Route
                                      path="Purchases-RequestForQuotation"
                                      element={<RequestForQuotation />}
                                    />
                                    <Route path="Purchases-Purchases" element={<Purchases />} />
                                    <Route
                                      path="Purchases-Purchases/Purchase"
                                      element={<Purchase />}
                                    />
                                    <Route
                                      path="Purchases-DeliveryNotes"
                                      element={<ProviderDeliveryNote />}
                                    />
                                    <Route
                                      path="Purchases-CreditNotes"
                                      element={<ProviderCreditNote />}
                                    />
                                    <Route
                                      path="Purchases-Invoices"
                                      element={<ProviderInvoices />}
                                    />
                                    <Route
                                      path="Purchases-Payments"
                                      element={<ProviderPayment />}
                                    />
                                    <Route
                                      path="*"
                                      element={<Navigate to="/Clients-Companies" />}
                                    />
                                  </Routes>
                                  <ErrorModal
                                    isDisplayed={errorState.isErrorModalDisplayed}
                                    text={errorState.text}
                                    onClose={handleCloseModal}
                                  />
                                  <Success
                                    isDisplayed={successState.isSuccessModalDisplayed}
                                    text={successState.text}
                                    onClose={handleSuccessModal}
                                  />
                                </Right>
                              </MainContainer>
                            </QuotationsContext.Context.Provider>
                          </ProvidersContactContext.Context.Provider>
                        </AllProvidersContactContext.Context.Provider>
                      </ProvidersContext.Context.Provider>
                    </AllProvidersContext.Context.Provider>
                  </AllCompaniesContactContext.Context.Provider>
                </CompaniesContactContext.Context.Provider>
              </AllCompaniesContext.Context.Provider>
            </CompaniesContext.Context.Provider>
          </CurrencyContext.Context.Provider>
        </AdminsContext.Context.Provider>
      </ErrorContext.Context.Provider>
    </SuccessContext.Context.Provider>
  );
};

export default PCBE;
