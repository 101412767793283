import React from 'react';

const CompanyIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.2657 14.3909L19.0471 12.5442C19.9846 12.3482 20.5849 11.418 20.3916 10.4673C20.2702 9.87681 20.0903 9.31597 19.8587 8.78476C18.3187 5.25782 14.4968 3.17175 10.6232 4.01302C6.51797 4.90445 3.59083 9.19514 4.22257 13.4038C4.93525 18.155 9.43388 21.2807 14.0157 20.3163C16.165 19.8649 18.0107 18.5904 19.2158 16.7301C17.8151 15.7976 16.0188 15.6631 14.5283 16.4405C14.1483 16.6389 13.7414 16.7848 13.312 16.876C10.6277 17.4414 7.99279 15.5787 7.63308 12.77C7.33182 10.4149 8.80663 8.1578 11.0616 7.51944C13.1344 6.93352 15.232 7.8295 16.3268 9.528L12.9545 10.237C11.0796 10.6314 9.87677 12.4918 10.2657 14.3909Z"
        fill="#1975FF"
      />
      <path
        d="M19.2156 16.73C17.8149 15.7976 16.0186 15.663 14.5281 16.4405C14.1481 16.6388 13.7412 16.7847 13.3118 16.8759C13.3118 16.8759 6.79204 19.3063 4.22461 13.4083C4.94178 18.1572 9.43592 21.2783 14.0155 20.3162C16.1625 19.8648 18.0105 18.5904 19.2156 16.73Z"
        fill="#1975FF"
      />
      <path
        d="M10.2441 4.10653C15.8129 5.19859 16.3277 9.52803 16.3277 9.52803L12.9532 10.2371C11.0805 10.6315 9.87542 12.4896 10.2644 14.391L19.0458 12.5443C19.9833 12.3482 20.5836 11.418 20.3902 10.4673C20.2688 9.87685 20.089 9.31601 19.8574 8.7848C18.2702 5.14159 14.2437 3.035 10.2441 4.10653Z"
        fill="#1975FF"
      />
    </svg>
  );
};

export default CompanyIcon;
