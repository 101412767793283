import React from 'react';

const Send = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.49043 7.94929L17.0904 4.78929C17.8404 4.58929 18.5204 5.26929 18.3204 6.01929L15.1504 17.6293C14.9204 18.4793 13.7804 18.6393 13.3304 17.8793L10.4204 13.0293C10.3404 12.8893 10.2204 12.7693 10.0804 12.6893L5.24043 9.76929C4.48043 9.31929 4.64043 8.17929 5.49043 7.94929V7.94929Z"
        stroke="#1975FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M13.1293 9.98047L10.2793 12.8305"
        stroke="#1975FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Send;
