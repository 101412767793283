import { DefaultTheme } from 'styled-components';

const defaultTheme: DefaultTheme = {
  colors: {
    primary: {
      lighter: '#CDE1FF',
      light: '#6FA9FF',
      main: '#1975FF',
      mainTransparent: '#1975FF14',
      dark: '#185FC9',
      darker: '#144B9E'
    },
    grey: {
      lightest: '#F9FAFC',
      lighter: '#EEF1F3',
      light: '#D9DEE2',
      main: '#BFC6CC',
      dark: '#A9B2B9',
      darker: '#919BA3'
    },
    common: {
      white: '#FFFFFF',
      black: '#000000',
      transparent: 'transparent'
    },
    info: {
      lighter: '#C2E5FF',
      light: '#69BFFE',
      main: '#20A1FF',
      dark: '#1A7EC8',
      darker: '#0D446C'
    },
    success: {
      lighter: '#D8F5CE',
      light: '#8ADA6D',
      main: '#65D83C',
      dark: '#4A9D2D',
      darker: '#336B1F'
    },
    warning: {
      lighter: '#FCEDC7',
      light: '#FFD568',
      main: '#FFC225',
      dark: '#CC9D24',
      darker: '#8A6A16'
    },
    error: {
      lighter: '#FFE5E5',
      light: '#FF8484',
      main: '#FF4242',
      dark: '#C92F2F',
      darker: '#812222'
    },
    text: {
      primary: '#1A2128',
      secondary: '#434D56',
      tertiary: '#A9B2B9'
    }
  },

  spacing: {
    xxxxs: 4,
    xxxs: 8,
    xxs: 12,
    xs: 16,
    s: 24,
    m: 32,
    l: 40,
    xl: 56,
    xxl: 64,
    xxxl: 80
  },

  fontFamily: "'Inter', 'Helvetica Neue', Arial, sans-serif",

  transitions: {
    generic: 'all 0.15s ease'
  }
};

export default defaultTheme;
