import React, { useContext, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProviderState, useProviderHandler } from 'utils/provider';
import { CustomInput, SmallLoader } from 'components/common';
import { AllProvidersContext } from 'contexts';

import ProviderContent from './ProviderContent';

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 24px;

  background: rgb(238 248 255 / 50%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const NavigationButton = styled.button<{
  isSelected: boolean;
}>`
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  font-weight: ${(props) => (props.isSelected ? 600 : 400)};
  font-size: 15px;
  color: ${(props) =>
    props.isSelected ? props.theme.colors.primary.main : props.theme.colors.text.secondary};
  cursor: pointer;
`;

const List = styled.ul`
  display: flex;
  height: 100%;
  margin: 0;
  gap: 32px;
  margin-left: 64px;

  list-style-type: none;
  padding: 0;
`;

const Item = styled.li<{
  isSelected: boolean;
}>`
  display: flex;
  justify-content: center;
  width: 70px;

  ${(props) =>
    props.isSelected &&
    css`
      border-bottom: 2px solid ${(props) => props.theme.colors.primary.main};
      border-radius: 2px;
    `}

  /* margin-bottom: 0; */
  :hover {
    border-bottom: 2px solid ${(props) => props.theme.colors.primary.main};
    border-radius: 2px;
  }

  :hover ${NavigationButton} {
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary.main};
  }
`;

const findProviderState = (state: string) => {
  if (state === ProviderState.ACTIVITIES) {
    return ProviderState.ACTIVITIES;
  }
  if (state === ProviderState.CONTACTS) {
    return ProviderState.CONTACTS;
  }
  return ProviderState.SETTINGS;
};

const Provider: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const onChangeProvider = useProviderHandler();

  const { state: allProvidersState } = useContext(AllProvidersContext.Context);
  const [isLoading, setIsLoading] = useState(true);
  const [providerState, setProviderState] = useState<ProviderState>();
  const [providerDetails, setProvidersDetails] = useState<Provider>();
  const [providerId, setProviderId] = useState<string>(
    new URLSearchParams(window.location.search).get('providerId')
  );

  const handleNavigationClick = (state: ProviderState) => () => {
    navigate(`/Clients-Providers/Provider?providerId=${providerId}&providerView=${state}`);
  };

  const handleChangeProviderName = () =>
    onChangeProvider('company_name', providerDetails, setProviderState);

  useEffect(() => {
    const providerView = new URLSearchParams(window.location.search).get('providerView');

    setProviderState(findProviderState(providerView));
  }, [location]);

  useEffect(() => {
    (async () => {
      const providerId = new URLSearchParams(window.location.search).get('providerId');
      setProvidersDetails(allProvidersState.find((provider) => provider.id == Number(providerId)));
      setProviderId(providerId);
      setIsLoading(false);
    })();
  }, [allProvidersState]);

  return (
    <div>
      <Header>
        <CustomInput
          defaultValue={providerDetails?.company_name}
          placeholder="Edit name"
          callBack={handleChangeProviderName}
          debounceTime={1000}
        />
        <List>
          <Item isSelected={providerState === ProviderState.ACTIVITIES}>
            <NavigationButton
              onClick={handleNavigationClick(ProviderState.ACTIVITIES)}
              isSelected={providerState === ProviderState.ACTIVITIES}
            >
              Activities
            </NavigationButton>
          </Item>
          <Item isSelected={providerState === ProviderState.CONTACTS}>
            <NavigationButton
              onClick={handleNavigationClick(ProviderState.CONTACTS)}
              isSelected={providerState === ProviderState.CONTACTS}
            >
              Contacts
            </NavigationButton>
          </Item>
          <Item isSelected={providerState === ProviderState.SETTINGS}>
            <NavigationButton
              onClick={handleNavigationClick(ProviderState.SETTINGS)}
              isSelected={providerState === ProviderState.SETTINGS}
            >
              Settings
            </NavigationButton>
          </Item>
        </List>
      </Header>
      {isLoading ? (
        <SmallLoader />
      ) : (
        <ProviderContent
          providerState={providerState}
          providerDetail={providerDetails}
          setProvidersDetails={setProvidersDetails}
        />
      )}
    </div>
  );
};

export default Provider;
