import React, { useState, useEffect } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import loader from '../../assets/pcb-loader.json';

type LoaderProps = {
  isLoading: boolean;
  callback: React.Dispatch<React.SetStateAction<boolean>>;
};

const Loader: React.FC<LoaderProps> = ({ callback, isLoading }) => {
  const [isLoadingState, setIsLoadingState] = useState(isLoading);

  useEffect(() => {
    setIsLoadingState(isLoading);
  }, [isLoading]);

  return (
    <Player
      autoplay
      src={loader}
      style={{ height: '300px', width: '300px' }}
      loop={true}
      onEvent={(event) => {
        if (event === 'loop' && !isLoadingState) {
          callback(true);
        }
      }}
    ></Player>
  );
};

export default Loader;
