import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PCBSeparation } from './Tools';

const PCBWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
`;

const HrScoring = styled.hr`
  margin: 0;
  width: 4px;
  position: absolute;
  border: none;
  border-top: 2px solid #018830;
  z-index: 1;
`;

const Hr = styled.hr<{
  width: number;
  separation: PCBSeparation;
}>`
  margin: 0;
  width: ${(props) => `${props.width}px`};
  position: absolute;

  ${(props) =>
    props.separation === PCBSeparation.NoSeparation
      ? `
      border: none;
      border-top: 0.5px dashed #fff;;
      z-index: 2;
  `
      : `
  border: none;
  border-top: 1px solid #86e06b;
  z-index: 1;

 `}
`;

interface PCBViewerProps {
  width: number;
  height: number;
  circuitsX: number;
  circuitsY: number;
  outerMarginLeft: number;
  outerMarginRight: number;
  outerMarginBottom: number;
  outerMarginTop: number;
  innerMarginX: number;
  innerMarginY: number;
  pcbSeparationX: PCBSeparation;
  pcbSeparationY: PCBSeparation;
}

const PCBViewer: React.FC<PCBViewerProps> = ({
  width,
  height,
  circuitsX,
  circuitsY,
  outerMarginRight,
  outerMarginLeft,
  outerMarginTop,
  outerMarginBottom,
  innerMarginX,
  innerMarginY,
  pcbSeparationX = PCBSeparation.VCut,
  pcbSeparationY = PCBSeparation.VCut
}) => {
  const circuitWidth = Number(width);
  const circuitHeight = Number(height);
  const circuits = [];

  const isVCutNoBorderX = pcbSeparationX === PCBSeparation.VCutNoBorder;
  const isVCutNoBorderY = pcbSeparationY === PCBSeparation.VCutNoBorder;
  const isMillingX = pcbSeparationX === PCBSeparation.Milling;
  const isMillingScoringX = pcbSeparationX === PCBSeparation.MillingScoring;
  const isMillingScoringY = pcbSeparationY === PCBSeparation.MillingScoring;
  const isMillingY = pcbSeparationY === PCBSeparation.Milling;
  const isNoSeparationY = pcbSeparationY === PCBSeparation.NoSeparation;
  const isNoSeparationX = pcbSeparationX === PCBSeparation.NoSeparation;

  const heightBorderLength = isVCutNoBorderX
    ? !isNoSeparationX
      ? circuitHeight
      : circuitHeight
    : 10000;
  const widthBorderLength = isVCutNoBorderY
    ? !isNoSeparationY
      ? circuitWidth + 1
      : circuitWidth
    : 10000;

  const maxYMargin = Math.max(outerMarginLeft, outerMarginRight);

  for (let j = 0; j < circuitsY; j++) {
    for (let i = 0; i < circuitsX; i++) {
      circuits.push(
        <div
          style={{
            position: 'relative'
          }}
          key={`${i}-${j}-pcb`}
        >
          {!isMillingY && !isMillingScoringY && !isVCutNoBorderY && (
            <>
              {((Number(outerMarginTop) === 0 && j !== 0) || Number(outerMarginTop) !== 0) &&
                i === 0 && (
                  <Hr
                    style={{
                      top: isNoSeparationY ? -0.25 : -0.5,
                      left: -maxYMargin,
                      transform: 'rotate(0deg)'
                    }}
                    width={widthBorderLength}
                    separation={pcbSeparationY}
                  />
                )}

              {((Number(outerMarginBottom) === 0 && j !== circuitsY - 1) ||
                Number(outerMarginBottom) !== 0) &&
                i === 0 && (
                  <Hr
                    style={{
                      bottom: isNoSeparationY ? -0.25 : -0.5,
                      left: -maxYMargin,
                      transform: 'rotate(0deg)'
                    }}
                    width={widthBorderLength}
                    separation={pcbSeparationY}
                  />
                )}
            </>
          )}

          {isVCutNoBorderY && (
            <>
              <Hr
                style={{
                  top: -0.5,
                  left: -0.5,
                  transform: 'rotate(0deg)'
                }}
                width={widthBorderLength}
                separation={pcbSeparationY}
              />
              <Hr
                style={{
                  bottom: -0.5,
                  left: -0.5,
                  transform: 'rotate(0deg)'
                }}
                width={widthBorderLength}
                separation={pcbSeparationY}
              />
            </>
          )}

          {isMillingScoringY && (
            <>
              <HrScoring
                style={{
                  top: 0,
                  right: circuitWidth / 2 - 2
                }}
              />
              <HrScoring
                style={{
                  bottom: 0,
                  right: circuitWidth / 2 - 2
                }}
              />
            </>
          )}
          {!isMillingX && !isMillingScoringX && !isVCutNoBorderX && (
            <>
              {((Number(outerMarginRight) === 0 && i !== circuitsX - 1) ||
                Number(outerMarginRight) !== 0) &&
                j === circuitsY - 1 && (
                  <Hr
                    style={{
                      top: circuitHeight / 2 - 0.5,
                      right: -heightBorderLength / 2,
                      transform: 'rotate(90deg)'
                    }}
                    width={heightBorderLength}
                    separation={pcbSeparationX}
                  />
                )}
              {((Number(outerMarginLeft) === 0 && i !== 0) || Number(outerMarginLeft) !== 0) &&
                j === circuitsY - 1 && (
                  <Hr
                    style={{
                      bottom: circuitHeight / 2 - 0.5,
                      left: -heightBorderLength / 2,
                      transform: 'rotate(90deg)'
                    }}
                    width={heightBorderLength}
                    separation={pcbSeparationX}
                  />
                )}
            </>
          )}

          {isVCutNoBorderX && (
            <>
              <Hr
                style={{
                  top: circuitHeight / 2 - 0.5,
                  right: -heightBorderLength / 2,
                  transform: 'rotate(90deg)'
                }}
                width={heightBorderLength}
                separation={pcbSeparationX}
              />
              <Hr
                style={{
                  bottom: circuitHeight / 2 - 0.5,
                  left: -heightBorderLength / 2,
                  transform: 'rotate(90deg)'
                }}
                width={heightBorderLength}
                separation={pcbSeparationX}
              />
            </>
          )}

          {isMillingScoringX && (
            <>
              <HrScoring
                style={{
                  top: circuitHeight / 2 - 1,
                  right: -1,
                  transform: 'rotate(90deg)'
                }}
              />
              <HrScoring
                style={{
                  top: circuitHeight / 2 - 1,
                  left: -1,
                  transform: 'rotate(90deg)'
                }}
              />
            </>
          )}

          <div
            style={{
              backgroundColor: '#048731',
              width: `${circuitWidth}px`,
              height: `${circuitHeight}px`,
              borderLeft:
                ((isMillingX || isMillingScoringX) && Number(outerMarginLeft) !== 0) ||
                ((isMillingX || isMillingScoringX) && Number(outerMarginLeft) === 0 && i !== 0)
                  ? `${i !== 0 && Number(innerMarginX) === 0 ? '1px' : '2px'} solid #000`
                  : 'none',
              borderRight:
                ((isMillingX || isMillingScoringX) && Number(outerMarginRight) !== 0) ||
                ((isMillingX || isMillingScoringX) &&
                  Number(outerMarginRight) === 0 &&
                  i !== circuitsX - 1)
                  ? `${
                      i !== circuitsX - 1 && Number(innerMarginX) === 0 ? '1px' : '2px'
                    } solid #000`
                  : 'none',
              borderTop:
                ((isMillingY || isMillingScoringY) && Number(outerMarginTop) !== 0) ||
                ((isMillingY || isMillingScoringY) && Number(outerMarginTop) === 0 && j !== 0)
                  ? `${j !== 0 && Number(innerMarginY) === 0 ? '1px' : '2px'}  solid #000`
                  : 'none',
              borderBottom:
                ((isMillingY || isMillingScoringY) && Number(outerMarginBottom) !== 0) ||
                ((isMillingY || isMillingScoringY) &&
                  Number(outerMarginBottom) === 0 &&
                  j !== circuitsY - 1)
                  ? `${
                      j !== circuitsY - 1 && Number(innerMarginY) === 0 ? '1px' : '2px'
                    }  solid #000`
                  : 'none'
            }}
          ></div>
        </div>
      );
    }
  }

  return (
    <PCBWrapper
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${circuitsX}, ${circuitWidth}px)`,
        gridTemplateRows: `repeat(${circuitsY}, ${circuitHeight}px)`,
        gap: `${innerMarginY}px ${innerMarginX}px`,
        position: 'relative',
        padding: `${outerMarginTop}px ${outerMarginRight}px ${outerMarginBottom}px ${outerMarginLeft}px`,
        overflow: 'hidden'
      }}
    >
      {circuits}
    </PCBWrapper>
  );
};

interface PCBGeneratorProps {
  circuitsX: number;
  circuitsY: number;
  outerWidth: number;
  outerHeight: number;
  outerMarginTop: number;
  outerMarginBottom: number;
  outerMarginLeft: number;
  outerMarginRight: number;
  innerMarginX: number;
  innerMarginY: number;
  pcbSeparationX: PCBSeparation;
  pcbSeparationY: PCBSeparation;
}

const PCBGenerator: React.FC<PCBGeneratorProps> = ({
  circuitsX = 0,
  circuitsY = 0,
  outerWidth = 0,
  outerHeight = 0,
  outerMarginTop = 0,
  outerMarginBottom = 0,
  outerMarginLeft = 0,
  outerMarginRight = 0,
  innerMarginX = 0,
  innerMarginY = 0,
  pcbSeparationX = PCBSeparation.VCut,
  pcbSeparationY = PCBSeparation.VCut
}) => {
  const [viewerScale, setViewerScale] = useState<number>(1);

  useEffect(() => {
    const calculateViewerSize = () => {
      if (outerWidth === 0 || outerHeight === 0) {
        return;
      }

      let scale = 1;

      const totalOuterWidth = Number(outerWidth) * Number(circuitsX);
      const totalOuterHeight = Number(outerHeight) * Number(circuitsY);

      const availableWidth = 630;
      const availableHeight = 630;

      const totalInnerMarginX = Number(innerMarginX) * (circuitsX - 1);
      const totalInnerMarginY = Number(innerMarginY) * (circuitsY - 1);

      const maxOuterWidth =
        totalOuterWidth + Number(outerMarginLeft) + Number(outerMarginRight) + totalInnerMarginX;
      const maxOuterHeight =
        totalOuterHeight + Number(outerMarginTop) + Number(outerMarginBottom) + totalInnerMarginY;

      const widthScale = availableWidth / maxOuterWidth;
      const heightScale = availableHeight / maxOuterHeight;

      scale = Math.min(widthScale, heightScale);

      if (scale < 1) {
        const widthRatio = availableWidth / maxOuterWidth;
        const heightRatio = availableHeight / maxOuterHeight;
        scale = Math.min(widthRatio, heightRatio);
      }

      setViewerScale(scale);
    };

    calculateViewerSize();
  }, [
    outerWidth,
    outerHeight,
    outerMarginLeft,
    outerMarginRight,
    outerMarginTop,
    outerMarginBottom,
    innerMarginX,
    innerMarginY,
    circuitsX,
    circuitsY
  ]);

  return (
    <div
      style={{
        width: 650,
        height: 650,
        backgroundColor: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      }}
    >
      <div
        style={{
          backgroundColor: '#1aaa47',
          transform: `scale(${viewerScale})`
        }}
      >
        <div></div>
        <PCBViewer
          circuitsX={circuitsX}
          circuitsY={circuitsY}
          width={outerWidth}
          height={outerHeight}
          outerMarginLeft={outerMarginLeft}
          outerMarginRight={outerMarginRight}
          outerMarginBottom={outerMarginBottom}
          outerMarginTop={outerMarginTop}
          innerMarginX={innerMarginX}
          innerMarginY={innerMarginY}
          pcbSeparationX={pcbSeparationX}
          pcbSeparationY={pcbSeparationY}
        />
      </div>
    </div>
  );
};

export default PCBGenerator;
