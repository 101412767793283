import React from 'react';
import styled from 'styled-components';
import ReactSelect, { components, ControlProps, Props, GroupBase } from 'react-select';

declare module 'react-select/dist/declarations/src/Select' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  export interface Props<Option, IsMulti extends boolean, Group extends GroupBase<Option>> {
    hasError?: boolean;
    width?: number;
    iconLeft?: JSX.Element;
    iconRight?: JSX.Element;
    onTextInputClear?: React.MouseEventHandler<HTMLButtonElement>;
    usePortalStyles?: boolean;
  }
}

const IconLeft = styled.span`
  display: inline-flex;
  margin-left: ${(props) => props.theme.spacing.xs}px;
`;

const IconRight = styled.span`
  display: inline-flex;
  margin-right: ${(props) => props.theme.spacing.xs}px;
`;

const Control = ({ children, ...props }: ControlProps) => {
  const { iconLeft, iconRight } = props.selectProps;

  return (
    <components.Control {...props}>
      {!!iconLeft && <IconLeft>{iconLeft}</IconLeft>}
      {children}
      {!!iconRight && <IconRight>{iconRight}</IconRight>}
    </components.Control>
  );
};

function CustomSelect<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) {
  const { usePortalStyles = false, menuPlacement = 'bottom', ...restProps } = props;

  return (
    <ReactSelect
      components={{ Control }}
      menuShouldScrollIntoView={true}
      menuPlacement={menuPlacement}
      menuPortalTarget={usePortalStyles ? document.body : undefined}
      styles={usePortalStyles ? { menuPortal: (base) => ({ ...base, zIndex: 9999 }) } : undefined}
      {...restProps}
    />
  );
}

export default CustomSelect;
