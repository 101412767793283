import React from 'react';
import styled from 'styled-components';

import { CheckMark } from 'svgs';

const StyledCheckbox = styled.div`
  content: '';
  cursor: pointer;
  width: 18px;
  height: 18px;

  border: 2px solid #a9b2b9;
  border-radius: 4px;
`;

const UnselectedCheckbox = styled(StyledCheckbox)`
  background: ${(props) => props.theme.colors.common.white};
`;

const SelectedCheckbox = styled(StyledCheckbox)`
  display: flex;
  justify-content: center;
  align-items: center;

  border-color: ${(props) => props.theme.colors.primary.main};
  background: ${(props) => props.theme.colors.primary.main};
  color: ${(props) => props.theme.colors.common.white};
`;

type CheckboxProps = {
  isSelected: boolean;
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const Checkbox = ({ isSelected, onClick }: CheckboxProps) => {
  if (isSelected) {
    return <SelectedCheckbox onClick={onClick}>{CheckMark()}</SelectedCheckbox>;
  }

  return <UnselectedCheckbox onClick={onClick} />;
};

export default Checkbox;
