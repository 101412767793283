import React from 'react';

const Info = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="10" fill="#232C34" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 17C11.4477 17 11 16.5523 11 16L11 11.5C11 10.9477 11.4477 10.5 12 10.5C12.5523 10.5 13 10.9477 13 11.5L13 16C13 16.5523 12.5523 17 12 17Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9.5C11.4477 9.5 11 9.05228 11 8.5L11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8L13 8.5C13 9.05228 12.5523 9.5 12 9.5Z"
        fill="white"
      />
    </svg>
  );
};

export default Info;
