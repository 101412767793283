import { createContext } from 'react';

const initialState: CompaniesContactContextState = {
  count: 0,
  next: null,
  previous: null,
  results: []
};

const reducer = (
  state: CompaniesContactContextState,
  action: CompaniesContactDispatchAction
): CompaniesContactContextState => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return action.payload;
    case 'SET_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type CompaniesContactContextType = {
  state: CompaniesContactContextState;
  dispatch: React.Dispatch<CompaniesContactDispatchAction>;
};

const Context = createContext<CompaniesContactContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'CompaniesContact';

export { Context, initialState, reducer };
