import { useContext } from 'react';

import { AllProvidersContext } from 'contexts';
import useApi from 'services/axios';
import { patchProvider } from 'services/endpoints';

export enum ProviderState {
  ACTIVITIES = 'activities',
  CONTACTS = 'contacts',
  SETTINGS = 'settings'
}

export const AddressTypeOption: Option[] = [
  { value: 'shipping', label: 'Shipping' },
  { value: 'billing', label: 'Billing' }
];

export const taxeRatesOption: NumberOption[] = [
  { value: 0, label: '0' },
  { value: 0.05, label: '5' },
  { value: 0.1, label: '10' },
  { value: 0.15, label: '15' },
  { value: 0.2, label: '20' },
  { value: 0.25, label: '25' },
  { value: 0.3, label: '30' }
];

export const termPaymentOption: NumberOption[] = [
  { value: 0, label: '0' },
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
  { value: 25, label: '25' },
  { value: 30, label: '30' },
  { value: 35, label: '35' },
  { value: 40, label: '40' },
  { value: 45, label: '45' },
  { value: 50, label: '50' },
  { value: 55, label: '55' },
  { value: 60, label: '60' },
  { value: 65, label: '65' },
  { value: 70, label: '70' },
  { value: 75, label: '75' },
  { value: 80, label: '80' },
  { value: 85, label: '85' },
  { value: 90, label: '90' },
  { value: 95, label: '95' },
  { value: 100, label: '100' }
];

export const paymentMethodOption: Option[] = [
  { value: 'transfer', label: 'Transfer' },
  { value: 'check', label: 'Check' },
  { value: 'card', label: 'Card' }
];

export const paymentModalityOption: Option[] = [
  { value: 'Net', label: 'Net' },
  { value: 'End of month on 10th', label: 'End of month on 10th' },
  { value: 'End of month 15', label: 'End of month 15' },
  { value: 'End of month 30', label: 'End of month 30' }
];

export const accountTypeOption: Option[] = [
  { value: 'active', label: 'Active' },
  { value: 'inactive', label: 'Inactive' },
  { value: 'blocked', label: 'Blocked' },
  { value: 'dormant', label: 'Dormant' }
];

export const accountStatusOption: Option[] = [
  { value: 'client', label: 'Client' },
  { value: 'provider', label: 'Provider' },
  { value: 'prospect', label: 'Prospect' }
];

export const useProviderHandler = <T extends Option | NumberOption>() => {
  const { patch } = useApi();

  const { state: allProvidersState, dispatch: allProvidersAction } = useContext(
    AllProvidersContext.Context
  );

  const onChangeProviderHandler =
    (field: string, providerDetail: Provider, setter?: React.Dispatch<React.SetStateAction<T>>) =>
    async (newValue: Option | string) => {
      if (setter) {
        setter(newValue);
      }

      const newProviderDetail = await patch(patchProvider(providerDetail.id), {
        ...providerDetail,
        [field]: newValue.value !== undefined ? newValue.value : newValue
      });

      const newProvidersState = allProvidersState.map((provider) =>
        provider.id === newProviderDetail.data.id ? newProviderDetail.data : provider
      );

      allProvidersAction({
        type: 'UPDATE_ALL_DATA',
        payload: newProvidersState
      });
    };

  return onChangeProviderHandler;
};
