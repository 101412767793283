import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { AdminsContext, AllProvidersContext } from 'contexts';
import { getSelectStyles } from 'utils/style';
import { ButtonVariant } from 'components/common/Button';
import useApi from 'services/axios';
import { getSpecificProviderInvoices, postProviderCreditNote } from 'services/endpoints';

import { Button, CustomDatePicker, CustomInput, CustomSelect, ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const CustomContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Left = styled(Row)`
  justify-content: flex-end;
`;

type InvoiceCreditNoteModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  selectedInvoices: number;
};

const ProviderInvoiceCreditNoteModal: React.FC<InvoiceCreditNoteModalProps> = ({
  isDisplayed,
  selectedInvoices,
  onClose
}) => {
  const [creditNoteDetail, setCreditNoteDetail] = useState<Partial<ProviderCreditNotes>>();
  const [companyOption, setCompanyOption] = useState<Option>();
  const [createdDate, setCreatedDate] = useState<string>();
  const [amount, setAmount] = useState<string>();
  const [salesMan, setSalesMan] = useState<Option>();
  const [admin, setAdmin] = useState<Option>();
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { state: allProvidersContext } = useContext(AllProvidersContext.Context);
  const title = 'New Credit Note';
  const button = 'Create';
  const { post, get } = useApi();

  const companyFormatted = useMemo(
    () =>
      allProvidersContext.map((provider) => ({
        label: provider.company_name,
        value: provider.id
      })),
    [allProvidersContext]
  );

  const adminFormatted = useMemo(
    () =>
      adminsState.map((admin) => ({
        label: `${admin.last_name} ${admin.first_name}`,
        value: admin.id
      })),
    [adminsState]
  );

  const postDeliveryNote = async () => {
    const newCreditNoteDetail = await post(
      postProviderCreditNote() + `?from_invoice=true&invoice_id=${selectedInvoices}`,
      creditNoteDetail
    );
    setCreditNoteDetail(newCreditNoteDetail.data);
    onClose();
  };

  const handleSaveButtonClick = async () => {
    postDeliveryNote();
    onClose();
  };

  const handleSelectChange = (name: string, option: Option) => {
    setCreditNoteDetail({
      ...creditNoteDetail,
      [name]: option.value
    });
  };

  const handleChangeDatePicker = (name: string) => async (value: Date) => {
    setCreditNoteDetail({
      ...creditNoteDetail,
      [name]: value
    });
  };

  const handleChange = (name: string) => (value: number) => {
    setCreditNoteDetail({
      ...creditNoteDetail,
      [name]: value
    });
  };

  useEffect(() => {
    setCompanyOption(
      companyFormatted.find((company) => company.value === creditNoteDetail?.company)
    );
    setCreatedDate(creditNoteDetail?.credit_note_date);
    setAmount(creditNoteDetail?.amount);
    setSalesMan(adminFormatted.find((admin) => admin.value === creditNoteDetail?.sales_person));
    setAdmin(adminFormatted.find((admin) => admin.value === creditNoteDetail?.admin));
  }, [creditNoteDetail]);

  useEffect(() => {
    if (selectedInvoices && isDisplayed) {
      (async () => {
        const invoiceDetail = await get(getSpecificProviderInvoices(selectedInvoices));
        setCreditNoteDetail({
          provider: invoiceDetail.data.provider,
          credit_note_date: new Date(),
          amount: '0',
          sales_person: invoiceDetail.data.sales_person_id,
          admin: invoiceDetail.data.admin_id
        });
      })();
    }
  }, [selectedInvoices, isDisplayed]);

  useEffect(() => {
    if (!isDisplayed) {
      setCreditNoteDetail(null);
    }
  }, [isDisplayed]);

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={title}>
          <Content>
            <CustomContainer>
              <TitleSelect>Company</TitleSelect>
              <CustomSelect
                placeholder="Company"
                value={companyOption}
                options={companyFormatted}
                onChange={(e) => {
                  handleSelectChange('company', e);
                }}
                isDisabled={true}
                styles={getSelectStyles(null, '200px', '50px')}
              />
            </CustomContainer>
            <Row>
              <CustomContainer>
                <TitleSelect>Salesman</TitleSelect>
                <CustomSelect
                  placeholder="Salesman"
                  value={salesMan}
                  options={adminFormatted}
                  isDisabled={!!salesMan}
                  onChange={(e) => {
                    handleSelectChange('sales_person', e);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '200px', '50px')}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>Admin</TitleSelect>
                <CustomSelect
                  placeholder="Admin"
                  value={admin}
                  isDisabled={!!admin}
                  options={adminFormatted}
                  onChange={(e) => {
                    handleSelectChange('admin', e);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '200px', '50px')}
                />
              </CustomContainer>
            </Row>
            <Row>
              <CustomContainer>
                <CustomInput
                  title="Amount"
                  defaultValue={amount}
                  placeholder="0$"
                  callBack={() => handleChange('amount')}
                />
              </CustomContainer>
              <CustomContainer>
                <TitleSelect>Creation date</TitleSelect>
                <CustomDatePicker
                  value={createdDate}
                  isDisabled={false}
                  updatedDate={handleChangeDatePicker('date')}
                />
              </CustomContainer>
            </Row>
            <Left>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={button}
                onClick={handleSaveButtonClick}
              />
            </Left>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default ProviderInvoiceCreditNoteModal;
