import React from 'react';

const Money = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="8.19643" stroke="white" strokeWidth="1.60714" />
      <path
        d="M12.8494 18.6589V17.1332C13.5866 16.9617 14.1466 16.5932 14.5294 16.0275C14.9123 15.456 15.1037 14.7017 15.1037 13.7646C15.1037 13.0675 14.9066 12.476 14.5123 11.9903C14.1237 11.5046 13.5123 11.0846 12.678 10.7303C12.598 10.6789 12.478 10.6132 12.318 10.5332C12.1637 10.4475 12.0408 10.376 11.9494 10.3189C11.8637 10.2617 11.7694 10.1932 11.6666 10.1132C11.5637 10.0332 11.4894 9.9446 11.4437 9.84746C11.398 9.75031 11.3751 9.64174 11.3751 9.52174C11.3751 9.0646 11.7408 8.83603 12.4723 8.83603C12.7751 8.83603 13.0923 8.90174 13.4237 9.03317C13.7608 9.15888 14.0408 9.35317 14.2637 9.61603L14.6923 7.96174C14.6808 7.93888 14.6608 7.91031 14.6323 7.87603C14.6037 7.84174 14.5351 7.77888 14.4266 7.68746C14.3237 7.59031 14.2066 7.50174 14.0751 7.42174C13.9494 7.33603 13.7751 7.25031 13.5523 7.1646C13.3351 7.07888 13.1008 7.01317 12.8494 6.96746V5.57031H10.698V7.0446C10.2408 7.15888 9.85512 7.33888 9.54084 7.5846C9.23227 7.8246 8.99227 8.1446 8.82084 8.5446C8.65512 8.9446 8.57227 9.41603 8.57227 9.95888C8.57227 10.2332 8.60084 10.496 8.65798 10.7475C8.72084 10.9932 8.79512 11.2075 8.88084 11.3903C8.97227 11.5732 9.08655 11.7503 9.22369 11.9217C9.36655 12.0932 9.49798 12.2332 9.61798 12.3417C9.73798 12.4503 9.88084 12.5617 10.0466 12.676C10.218 12.7903 10.3523 12.8732 10.4494 12.9246C10.5466 12.976 10.6666 13.0389 10.8094 13.1132C11.3751 13.3875 11.7723 13.6217 12.0008 13.816C12.2351 14.0103 12.3523 14.2446 12.3523 14.5189C12.3523 14.776 12.2494 14.9532 12.0437 15.0503C11.8437 15.1475 11.5151 15.196 11.058 15.196C10.6008 15.196 10.1751 15.0817 9.78084 14.8532C9.38655 14.6189 9.07227 14.3446 8.83798 14.0303L8.62369 16.1132C8.80655 16.3132 9.06941 16.5103 9.41227 16.7046C9.76084 16.8932 10.1894 17.036 10.698 17.1332V18.6589H12.8494Z"
        fill="white"
      />
    </svg>
  );
};

export default Money;
