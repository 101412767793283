import React from 'react';
import { Player } from '@lottiefiles/react-lottie-player';

import loader from '../../assets/pcb-small-loader.json';

const SmallLoader: React.FC = () => {
  return (
    <Player autoplay src={loader} style={{ height: '30px', width: '30px' }} loop={true}></Player>
  );
};

export default SmallLoader;
