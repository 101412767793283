import React, { useContext, useEffect, useState } from 'react';

import { Cross, Download, Trash } from 'svgs';
import { AdminsContext, ProvidersContactContext } from 'contexts';
import useApi from 'services/axios';
import { delProviderContact, getSpecificProviderContacts } from 'services/endpoints';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer, HelperText } from 'utils/array';

import { Array, contactsProvider } from '../../array';
import { MainContainer } from '../../styledComponent';
import PictureTag from '../../PictrureTag';
import { capitalizeFirstLetter, getInitial } from '../../Tools';
import { ProviderContactsModal } from '../../modal';

type ProviderContactsProps = {
  providerDetail: Provider;
};

const ProviderContacts: React.FC<ProviderContactsProps> = ({ providerDetail }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedContacts, setSelectedContacts] = useState<number[]>([]);
  const [contact, setContact] = useState<ProvidersContactContextState>();
  const [contactDetails, setContactDetails] = useState<ProviderContact>({
    provider_company_id: providerDetail.id
  });
  const [modalCreateContactDisplayed, setModalCreateContactDisplayed] = useState<boolean>(false);
  const { state: providerContactState, dispatch: providerContactAction } = useContext(
    ProvidersContactContext.Context
  );
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { del, get } = useApi();

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };
  const unSelectedContacts = (id: number) => {
    setSelectedContacts((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteContacts = () => {
    selectedContacts.map((providerId) => {
      del(delProviderContact(providerDetail.id, providerId));
      unSelectedContacts(providerId);
    });

    const newProvidersState = contact.results.filter(
      (provider) => !selectedContacts.includes(provider.id)
    );

    providerContactAction({
      type: 'UPDATE_DATA',
      payload: {
        ...providerContactState,
        count: providerContactState.count - 1,
        results: newProvidersState
      }
    });

    setContact({
      ...providerContactState,
      count: providerContactState.count - 1,
      results: newProvidersState
    });
  };

  const getPhoneNumberFormatted = (phoneNumber: string) => phoneNumber.replace(/.{2}/g, '$& ');

  const providerContactPreProcessing = contact?.results.map((contact) => ({
    id: contact.id,
    job_name: {
      value: contact.job_name,
      display: <div>{capitalizeFirstLetter(contact.job_name)}</div>
    },
    last_name: {
      value: contact.last_name,
      display: <div>{capitalizeFirstLetter(contact.last_name)}</div>
    },
    first_name: {
      value: contact.first_name,
      display: <div>{capitalizeFirstLetter(contact.first_name)}</div>
    },
    email_address: {
      value: contact.email_address,
      display: <BlueBoldTextContainer>{contact.email_address}</BlueBoldTextContainer>
    },
    address_name: {
      value: contact?.address?.address_name,
      display: contact?.address?.address_name
    },
    phone_number: {
      value: contact.phone_number,
      display: <div>{getPhoneNumberFormatted(contact.phone_number)}</div>
    },
    sales_person: {
      value: getAdminInital(contact.sales_person),
      display: <PictureTag text={getAdminInital(contact.sales_person)} imgUrl="" />
    }
  }));

  const unSelectedProviders = (id: number) => {
    setSelectedContacts((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedProviders(id);
    } else {
      if (!selectedContacts.includes(id)) {
        setSelectedContacts((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const contactDetails = contact?.results.find((contact) => contact.id === Number(id));

    setContactDetails(contactDetails);
    setModalCreateContactDisplayed(true);
  };

  const openCreateProviderContactsModal = () => {
    setContactDetails({ provider_company_id: providerDetail.id });
    setModalCreateContactDisplayed(true);
  };

  const handleCloseProviderContactsModal = () => {
    setModalCreateContactDisplayed(false);
  };

  const updateProviderContact = (newPage: any) => {
    setContact(newPage);
  };

  useEffect(() => {
    (async () => {
      const contact = await get(getSpecificProviderContacts(providerDetail.id));
      setContact(contact.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Array
        template={contactsProvider}
        content={providerContactPreProcessing}
        lineSelected={handleLineSelected}
        lineClicked={handleLineClicked}
        pagination={contact}
        updatedArrayData={updateProviderContact}
        isSearchable={false}
        tools={
          <>
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Contact"
              leftIcon={Cross()}
              onClick={openCreateProviderContactsModal}
            />
            <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            {!!selectedContacts.length && (
              <Button
                variant={ButtonVariant.ERROR}
                text={`Delete (${selectedContacts.length}) rows`}
                leftIcon={Trash()}
                onClick={deleteContacts}
              />
            )}
          </>
        }
      />
      {!providerContactPreProcessing.length && (
        <HelperText>Add a contacts start a business with this provider</HelperText>
      )}
      <ProviderContactsModal
        isDisplayed={modalCreateContactDisplayed}
        contactDetail={contactDetails}
        providerDetail={providerDetail}
        onClose={handleCloseProviderContactsModal}
        setContacts={setContact}
        contacts={contact}
      />
    </MainContainer>
  );
};

export default ProviderContacts;
