import React, { useContext, useEffect, useState } from 'react';

import { Button, SmallLoader, Tag } from 'components/common';
import useApi from 'services/axios';
import { delRequestForQuotation, getRequestForQuotation } from 'services/endpoints';
import { purchasesRequestForQuotation } from 'components/array/template';
import PictureTag from 'components/PictrureTag';
import {
  Overflow,
  capitalizeFirstLetter,
  getInitial,
  useCompaniesState,
  useProvidersState
} from 'components/Tools';
import { TagColor } from 'components/common/Tag';
import { AdminsContext } from 'contexts';
import { RfqModal } from 'components/modal';
import { ButtonVariant } from 'components/common/Button';
import { Download, Trash } from 'svgs';
import { BlueBoldTextContainer } from 'utils/array';

import { Array } from '../array';
import { MainContainer, Title } from '../styledComponent';

enum RFQStatus {
  DRAFT = 'draft',
  SENT = 'sent',
  DONE = 'done'
}

const getRFQStatusColor = (status: string): TagColor => {
  switch (status) {
    case RFQStatus.DRAFT:
      return TagColor.BLUE;

    case RFQStatus.SENT:
      return TagColor.ORANGE;

    case RFQStatus.DONE:
      return TagColor.GREEN;

    default:
      return TagColor.DARKBLUE; // Default color, which can be adjusted as needed
  }
};

const RequestForQuotation: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [requestForQuotations, setRequestForQuotations] = useState<RfqContextState>();
  const [requestForQuotationsDetails, setRequestForQuotationsDetails] = useState<Rfq>();
  const [modalRfqDisplayed, setModalRfqDisplayed] = useState<boolean>(false);
  const [selectedRequestForQuotations, setSelectedRequestForQuotations] = useState<number[]>([]);
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { findCompanyById } = useCompaniesState();
  const { findProviderById } = useProvidersState();
  const { get, del } = useApi();

  const getProviderName = (providerId: number) => findProviderById(providerId)?.company_name;

  const getCompanyName = (companyId: number) => findCompanyById(companyId)?.company_name;

  const updateRfq = (newRfq: Rfq) => {
    const newRequestForQuotations = requestForQuotations.results.map((rfq) =>
      rfq.id === newRfq.id ? newRfq : rfq
    );
    setRequestForQuotations({ ...requestForQuotations, results: newRequestForQuotations });
  };

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const handleCloseRfqModal = () => {
    setModalRfqDisplayed(false);
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const newRfqModalDetails = requestForQuotations.results.find((item) => item.id === Number(id));
    setRequestForQuotationsDetails(newRfqModalDetails);
    setModalRfqDisplayed(true);
  };

  const requestForQuotationsPreProcessing = requestForQuotations?.results.map((rfq: Rfq) => ({
    id: rfq.id,
    provider: {
      value: getProviderName(rfq.provider_id),
      display: <div>{getProviderName(rfq.provider_id)}</div>
    },
    reference: {
      value: rfq?.reference,
      display: <BlueBoldTextContainer>{rfq?.reference}</BlueBoldTextContainer>
    },
    company: {
      value: getCompanyName(rfq.company_id),
      display: <div>{getCompanyName(rfq.company_id)}</div>
    },
    provider_purchases: { value: 'in coming', display: <div>in coming</div> },
    rfq_date: {
      value: rfq.rfq_date,
      display: <div>{new Date(rfq.rfq_date).toLocaleDateString('fr-FR')}</div>
    },
    sales_person: {
      value: getAdminInital(rfq?.sales_person),
      display: <PictureTag text={getAdminInital(rfq?.sales_person)} />
    },
    admin: {
      value: getAdminInital(rfq?.admin),
      display: <PictureTag text={getAdminInital(rfq?.admin)} />
    },
    status: {
      value: capitalizeFirstLetter(rfq.status),
      display: (
        <Tag text={capitalizeFirstLetter(rfq.status)} color={getRFQStatusColor(rfq.status)} />
      )
    }
  }));

  const unselectedRequestForQuotations = (id: number) => {
    setSelectedRequestForQuotations((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedRequestForQuotations(id);
    } else {
      if (!selectedRequestForQuotations.includes(id)) {
        setSelectedRequestForQuotations((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const updateRequestForQuotations = (newPage: any) => {
    setRequestForQuotations(newPage);
  };

  const deleteRfq = () => {
    selectedRequestForQuotations.map((rfqId) => {
      const deletedRfq = requestForQuotations.results.find((rfq) => rfq.id === rfqId);
      del(delRequestForQuotation(deletedRfq.company_id, deletedRfq.contact_id, rfqId));
      unselectedRequestForQuotations(rfqId);
    });
    const newRequestForQuotations = requestForQuotations.results.filter(
      (rfq) => !selectedRequestForQuotations.includes(rfq.id)
    );
    requestForQuotations.results = newRequestForQuotations;
    setRequestForQuotations(requestForQuotations);
  };

  useEffect(() => {
    (async () => {
      const requestForQuotations = await get(getRequestForQuotation);
      setRequestForQuotations(requestForQuotations.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Request for quotation</Title>
      <Overflow>
        <Array
          template={purchasesRequestForQuotation}
          content={requestForQuotationsPreProcessing}
          lineSelected={handleLineSelected}
          lineClicked={handleLineClicked}
          extended={true}
          pagination={requestForQuotations}
          updatedArrayData={updateRequestForQuotations}
          url={getRequestForQuotation}
          isSearchable={false}
          tools={
            <>
              {!!selectedRequestForQuotations.length && (
                <Button
                  variant={ButtonVariant.ERROR}
                  text={`Delete (${selectedRequestForQuotations.length}) rows`}
                  leftIcon={Trash()}
                  onClick={deleteRfq}
                />
              )}
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
      <RfqModal
        isDisplayed={modalRfqDisplayed}
        onClose={handleCloseRfqModal}
        requestForQuotationsDetails={requestForQuotationsDetails}
        callBack={updateRfq}
      />
    </MainContainer>
  );
};

export default RequestForQuotation;
