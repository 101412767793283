import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
/* Global Reset */
*, *:before, *:after {
  box-sizing: inherit;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
}
/* End Global Reset */

html {
  height: 100%;
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Inter';
  height: 100%;
  margin: 0;
  padding: 0;
}

#pcbe {
  height: 100%;
}

::placeholder {

}

@keyframes fade-in {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 1500px;
  }
}

.fade-in {
  animation: fade-in 300ms ease-in-out;
}
`;

export default GlobalStyles;
