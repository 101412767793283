import React from 'react';
import styled from 'styled-components';

const ModalCard = styled.div<{ open: boolean }>`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: 600px;
  border-radius: 15px;
  background-color: white;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
  transition: opacity 0.3s ease-in-out;
  opacity: ${(props) => (props.open ? 1 : 0)};
  z-index: 10001;
`;
const Background = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, ${(props) => (props.open ? 0.5 : 0)});
  transition: background-color 0.3s ease-in-out;
  z-index: 10000;
`;

const Block = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 29px;
  line-height: 1.5;
  margin-bottom: 4px;
`;

interface ModalCardProps {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  title?: JSX.Element;
}

const Modal: React.FC<ModalCardProps> = ({ isOpen, onClose, children, title }) => {
  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  return (
    isOpen && (
      <>
        <Background open={isOpen} onClick={handleClickOutside} />
        <ModalCard open={isOpen}>
          <Block>
            {title && <Title>{title}</Title>}
            {children}
          </Block>
        </ModalCard>
      </>
    )
  );
};

export default Modal;
