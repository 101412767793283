import React from 'react';
import styled from 'styled-components';

import { Loader } from './common';

const Title = styled.h1`
  font-size: 40px;
`;

const Name = styled.span`
  color: #1975ff;
`;

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

type LoadingPageProps = {
  isLoading: boolean;
  callback: React.Dispatch<React.SetStateAction<boolean>>;
};

const LoadingPage: React.FC<LoadingPageProps> = ({ callback, isLoading }) => {
  return (
    <Container>
      <Title>
        Welcome on <Name>PCB ELECTRONIC</Name> App
      </Title>
      <Loader callback={callback} isLoading={isLoading}></Loader>
      <div>Loading in progress...</div>
    </Container>
  );
};

export default LoadingPage;
