import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminsContext } from 'contexts';
import { Download, Trash } from 'svgs';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import { delOrder, getCustomerOrders } from 'services/endpoints';
import Tag, { TagColor } from 'components/common/Tag';

import { Array, salesOrders } from '../array';
import { MainContainer, Title } from '../styledComponent';
import PictureTag from '../PictrureTag';
import { Overflow, capitalizeFirstLetter, getInitial, useCompaniesState } from '../Tools';

enum OrderStatus {
  DRAFT = 'draft',
  PO_SENT = 'PO sent',
  PRODUCTION = 'production',
  SHIPPED = 'shipped',
  PARTIAL_DELIVERY = 'Partial delivery',
  DELIVERED = 'delivered',
  INVOICED = 'invoiced'
}

const getOrderStatusColor = (status: string): TagColor => {
  switch (status) {
    case OrderStatus.DRAFT:
      return TagColor.BLUE;

    case OrderStatus.PO_SENT:
    case OrderStatus.PRODUCTION:
      return TagColor.ORANGE;

    case OrderStatus.SHIPPED:
    case OrderStatus.PARTIAL_DELIVERY:
      return TagColor.GREEN;

    case OrderStatus.DELIVERED:
      return TagColor.DARKBLUE;

    case OrderStatus.INVOICED:
      return TagColor.RED;

    default:
      return TagColor.BLUE;
  }
};

const Orders: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState<OrderContextState>();
  const [selectedOrders, setSelectedOrders] = useState<number[]>([]);
  const { findCompanyById } = useCompaniesState();
  const { state: adminsState } = useContext(AdminsContext.Context);
  const navigate = useNavigate();
  const { get, del } = useApi();

  const goToOrder = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    navigate(`/Sales-Orders/Order?OrderId=${id}`);
  };

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };
  const getCompanyName = (companyId: number) => findCompanyById(companyId)?.company_name;

  const quotationsPreProcessing = orders?.results.map((order) => ({
    id: order.id,
    company_name: {
      value: getCompanyName(order.company),
      display: <div>{getCompanyName(order.company)}</div>
    },
    reference: {
      value: 'Order #' + order.reference,
      display: <BlueBoldTextContainer>{'Order #' + order.reference}</BlueBoldTextContainer>
    },
    reference_id_from_customer: {
      value: 'Order #' + order.reference_id_from_customer,
      display: (
        <BlueBoldTextContainer>
          {'Order #' + order.reference_id_from_customer}
        </BlueBoldTextContainer>
      )
    },
    order_date: {
      value: order.order_date,
      display: <div>{new Date(order.order_date).toLocaleDateString('fr-FR')}</div>
    },
    sales_person: {
      value: getAdminInital(order.sales_person),
      display: <PictureTag text={getAdminInital(order.sales_person)} />
    },
    admin: {
      value: getAdminInital(order.admin),
      display: <PictureTag text={getAdminInital(order.admin)} />
    },
    status: {
      value: capitalizeFirstLetter(order.status),
      display: (
        <Tag text={capitalizeFirstLetter(order.status)} color={getOrderStatusColor(order.status)} />
      )
    },
    price: {
      value: order.total_price_without_tax.toFixed(2),
      display: <div>{order.total_price_without_tax.toFixed(2)}</div>
    },
    margin: {
      value: order.margin_without_tax.toFixed(2),
      display: <div>{order.margin_without_tax.toFixed(2)}</div>
    },
    extended: (
      <div>
        <b>general_comment:</b>{' '}
        {order.general_comment ? order.general_comment : 'no comment defined for this order'}
      </div>
    )
  }));

  const unselectedOrders = (id: number) => {
    setSelectedOrders((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteOrders = () => {
    selectedOrders.map((OrderId) => {
      del(delOrder(OrderId));
      unselectedOrders(OrderId);
    });
    const newOrderState = orders.results.filter((order) => !selectedOrders.includes(order.id));
    orders.results = newOrderState;
    setOrders(orders);
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedOrders(id);
    } else {
      if (!selectedOrders.includes(id)) {
        setSelectedOrders((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const updateCustomerQuotation = (newPage: any) => {
    setOrders(newPage);
  };

  const openNewWindowButtonClick = (id: string) => {
    window.open(`/Sales-Orders/Order?OrderId=${id}`, '_blank');
  };

  useEffect(() => {
    (async () => {
      const customerOrders = await get(getCustomerOrders);
      setOrders(customerOrders.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Orders</Title>
      <Overflow>
        <Array
          template={salesOrders}
          content={quotationsPreProcessing}
          lineClicked={goToOrder}
          lineSelected={handleLineSelected}
          extended={true}
          pagination={orders}
          updatedArrayData={updateCustomerQuotation}
          url={getCustomerOrders}
          newWindowUrl={openNewWindowButtonClick}
          tools={
            <>
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
              {!!selectedOrders.length && (
                <Button
                  variant={ButtonVariant.ERROR}
                  text={`Delete (${selectedOrders.length}) rows`}
                  leftIcon={Trash()}
                  onClick={deleteOrders}
                />
              )}
            </>
          }
        />
      </Overflow>
    </MainContainer>
  );
};

export default Orders;
