import { DefaultTheme } from 'styled-components';
import { StylesConfig } from 'react-select';

export const getSelectBorderStyle = (
  theme: DefaultTheme,
  isFocused: boolean,
  menuIsOpen: boolean,
  hasError: boolean,
  isHovered?: boolean
): string => {
  if (isFocused || menuIsOpen) {
    return `
        inset 0 0 0 2px #d9dee2;
      `;
  }

  if (hasError) {
    return `
        inset 0 0 0 2px red;
      `;
  }

  if (isHovered) {
    return `
        inset 0 0 0 1px #d9dee2;
      `;
  }

  return `
      inset 0 0 0 1px #d9dee2;
    `;
};

export const getSelectStyles = (
  theme?: DefaultTheme,
  width?: string,
  minHeight?: string,
  isError?: boolean,
  hasInitialMenu = true
): StylesConfig<Option> => ({
  control: (provided, state) => ({
    ...provided,
    padding: '4px',
    minHeight: minHeight || 'unset',
    width: width || '100px',
    border: 'none',
    borderRadius: 10,
    borderBottomLeftRadius:
      (hasInitialMenu || state.selectProps.inputValue.length > 0) && state.menuIsOpen ? 0 : 10,
    borderBottomRightRadius:
      (hasInitialMenu || state.selectProps.inputValue.length > 0) && state.menuIsOpen ? 0 : 10,
    boxShadow: getSelectBorderStyle(theme, state.isFocused, state.menuIsOpen, isError),
    '&:hover': {
      boxShadow: getSelectBorderStyle(theme, state.isFocused, state.menuIsOpen, isError, true)
    }
  })
});
