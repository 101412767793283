import React from 'react';

import { CompanyState } from 'utils/company';

import CompanyContacts from './CompanyContacts';
import CompanySettings from './CompanySettings';
import CompanyActivities from './CompanyActivities';

type CompanyContentProps = {
  companyState: CompanyState;
  companyDetail: Company;
  setCompanyDetails: React.Dispatch<React.SetStateAction<Company>>;
};

const CompanyContent: React.FC<CompanyContentProps> = ({
  companyState,
  companyDetail,
  setCompanyDetails
}) => {
  if (companyState === CompanyState.ACTIVITIES) {
    return <CompanyActivities companyDetail={companyDetail} />;
  }
  if (companyState === CompanyState.CONTACTS) {
    return <CompanyContacts companyDetail={companyDetail} />;
  }

  return <CompanySettings companyDetail={companyDetail} setCompanyDetails={setCompanyDetails} />;
};

export default CompanyContent;
