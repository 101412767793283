import React from 'react';

const DotDotDot = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6 9C6 9.62132 5.49632 10.125 4.875 10.125C4.25368 10.125 3.75 9.62132 3.75 9C3.75 8.37868 4.25368 7.875 4.875 7.875C5.49632 7.875 6 8.37868 6 9Z"
        fill="#232C34"
      />
      <path
        d="M10.125 9C10.125 9.62132 9.62137 10.125 9.00005 10.125C8.37873 10.125 7.87505 9.62132 7.87505 9C7.87505 8.37868 8.37873 7.875 9.00005 7.875C9.62137 7.875 10.125 8.37868 10.125 9Z"
        fill="#232C34"
      />
      <path
        d="M14.2501 9C14.2501 9.62132 13.7464 10.125 13.1251 10.125C12.5037 10.125 12.0001 9.62132 12.0001 9C12.0001 8.37868 12.5037 7.875 13.1251 7.875C13.7464 7.875 14.2501 8.37868 14.2501 9Z"
        fill="#232C34"
      />
    </svg>
  );
};

export default DotDotDot;
