import styled from 'styled-components';

export const BlueBoldTextContainer = styled.div`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary.main};
`;

export const StyledButtonContainer = styled.div`
  display: inline-flex;
  gap: ${(props) => props.theme.spacing.xs}px;
`;

export const HelperText = styled.div`
  padding: 16px 8px;
`;

export const elementsDisplayedOption: NumberOption[] = Array.from({ length: 22 }, (_, k) =>
  k < 10 ? k * 10 : k < 15 ? 100 + (k - 10) * 20 : 200 + (k - 15) * 50
).map((value) => ({ value, label: value.toString() }));
