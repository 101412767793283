import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Array, relatedDocuments } from 'components/array';
import useApi from 'services/axios';
import { getRelatedDocuments } from 'services/endpoints';
import PictureTag from 'components/PictrureTag';
import { AdminsContext } from 'contexts';
import { getInitial } from 'components/Tools';
import { BlueBoldTextContainer } from 'utils/array';

import { ModalSidebar, SmallLoader } from '../common';
import { MainContainer } from '../styledComponent';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const transformDocuments = (documents: RelatedDocument[]): RelatedDocumentItem[] => {
  const result: RelatedDocumentItem[] = [];

  documents.forEach((document) => {
    (Object.keys(document) as (keyof RelatedDocument)[]).forEach((type) => {
      document[type].forEach((detail) => {
        result.push({ ...detail, type });
      });
    });
  });

  return result;
};

type RelatedDocumentModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  title: string;
  queryParam: string;
};

const RelatedDocumentModal: React.FC<RelatedDocumentModalProps> = ({
  title,
  isDisplayed,
  onClose,
  queryParam
}) => {
  const { get } = useApi();
  const [documents, setDocuments] = useState<RelatedDocuments>();
  const [documentsTransformed, setDocumentsTransformed] = useState<RelatedDocumentItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { state: adminsState } = useContext(AdminsContext.Context);

  const getAdminInitial = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const relatedDocumentPreProcessing = documentsTransformed.map((document) => ({
    id: `${document?.id}-${document.type}`,
    type: {
      value: document?.type,
      display: <div>{document?.type}</div>
    },
    reference: {
      value: document?.reference,
      display: <BlueBoldTextContainer>{document?.reference}</BlueBoldTextContainer>
    },
    date: {
      value: document?.date,
      display: <div>{new Date(document?.date).toLocaleDateString('fr-FR')}</div>
    },
    admin: {
      value: getAdminInitial(document?.admin),
      display: <PictureTag text={getAdminInitial(document?.admin)} />
    }
  }));

  useEffect(() => {
    if (documents) {
      setDocumentsTransformed(transformDocuments(documents));
    }
  }, [documents]);

  useEffect(() => {
    if (isDisplayed) {
      (async () => {
        const getDocs = await get(`${getRelatedDocuments}?${queryParam}`);
        setDocuments([getDocs.data]);
        setIsLoading(false);
      })();
    }
  }, [isDisplayed]);

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={title}>
          {isLoading ? (
            <SmallLoader />
          ) : (
            <Content>
              <Row>
                {relatedDocumentPreProcessing ? (
                  <Array
                    template={relatedDocuments}
                    content={relatedDocumentPreProcessing}
                    checkboxDisplayed={false}
                    isSearchable={false}
                  />
                ) : (
                  <>0 related document found</>
                )}
              </Row>
            </Content>
          )}
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default RelatedDocumentModal;
