import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { AdminsContext } from 'contexts';
import { patchContact, postCreateContact } from 'services/endpoints';
import useApi from 'services/axios';
import { getSelectStyles } from 'utils/style';
import { Cross } from 'svgs';

import { Button, CustomInput, CustomSelect, ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const SelectedTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #434d56;
`;

const SelectWrapper = styled.div`
  max-width: 250px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type ContactModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  contactDetail: Contact;
  companyDetail: Company;
  contacts: CompaniesContactContextState;
  setContacts: React.Dispatch<React.SetStateAction<CompaniesContactContextState>>;
};

const ContactModal: React.FC<ContactModalProps> = ({
  contactDetail,
  companyDetail,
  isDisplayed,
  onClose,
  contacts,
  setContacts
}) => {
  const [contact, setContact] = useState<Contact>({});
  const [address, setAddress] = useState<Option>();
  const [salesMan, setSalesMan] = useState<Option>();
  const { state: adminsState } = useContext(AdminsContext.Context);
  const navigate = useNavigate();
  const { post, patch } = useApi();

  const addressOptionFormatted = companyDetail.addresses.map((address) => ({
    label: address.address_name,
    value: address.id
  }));

  const adminFormatted = adminsState.map((admin) => ({
    label: `${admin.last_name} ${admin.first_name}`,
    value: admin.id
  }));

  const createCompanyContact = async () => {
    const newContact = await post(postCreateContact(contactDetail.company_id), contact);
    const newResult = [...contacts.results];

    newResult.unshift(newContact.data);

    setContacts({
      ...contacts,
      results: newResult
    });

    onClose();
  };

  const updateCompanyContact = async () => {
    const newContact = await patch(patchContact(contactDetail.company_id, contactDetail.id), {
      ...contact
    });

    setContacts({
      ...contacts,
      results: contacts.results.map((contact) =>
        contact.id === newContact.data?.id ? newContact.data : contact
      )
    });

    onClose();
  };

  const handleChangeSelect = (
    name: string,
    option: Option,
    updateState: (option: Option) => void
  ) => {
    setContact({
      ...contact,
      [name]: option.value
    });
    updateState(option);
  };

  const handleChange = (name: string) => (value: string | number) => {
    setContact({
      ...contact,
      [name]: value
    });
  };

  useEffect(() => {
    setContact(contactDetail);
    if (contactDetail.address) {
      setAddress(
        addressOptionFormatted.find((address) => address.value === contactDetail.address.id)
      );
    }
    if (contactDetail.sales_person) {
      setSalesMan(adminFormatted.find((admin) => admin.value === contactDetail.sales_person));
    }
  }, [contactDetail]);

  const handleSaveButton = contactDetail.id ? updateCompanyContact : createCompanyContact;

  const handleClickNewAddressButton = () => {
    navigate(
      `/Clients-Companies/Company?companyId=${contactDetail.company_id}&companyView=settings`
    );
  };

  const modalTitle = contactDetail.id ? 'Update company contact' : 'New company contact';

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={modalTitle}>
          <Content>
            <Row>
              <CustomInput
                title="Name"
                defaultValue={contactDetail.last_name}
                placeholder="Name"
                callBack={() => handleChange('last_name')}
              />
              <CustomInput
                title="Firstname"
                defaultValue={contactDetail.first_name}
                placeholder="Firstname"
                callBack={() => handleChange('first_name')}
              />
            </Row>
            <CustomInput
              title="Job"
              defaultValue={contactDetail.job_name}
              placeholder="Job"
              callBack={() => handleChange('job_name')}
            />
            <CustomInput
              title="Phone number"
              defaultValue={contactDetail.phone_number}
              placeholder="Phone number"
              callBack={() => handleChange('phone_number')}
            />
            <CustomInput
              title="Email"
              defaultValue={contactDetail.email_address}
              placeholder="Email"
              callBack={() => handleChange('email_address')}
            />
            <Row>
              <SelectWrapper>
                <SelectedTitle>Associated address (by name)</SelectedTitle>
                <CustomSelect
                  placeholder="Address (by Name)"
                  value={address}
                  options={addressOptionFormatted}
                  onChange={(e) => {
                    handleChangeSelect('address_id', e, setAddress);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </SelectWrapper>
              <Button
                text="New address"
                onClick={handleClickNewAddressButton}
                leftIcon={Cross()}
                variant={0}
                sizing={3}
              />
            </Row>
            <SelectWrapper>
              <SelectedTitle>Associated salesman</SelectedTitle>
              <CustomSelect
                placeholder="Salesman"
                value={salesMan}
                options={adminFormatted}
                onChange={(e) => {
                  handleChangeSelect('sales_person', e, setSalesMan);
                }}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '250px', '50px')}
              />
            </SelectWrapper>
            <ButtonWrapper>
              <Button text="Save" onClick={handleSaveButton} variant={1} sizing={1} />
            </ButtonWrapper>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default ContactModal;
