import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useApi from 'services/axios';
import { patchProviderDeliveryNote, postProviderDeliveryNotePDF } from 'services/endpoints';
import { UpdateSalesDeliveryNoteRecap } from 'components/array/template';
import { Array } from 'components/array';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import { Download, Eye } from 'svgs';
import { getSelectStyles } from 'utils/style';
import { AllCompaniesContactContext, AllCompaniesContext } from 'contexts';
import { useProvidersState } from 'components/Tools';
import { addressSelectedTemplate, contactSelectedTemplate } from 'utils/sales';

import { Button, CustomInput, CustomSelect, CustomTextArea, ModalSidebar } from '../common';
import { MainContainer, SubTitle } from '../styledComponent';

import RelatedDocumentModal from './RelatedDocumentModal';

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Left = styled(Row)`
  justify-content: flex-end;
`;

const CustomContainer = styled.div`
  // gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const CustomTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: rgb(67, 77, 86);
`;

const CustomText = styled.div`
  display: flex;
  height: 45.5px;
  width: 200px;
  padding-left: 16px;
  align-items: center;

  color: #1975ff;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
`;

type UpdatedProviderDeliveryNoteModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  deliveryModalDetails: ProviderDeliveryNote;
  callback?: (newNote: ProviderDeliveryNote) => void;
};

const UpdatedProviderDeliveryNoteModal: React.FC<UpdatedProviderDeliveryNoteModalProps> = ({
  isDisplayed,
  onClose,
  deliveryModalDetails,
  callback
}) => {
  const [modalRelatedDocumentDisplayed, setModalRelatedDocument] = useState<boolean>(false);
  const [deliveryNote, setDeliveryNote] = useState<ProviderDeliveryNote>();
  const { state: allCompaniesContext } = useContext(AllCompaniesContext.Context);
  const { state: allCompaniesContactState } = useContext(AllCompaniesContactContext.Context);
  const { patch, post } = useApi();
  const { findProviderById } = useProvidersState();

  const getProviderName = (ItemProviderId: number) =>
    findProviderById(ItemProviderId)?.company_name;

  const handleChange = (id: number) => (value: number) => {
    const new_provider_order_items: ProviderPurchaseItem[] =
      deliveryNote.provider_purchase_items.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            quantity:
              Number(value) > item.total_delivered_quantity
                ? item.total_delivered_quantity
                : Number(value)
          };
        } else {
          return item;
        }
      });

    setDeliveryNote({
      ...deliveryNote,
      provider_purchase_items: new_provider_order_items
    });
  };

  const handleCloseRelatedDocumentModal = () => {
    setModalRelatedDocument(false);
  };

  const handleDocumentButtonClick = () => {
    setModalRelatedDocument(true);
  };

  const handleSaveButtonClick = async () => {
    const newDeliveryNote = await patch(patchProviderDeliveryNote(deliveryNote.id), deliveryNote);
    setDeliveryNote(newDeliveryNote.data);
    callback(newDeliveryNote.data);
    onClose();
  };

  const handlePDFButtonClick = async () => {
    const PurchaseItemPDF = await post(postProviderDeliveryNotePDF(deliveryNote.id), {}, 'blob');
    const blob = new Blob([PurchaseItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `deliveryNote-${deliveryNote.reference}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const newAddressOption = allCompaniesContext.find(
    (company) => company.id === deliveryModalDetails?.provider
  );

  const shippingAddressOptionFormatted = useMemo(
    () =>
      newAddressOption?.addresses
        ? newAddressOption.addresses
            .filter((address) => address.address_type === 'shipping')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [newAddressOption]
  );

  const companiesContactFormatted = useMemo(
    () =>
      allCompaniesContactState
        .filter((contact) => contact.id === deliveryModalDetails?.contact_id)
        .map((contact) => ({
          label: contactSelectedTemplate(contact),
          value: contact.id
        })),
    [allCompaniesContactState, deliveryModalDetails]
  );

  const handleInputChange = (name: string) => (value: string | number) => {
    setDeliveryNote({ ...deliveryModalDetails, [name]: value });
  };

  const salesDeliveryNotePreprocessing = deliveryModalDetails?.provider_purchase_items?.map(
    (item) => {
      return {
        id: item.id,
        reference: {
          value: item.provider_purchase_item_reference,
          display: <div>{item.provider_purchase_item_reference}</div>
        },
        total_delivered_quantity: {
          value: item.provider_purchase_item_quantity,
          display: <div>{item.provider_purchase_item_quantity}</div>
        },
        total_remaining_quantity: {
          value: item.provider_purchase_item_remaining_quantity,
          display: <div>{item.provider_purchase_item_remaining_quantity}</div>
        },
        order_quantity: {
          value: item.quantity.toString(),
          display: (
            <CustomInput
              defaultValue={item.quantity.toString()}
              placeholder="reference"
              callBack={() => handleChange(item.id)}
              width="70px"
            />
          )
        }
      };
    }
  );

  useEffect(() => {
    if (deliveryModalDetails) {
      setDeliveryNote(deliveryModalDetails);
    }
  }, [deliveryModalDetails]);

  return (
    <MainContainer>
      {isDisplayed && deliveryModalDetails && (
        <>
          <ModalSidebar
            isOpen={isDisplayed}
            onClose={onClose}
            title="Provider delivery note settings"
          >
            <Content>
              <Row>
                <CustomContainer>
                  <CustomInput
                    title="Tracking Number"
                    defaultValue={deliveryModalDetails.tracking_number}
                    placeholder="#0000000"
                    callBack={() => handleInputChange('tracking_number')}
                  />
                </CustomContainer>
                <CustomContainer>
                  <CustomTitle>Related order</CustomTitle>
                  <CustomText>{deliveryModalDetails.purchase_order_reference}</CustomText>
                </CustomContainer>
                <CustomContainer>
                  <CustomTitle>Provider</CustomTitle>
                  <CustomText>{getProviderName(deliveryModalDetails.provider)}</CustomText>
                </CustomContainer>
              </Row>

              <div>Select the products and quantities concerned by the delivery note</div>
              <Array
                template={UpdateSalesDeliveryNoteRecap}
                content={salesDeliveryNotePreprocessing}
                checkboxDisplayed={false}
                isSearchable={false}
              />
              <Left>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  text={`Save`}
                  onClick={handleSaveButtonClick}
                />
              </Left>

              <SubTitle>Print & send delivery note</SubTitle>

              <Row>
                <CustomContainer>
                  <TitleSelect>Select shipping address</TitleSelect>
                  <CustomSelect
                    placeholder="Shipping address (by Name)"
                    value={shippingAddressOptionFormatted}
                    isDisabled={true}
                    styles={getSelectStyles(null, '250px', '85px')}
                  />
                </CustomContainer>
                <CustomContainer>
                  <TitleSelect>Contact</TitleSelect>
                  <CustomSelect
                    placeholder="Contact"
                    value={companiesContactFormatted}
                    isDisabled={true}
                    styles={getSelectStyles(null, '250px', '85px')}
                  />
                </CustomContainer>
              </Row>

              <Row>
                <Button
                  sizing={ButtonSizing.SMALL}
                  text={`Download delivery note`}
                  leftIcon={Download()}
                  onClick={handlePDFButtonClick}
                />
                <Button
                  text="View documents"
                  leftIcon={Eye()}
                  sizing={ButtonSizing.SMALL}
                  onClick={handleDocumentButtonClick}
                />
              </Row>
              <CustomTextArea
                title="General comment"
                value={deliveryModalDetails.general_comment}
                isDisabled={true}
                placeholder="No comment"
                isBold={true}
              />
            </Content>
          </ModalSidebar>
          <RelatedDocumentModal
            title="Provider DeliveryNote related document"
            queryParam={`provider_delivery_note_id=${deliveryModalDetails?.id}`}
            isDisplayed={modalRelatedDocumentDisplayed}
            onClose={handleCloseRelatedDocumentModal}
          />
        </>
      )}
    </MainContainer>
  );
};

export default UpdatedProviderDeliveryNoteModal;
