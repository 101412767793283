import React, { useEffect, useState } from 'react';
// eslint-disable-next-line import/no-internal-modules
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';

import DatePickerHeader from './DatePickerHeader';

interface CustomDatePickerProps {
  value: string;
  isDisabled: boolean;
  updatedDate?: (newValue: any) => Promise<void>;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({ value, isDisabled, updatedDate }) => {
  const [startDate, setStartDate] = useState<Date>(new Date());

  useEffect(() => {
    if (value) {
      setStartDate(new Date(value));
    }
  }, [value]);

  const handleChange = (newDate: Date) => {
    setStartDate(newDate);
    if (updatedDate) {
      updatedDate(newDate);
    }
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleChange}
      customInput={<DatePickerHeader isDisabled={isDisabled} />}
      dateFormat="dd/MM/yyyy"
      showIcon={true}
      disabled={isDisabled}
    />
  );
};

export default CustomDatePicker;
