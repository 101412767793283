import { createContext } from 'react';

const initialState: AdminsContextState = [];

const reducer = (state: AdminsContextState, action: AdminsDispatchAction): AdminsContextState => {
  switch (action.type) {
    case 'SET_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type AdminsContextType = {
  state: AdminsContextState;
  dispatch: React.Dispatch<AdminsDispatchAction>;
};

const Context = createContext<AdminsContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'Admins';

export { Context, initialState, reducer };
