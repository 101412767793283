import React, { useContext } from 'react';
import styled from 'styled-components';

import { Array, quotationVersion } from 'components/array';
import { getInitial } from 'components/Tools';
import PictureTag from 'components/PictrureTag';
import { AdminsContext } from 'contexts';
import { BlueBoldTextContainer } from 'utils/array';

import { ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

type QuotationVersionsModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  quotations: Partial<Quotation>;
  callback: (value: React.SetStateAction<number>) => void;
};

const QuotationVersionsModal: React.FC<QuotationVersionsModalProps> = ({
  quotations,
  isDisplayed,
  onClose,
  callback
}) => {
  const { state: adminsState } = useContext(AdminsContext.Context);
  const getAdminInitial = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const quotationVersionPreprocessing = quotations?.quotation_versions
    ?.sort((a: QuotationVersion, b: QuotationVersion) => b.id - a.id)
    .map((quotation) => {
      return {
        id: quotation.id,
        date: {
          value: quotation.created_at,
          display: <div>{new Date(quotation.created_at).toLocaleDateString('fr-FR')}</div>
        },
        admin: {
          value: getAdminInitial(quotation?.creator_id),
          display: <PictureTag text={getAdminInitial(quotation?.creator_id)} imgUrl="" />
        },
        reference: {
          value: `${quotations.reference}-v${quotation.version_number}`,
          display: (
            <BlueBoldTextContainer>{`${quotations.reference}-v${quotation.version_number}`}</BlueBoldTextContainer>
          )
        }
      };
    });

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    callback(Number(id));
    onClose();
  };

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title="Quotation history">
          <Content>
            <Row>
              {quotationVersionPreprocessing ? (
                <Array
                  template={quotationVersion}
                  content={quotationVersionPreprocessing}
                  checkboxDisplayed={false}
                  lineClicked={handleLineClicked}
                  isSearchable={false}
                />
              ) : (
                <>0 quotation finded - Create a new one to find quotation history here</>
              )}
            </Row>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default QuotationVersionsModal;
