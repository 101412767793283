import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import useApi from 'services/axios';
import {
  delProviderDeliveryNote,
  getProviderDeliveryNote,
  postProviderInvoices
} from 'services/endpoints';
import { Cross, Download, Send, Trash } from 'svgs';
import { BlueBoldTextContainer } from 'utils/array';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { MainContainer, Title } from 'components/styledComponent';
import { Array, providerDeliveryNotes } from 'components/array';
import { Overflow, capitalizeFirstLetter, getInitial, useProvidersState } from 'components/Tools';
import Tag, { TagColor } from 'components/common/Tag';
import PictureTag from 'components/PictrureTag';
import { AdminsContext } from 'contexts';
import { UpdatedProviderDeliveryNoteModal } from 'components/modal';

enum ProviderDeliveryNoteStatus {
  DRAFT = 'draft',
  SENT = 'sent'
}

const getProviderDeliveryNoteStatusColor = (status: string): TagColor => {
  switch (status) {
    case ProviderDeliveryNoteStatus.DRAFT:
      return TagColor.BLUE;

    case ProviderDeliveryNoteStatus.SENT:
      return TagColor.GREEN;

    default:
      return TagColor.BLUE;
  }
};

const ProviderDeliveryNote: React.FC = () => {
  const [delivery, setDelivery] = useState<ProviderDeliveryNotes>();
  const [deliveryModalDetails, setDeliveryModalDetails] = useState<ProviderDeliveryNote>();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState<number[]>([]);
  const [invoicesButtonDisplayed, setInvoicesButtonDisplayed] = useState<boolean>(true);
  const [modalSalesDeliveryNoteDisplayed, setModalSalesDeliveryNoteDisplayed] =
    useState<boolean>(false);
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { findProviderById } = useProvidersState();
  const navigate = useNavigate();
  const { get, del, post } = useApi();

  const handleSalesDeliveryNoteModal = () => {
    setModalSalesDeliveryNoteDisplayed(false);
  };

  const unselectedDeliveryNote = (id: number) => {
    setSelectedDeliveryNote((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedDeliveryNote(id);
    } else {
      if (!selectedDeliveryNote.includes(id)) {
        setSelectedDeliveryNote((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const newDeliveryModalDetails = delivery.results.find((item) => item.id === Number(id));
    setDeliveryModalDetails(newDeliveryModalDetails);
    setModalSalesDeliveryNoteDisplayed(true);
  };

  const deleteDeliveryNote = () => {
    selectedDeliveryNote.map((DeliveryId) => {
      del(delProviderDeliveryNote(DeliveryId));
      unselectedDeliveryNote(DeliveryId);
    });
    const newOrderState = delivery.results.filter(
      (delivery: ProviderDeliveryNote) => !selectedDeliveryNote.includes(delivery.id)
    );
    delivery.results = newOrderState;
    setDelivery(delivery);
  };

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const getProviderName = (providerId: number) => findProviderById(providerId)?.company_name;

  const updateDelivery = (newNote: ProviderDeliveryNote) => {
    const newDeliveryNoteResult = delivery.results.map((note) =>
      note.id === newNote.id ? newNote : note
    );
    setDelivery({ ...delivery, results: newDeliveryNoteResult });
  };

  const deliveryNotePreProcessing = delivery?.results.map((note: ProviderDeliveryNote) => ({
    id: note.id,
    reference: {
      value: note?.reference,
      display: <BlueBoldTextContainer>{note?.reference}</BlueBoldTextContainer>
    },
    provider_name: {
      value: getProviderName(note.provider),
      display: <div>{getProviderName(note.provider)}</div>
    },
    order_reference: {
      value: note?.purchase_order_reference,
      display: <BlueBoldTextContainer>{note?.purchase_order_reference}</BlueBoldTextContainer>
    },
    tracking: { value: note?.tracking_number, display: <div>{'#' + note?.tracking_number}</div> },
    note_date: {
      value: note.delivery_note_date,
      display: <div>{new Date(note.delivery_note_date).toLocaleDateString('fr-FR')}</div>
    },
    sales_person: {
      value: getAdminInital(note?.sales_person_id),
      display: <PictureTag text={getAdminInital(note?.sales_person_id)} />
    },
    admin: {
      value: getAdminInital(note?.admin_id),
      display: <PictureTag text={getAdminInital(note?.admin_id)} />
    },
    status: {
      value: note.status,
      display: (
        <Tag
          text={capitalizeFirstLetter(note.status)}
          color={getProviderDeliveryNoteStatusColor(note.status)}
        />
      )
    },
    price: {
      value: note.total_buying_price.toFixed(2),
      display: <div>{note.total_buying_price.toFixed(2)}</div>
    },
    extended: (
      <div>
        <b>general_comment:</b>{' '}
        {note.general_comment ? note.general_comment : 'no comment defined for this delivery note'}
      </div>
    )
  }));

  const handleCreateInvoicesButtonClick = async () => {
    const InvoicesDetail = delivery.results.find((delivery) =>
      selectedDeliveryNote.includes(delivery.id)
    );
    await post(postProviderInvoices(), {
      provider: InvoicesDetail.provider,
      delivery_note_ids: selectedDeliveryNote
    });

    navigate('/Purchases-Invoices');
  };

  const updateProviderDeliveryNote = (newPage: any) => {
    setDelivery(newPage);
  };

  const checkSameCompany = (orders: ProviderDeliveryNote[]): boolean => {
    if (orders.length === 0) return true;

    const company = orders[0].provider;

    return orders.every((order) => order.provider === company);
  };

  useEffect(() => {
    if (selectedDeliveryNote && delivery?.results) {
      const deliveryFiltered = delivery.results.filter((delivery) =>
        selectedDeliveryNote.includes(delivery.id)
      );
      setInvoicesButtonDisplayed(checkSameCompany(deliveryFiltered));
    }
  }, [selectedDeliveryNote]);

  useEffect(() => {
    (async () => {
      const getDeliveryNote = await get(getProviderDeliveryNote());
      setDelivery(getDeliveryNote.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Provider Delivery note</Title>
      <Overflow>
        <Array
          template={providerDeliveryNotes}
          content={deliveryNotePreProcessing}
          lineSelected={handleLineSelected}
          lineClicked={handleLineClicked}
          extended={true}
          pagination={delivery}
          updatedArrayData={updateProviderDeliveryNote}
          url={getProviderDeliveryNote()}
          tools={
            <>
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
              {!!selectedDeliveryNote.length && (
                <>
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedDeliveryNote.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteDeliveryNote}
                  />

                  <Button
                    text="Create Invoices"
                    leftIcon={Cross()}
                    variant={ButtonVariant.PRIMARY}
                    onClick={handleCreateInvoicesButtonClick}
                    disabled={!invoicesButtonDisplayed}
                  />

                  <Button variant={ButtonVariant.PRIMARY} text="Send Note" leftIcon={Send()} />
                </>
              )}
            </>
          }
        />
      </Overflow>
      <UpdatedProviderDeliveryNoteModal
        isDisplayed={modalSalesDeliveryNoteDisplayed}
        onClose={handleSalesDeliveryNoteModal}
        deliveryModalDetails={deliveryModalDetails}
        callback={updateDelivery}
      />
    </MainContainer>
  );
};

export default ProviderDeliveryNote;
