import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AdminsContext } from 'contexts';
import { Download, Money, Send, Trash, WhiteCross } from 'svgs';
import { Button, SmallLoader, Tag } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import { delInvoice, getCustomerInvoices, postCustomerInvoicePDF } from 'services/endpoints';
import PictureTag from 'components/PictrureTag';
import { capitalizeFirstLetter, getInitial, useCompaniesState } from 'components/Tools';
import PaymentModal from 'components/modal/PaymentModal';
import { TagColor } from 'components/common/Tag';
import { InvoiceCreditNoteModal } from 'components/modal';

import { Array, salesInvoices } from '../array';
import { MainContainer, Title } from '../styledComponent';

const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
`;

enum InvoiceStatus {
  PAID = 'paid',
  NOT_PAID = 'not paid'
}

enum InvoiceTypeStatus {
  ADVANCE = 'advance',
  CLASSIC = 'classic'
}

const getInvoiceStatusColor = (status: string): TagColor => {
  switch (status) {
    case InvoiceStatus.PAID:
      return TagColor.GREEN;

    case InvoiceStatus.NOT_PAID:
      return TagColor.RED;

    default:
      return TagColor.BLUE;
  }
};

const getInvoiceTypeStatusStatusColor = (status: string): TagColor => {
  switch (status) {
    case InvoiceTypeStatus.ADVANCE:
      return TagColor.DARKBLUE;

    case InvoiceTypeStatus.CLASSIC:
      return TagColor.ORANGE;

    default:
      return TagColor.BLUE;
  }
};

const Invoices: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalPaymentDisplayed, setModalPaymentDisplayed] = useState<boolean>(false);
  const [modalCreditNoteDisplayed, setModalCreditNoteDisplayed] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<InvoiceContextState>();
  const [selectedInvoices, setSelectedInvoices] = useState<number[]>([]);
  const { findCompanyById } = useCompaniesState();
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { del, get, post } = useApi();
  const getCompanyName = (companyId: number) => findCompanyById(companyId)?.company_name;

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const handleClickRegisterPaymentButton = () => {
    setModalPaymentDisplayed(true);
  };

  const handleClickNewCreditNoteButton = () => {
    setModalCreditNoteDisplayed(true);
  };

  const handleCloseSalesPaymentModal = () => {
    setModalPaymentDisplayed(false);
  };

  const handleCloseCreditNoteModal = () => {
    setModalCreditNoteDisplayed(false);
  };

  const invoicesPreProcessing = invoices?.results.map((invoices) => ({
    id: invoices.id,
    invoice_type: {
      value: invoices.invoice_type,
      display: (
        <Tag
          text={capitalizeFirstLetter(invoices.invoice_type)}
          color={getInvoiceTypeStatusStatusColor(invoices.invoice_type)}
        />
      )
    },
    company_name: {
      value: getCompanyName(invoices.company),
      display: <div>{getCompanyName(invoices.company)}</div>
    },
    order_reference: {
      value: invoices.order_reference,
      display: <BlueBoldTextContainer>{invoices.order_reference}</BlueBoldTextContainer>
    },
    reference: {
      value: invoices.reference,
      display: <BlueBoldTextContainer>{invoices.reference}</BlueBoldTextContainer>
    },
    sales_person: {
      value: getAdminInital(invoices?.sales_person_id),
      display: <PictureTag text={getAdminInital(invoices?.sales_person_id)} />
    },
    admin: {
      value: getAdminInital(invoices?.admin_id),
      display: <PictureTag text={getAdminInital(invoices?.admin_id)} />
    },
    margin: {
      value: invoices.margin_without_tax,
      display: <div>{invoices.margin_without_tax}</div>
    },
    status: {
      value: invoices.status,
      display: (
        <Tag
          text={capitalizeFirstLetter(invoices.status)}
          color={getInvoiceStatusColor(invoices.status)}
        />
      )
    },
    total_price_without_tax: {
      value: invoices.invoice_amount_without_tax,
      display: <div>{invoices.invoice_amount_without_tax}</div>
    },
    total_price_with_tax: {
      value: invoices.invoice_amount_with_tax,
      display: <div>{invoices.invoice_amount_with_tax}</div>
    },
    down_payment: { value: invoices.down_payment, display: <div>{invoices.down_payment}</div> },
    invoice_balance_amount: {
      value: invoices.invoice_balance_amount,
      display: <div>{invoices.invoice_balance_amount}</div>
    },
    invoice_date: {
      value: invoices.invoice_date,
      display: <div>{new Date(invoices.invoice_date).toLocaleDateString('fr-FR')}</div>
    },
    invoice_reference: {
      value: invoices.customer_orders
        .map((customer) => customer.delivery_note_reference)
        .join('\n'),
      display: (
        <BlueBoldTextContainer>
          {invoices.customer_orders.map((customer) => customer.delivery_note_reference).join('\n')}
        </BlueBoldTextContainer>
      )
    },
    extended: (
      <div>
        <b>general_comment:</b>{' '}
        {invoices.general_comment
          ? invoices.general_comment
          : 'no comment defined for this invoice'}
      </div>
    )
  }));

  const unselectedInvoices = (id: number) => {
    setSelectedInvoices((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteInvoices = () => {
    selectedInvoices.map((InvoiceId) => {
      del(delInvoice(InvoiceId));
      unselectedInvoices(InvoiceId);
    });
    const newInvoicestate = invoices.results.filter(
      (order) => !selectedInvoices.includes(order.id)
    );
    invoices.results = newInvoicestate;
    setInvoices(invoices);
  };

  const updateCustomerInvoices = (newPage: any) => {
    setInvoices(newPage);
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedInvoices(id);
    } else {
      if (!selectedInvoices.includes(id)) {
        setSelectedInvoices((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const getInvoiceDocument = async (id: number) => {
    const PurchaseItemPDF = await post(postCustomerInvoicePDF(id), {}, 'blob');
    const blob = new Blob([PurchaseItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `invoices-${id}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const handlePDFButtonClick = () => {
    selectedInvoices.map(async (InvoiceId) => {
      getInvoiceDocument(InvoiceId);
    });
  };

  useEffect(() => {
    (async () => {
      const invoices = await get(getCustomerInvoices);
      setInvoices(invoices.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Invoices</Title>
      <Overflow>
        <Array
          template={salesInvoices}
          content={invoicesPreProcessing}
          lineSelected={handleLineSelected}
          extended={true}
          pagination={invoices}
          updatedArrayData={updateCustomerInvoices}
          url={getCustomerInvoices}
          tools={
            <>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Register payment`}
                leftIcon={Money()}
                onClick={handleClickRegisterPaymentButton}
                disabled={!selectedInvoices.length}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Credit note`}
                leftIcon={WhiteCross()}
                onClick={handleClickNewCreditNoteButton}
                disabled={selectedInvoices.length !== 1}
              />
              {!!selectedInvoices.length && (
                <>
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedInvoices.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteInvoices}
                  />
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    text={`Download invoices`}
                    leftIcon={Download()}
                    onClick={handlePDFButtonClick}
                  />
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    text={`send by email`}
                    leftIcon={Send()}
                  />
                </>
              )}
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
      <PaymentModal
        isDisplayed={modalPaymentDisplayed}
        onClose={handleCloseSalesPaymentModal}
        preSelectedInvoices={selectedInvoices}
      />
      <InvoiceCreditNoteModal
        isDisplayed={modalCreditNoteDisplayed}
        onClose={handleCloseCreditNoteModal}
        selectedInvoices={selectedInvoices[0]}
      />
    </MainContainer>
  );
};

export default Invoices;
