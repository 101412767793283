import { createContext } from 'react';

const initialState: ProvidersContactContextState = {
  count: 0,
  next: null,
  previous: null,
  results: []
};

const reducer = (
  state: ProvidersContactContextState,
  action: ProvidersContactDispatchAction
): ProvidersContactContextState => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return action.payload;
    case 'SET_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type ProvidersContactContextType = {
  state: ProvidersContactContextState;
  dispatch: React.Dispatch<ProvidersContactDispatchAction>;
};

const Context = createContext<ProvidersContactContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'ProvidersContact';

export { Context, initialState, reducer };
