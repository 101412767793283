import React, { useState } from 'react';
import styled from 'styled-components';

import { delAddress } from 'services/endpoints';
import useApi from 'services/axios';
import { Cross, Dashboard, Download, Info, Trash } from 'svgs';
import { getSelectStyles } from 'utils/style';
import SettingCard from 'components/SettingCard';
import { Button, CustomInput, CustomSelect, Tag } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { TagColor } from 'components/common/Tag';
import { HelperText } from 'utils/array';
import { InputStyle } from 'components/common/CustomInput';

import {
  accountStatusOption,
  accountTypeOption,
  currencyOption,
  paymentMethodOption,
  paymentModalityOption,
  taxeRatesOption,
  termPaymentOption,
  useCompanyHandler
} from '../../../utils/company';
import { addressCompanies, Array } from '../../array';
import { AddressModal } from '../../modal';
import { capitalizeFirstLetter } from '../../Tools';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  gap: 24px;
`;

const FormRow = styled.div`
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

const CustomContainer = styled.div`
  gap: 4px;
  margin-left: 4px;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export enum AddressType {
  BILLING = 'billing',
  SHIPPING = 'shiiping'
}

const getFinancialStatusColor = (status: string): InputStyle => {
  switch (status) {
    case 'ok':
      return InputStyle.GREEN;
    case 'warning':
      return InputStyle.ORANGE;
    case 'critical':
      return InputStyle.RED;
    default:
      return InputStyle.WHITE;
  }
};

const getTypeColor = (status: string) => {
  if (status === AddressType.BILLING) {
    return TagColor.GREEN;
  }
  return TagColor.ORANGE;
};

type CompanySettingsProps = {
  companyDetail: Company;
  setCompanyDetails: React.Dispatch<React.SetStateAction<Company>>;
};

const CompanySettings: React.FC<CompanySettingsProps> = ({ companyDetail, setCompanyDetails }) => {
  const [selectedAddress, setSelectedAddress] = useState<number[]>([]);
  const [modalAddressDisplayed, setModalAddressDisplayed] = useState<boolean>(false);
  const [addressDetail, setAddressDetail] = useState<any>({});
  const { del } = useApi();
  const onChangeCompany = useCompanyHandler();

  const companySettingsPreProcessing = companyDetail.addresses.map((company) => {
    return {
      id: company.id,
      address_1: { value: company.address_1, display: <div>{company.address_1}</div> },
      postal_code: { value: company.postal_code, display: <div>{company.postal_code}</div> },
      country: { value: company.country, display: <div>{company.country}</div> },
      city: { value: company.city, display: <div>{company.city}</div> },
      address_name: { value: company.address_name, display: company.address_name },
      state: { value: company.state, display: <div>{company.state}</div> },
      address_type: {
        value: company.address_type,
        display: (
          <Tag
            text={capitalizeFirstLetter(company.address_type)}
            color={getTypeColor(company.address_type)}
          />
        )
      }
    };
  });

  const [accountStatus, setAccountStatus] = useState<Option>(
    accountStatusOption.find((option) => option.value === companyDetail.status)
  );
  const [accountType, setAccountType] = useState<Option>(
    accountTypeOption.find((option) => option.value === companyDetail.account_type)
  );
  const [currency, setCurrency] = useState<Option>(
    currencyOption.find((option) => option.value === companyDetail.currency)
  );
  const [paymentMethod, setPaymentMethod] = useState<Option>(
    paymentMethodOption.find((option) => option.value === companyDetail.payment_method)
  );
  const [paymentModality, setPaymentModality] = useState<Option>(
    paymentModalityOption.find((option) => option.value === companyDetail.payment_modality)
  );
  const [termsPayment, setTermsPayment] = useState<NumberOption>(
    termPaymentOption.find((option) => option.value === companyDetail.terms_of_payment)
  );
  const [taxeRates, setTaxeRates] = useState<NumberOption>(
    taxeRatesOption.find((option) => option.value === companyDetail.tax_rate)
  );

  const handleChangeCurrency = onChangeCompany('currency', companyDetail, setCurrency);
  const handleChangePaymentMethod = onChangeCompany(
    'payment_method',
    companyDetail,
    setPaymentMethod
  );
  const handleChangePaymentModality = onChangeCompany(
    'payment_modality',
    companyDetail,
    setPaymentModality
  );
  const handleChangeTermsPayment = onChangeCompany(
    'terms_of_payment',
    companyDetail,
    setTermsPayment
  );
  const handleChangeTaxeRates = onChangeCompany('tax_rate', companyDetail, setTaxeRates);
  const handleChangeAccountStatus = onChangeCompany('status', companyDetail, setAccountStatus);
  const handleChangeAccountType = onChangeCompany('account_type', companyDetail, setAccountType);
  const handleChangeHardFinancialLimit = () =>
    onChangeCompany('hard_financial_limit', companyDetail);
  const handleChangeSiren = () => onChangeCompany('siren', companyDetail);
  const handleChangeVatNumber = () => onChangeCompany('vat_number', companyDetail);

  const createCompanyAddress = async () => {
    setAddressDetail({});
    setModalAddressDisplayed(true);
  };

  const unSelectedCompanies = (id: number) => {
    setSelectedAddress((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };
  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unSelectedCompanies(id);
    } else {
      if (!selectedAddress.includes(id)) {
        setSelectedAddress((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const deleteCompany = () => {
    selectedAddress.map((addressId) => {
      del(delAddress(companyDetail.id, addressId));
      unSelectedCompanies(addressId);
    });

    setCompanyDetails({
      ...companyDetail,
      addresses: companyDetail.addresses.filter((company) => !selectedAddress.includes(company.id))
    });
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const contactDetails = companyDetail.addresses.find((address) => address.id === Number(id));
    setAddressDetail(contactDetails);
    setModalAddressDisplayed(true);
  };

  const handleCloseAddressModal = () => {
    setModalAddressDisplayed(false);
  };

  return (
    <Container>
      <SettingCard title="Payment information" icon={Info()}>
        <FormRow>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Siren number</TitleSelect>
              <CustomInput
                defaultValue={companyDetail?.siren}
                placeholder="add Siren number"
                callBack={handleChangeSiren}
                debounceTime={1000}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>VAT number</TitleSelect>
              <CustomInput
                defaultValue={companyDetail?.vat_number}
                placeholder="add VAT number"
                callBack={handleChangeVatNumber}
                debounceTime={1000}
              />
            </CustomContainer>
          </InputRowContainer>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Currency</TitleSelect>
              <CustomSelect
                placeholder="$ USD"
                value={currency}
                options={currencyOption}
                onChange={handleChangeCurrency}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Payment method</TitleSelect>
              <CustomSelect
                placeholder="Transfer"
                value={paymentMethod}
                options={paymentMethodOption}
                onChange={handleChangePaymentMethod}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Payment modality</TitleSelect>
              <CustomSelect
                placeholder="Net"
                value={paymentModality}
                options={paymentModalityOption}
                onChange={handleChangePaymentModality}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Terms of payment</TitleSelect>
              <CustomSelect
                placeholder="Default"
                value={termsPayment}
                options={termPaymentOption}
                onChange={handleChangeTermsPayment}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Tax rate</TitleSelect>
              <CustomSelect
                placeholder="Tax rate"
                value={taxeRates}
                options={taxeRatesOption}
                onChange={handleChangeTaxeRates}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '150px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
          </InputRowContainer>
          <InputRowContainer>
            <CustomContainer>
              <TitleSelect>Account status</TitleSelect>
              <CustomSelect
                placeholder="Active account"
                value={accountStatus}
                options={accountStatusOption}
                onChange={handleChangeAccountStatus}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '250px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Account Type</TitleSelect>
              <CustomSelect
                placeholder="Active account"
                value={accountType}
                options={accountTypeOption}
                onChange={handleChangeAccountType}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '250px', '50px')}
                usePortalStyles={true}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Current outstandings</TitleSelect>
              <CustomInput
                isDisabled={true}
                defaultValue={companyDetail?.payment_due_amount?.toString()}
                inputStyle={getFinancialStatusColor(companyDetail?.financial_status)}
                placeholder="ex: +$ 100000"
                debounceTime={1000}
              />
            </CustomContainer>
            <CustomContainer>
              <TitleSelect>Max financial outstandings</TitleSelect>
              <CustomInput
                defaultValue={companyDetail?.hard_financial_limit?.toString()}
                placeholder="ex: - 500"
                callBack={handleChangeHardFinancialLimit}
                debounceTime={1000}
              />
            </CustomContainer>
          </InputRowContainer>
        </FormRow>
      </SettingCard>
      <SettingCard title="Company’s adresses" icon={Dashboard()}>
        <>
          <Array
            template={addressCompanies}
            content={companySettingsPreProcessing}
            lineSelected={handleLineSelected}
            lineClicked={handleLineClicked}
            isSearchable={false}
            tools={
              <>
                <Button
                  variant={ButtonVariant.PRIMARY}
                  text="Address"
                  leftIcon={Cross()}
                  onClick={createCompanyAddress}
                />
                <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
                {!!selectedAddress.length && (
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedAddress.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteCompany}
                  />
                )}
              </>
            }
          />
          {!companySettingsPreProcessing.length && (
            <HelperText>
              Add a billing and shipping address to start a business with this company
            </HelperText>
          )}
          <AddressModal
            isDisplayed={modalAddressDisplayed}
            addressDetail={addressDetail}
            onClose={handleCloseAddressModal}
          />
        </>
      </SettingCard>
    </Container>
  );
};

export default CompanySettings;
