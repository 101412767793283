import React, { useState } from 'react';
import styled from 'styled-components';

import { ArrowDown, CompanyIcon } from 'svgs';

const Container = styled.div`
  width: 100%;
`;

const StyledDropDown = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 ${(props) => props.theme.spacing.xxs}px;
  background: ${(props) => props.theme.colors.primary.mainTransparent};
  border-radius: 10px;
`;

const TitleBlock = styled.div`
  display: flex;
  align-items: center;
`;

const Arrow = styled.div<{
  isOpen: boolean;
}>`
  display: inline-flex;
  transform: ${(props) => (props.isOpen ? `rotate(0deg)` : `rotate(180deg)`)};
  transition: transform 0.175s ease-out;
`;

const Title = styled.h4`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: ${(props) => props.theme.colors.primary.main};
  margin-left: ${(props) => props.theme.spacing.xxs}px;
`;

type DropDownProps = {
  title: string;
  children: JSX.Element;
  showContent?: boolean;
};

const DropDown = ({ title, children, showContent = false }: DropDownProps) => {
  const [isShowContent, setIsShowContent] = useState(showContent);

  const handleClick = () => setIsShowContent((showContent) => !showContent);

  return (
    <div>
      <Container>
        <StyledDropDown onClick={handleClick}>
          <TitleBlock>
            <CompanyIcon />
            <Title>{title}</Title>
          </TitleBlock>
          <Arrow isOpen={isShowContent}>
            <ArrowDown />
          </Arrow>
        </StyledDropDown>
      </Container>
      {isShowContent && children}
    </div>
  );
};

export default DropDown;
