import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { productTypeOptions } from 'utils/Item';
import { getSelectStyles } from 'utils/style';
import useApi from 'services/axios';
import {
  getGerFile,
  getItem,
  getItemSearch,
  getItemValidRef,
  patchItem,
  patchQuotationItem,
  postDuplicateExistingItem,
  postItem,
  postUploadGerFile
} from 'services/endpoints';
import {
  yesNoOption,
  cuLayersOption,
  finalCuOption,
  legendColorOption,
  maskColorOption,
  materialOption,
  pcbThicknessOption,
  solderMaskAndLegendOption,
  surfaceFinishOption,
  thicknessOption,
  deliveryOption,
  pcbSeparationOption,
  gerberOption,
  layerChoiseOption,
  IPCClassOption,
  goldFingerOption,
  viaMaskingOption,
  hdiOption,
  stackupOption,
  minSpaceOption,
  drillSizeOption,
  goldFingerThicknessOption,
  CAOSerializer,
  ComponentSerializer,
  MechanicSerializer,
  PCBSerializer,
  StencilSerializer,
  StudySerializer,
  WiringSerializer
} from 'utils/sales';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import PCBGenerator from 'components/PCBGenerator';
import { PCBSeparation } from 'components/Tools';
import { Download, Edit } from 'svgs';

import { Button, CustomInput, CustomSelect, CustomTextArea, ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const SelectedTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #434d56;
`;

const SelectWrapper = styled.div`
  max-width: 250px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const PCBGeneratorContainer = styled.div`
  display: flex;
  gap: 16px;
`;

const ClickableZone = styled.div<{
  isDisabled: boolean;
}>`
  display: grid;
  grid-template-columns: 180px auto auto;
  border-radius: 8px;
  padding: 8px;
  ${(props) =>
    props.isDisabled
      ? 'background: #eef1f3'
      : `&:hover {
    background: #eef1f3;
    cursor: pointer;
  }`};
`;

const ClickableZoneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TextValue = styled.text`
  color: #1975ff;
  font-weight: 700;
`;

const DoubleColumnValue = styled(TextValue)`
  grid-column-end: span 2;
`;

const IconColumn = styled(TextValue)`
  grid-column-end: span 3;
  text-align: end;
`;

const TitleGrid = styled.text`
  grid-column-end: span 3;
  font-weight: 700;
  color: black;
  margin-bottom: 8px;
`;

const ContentModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const GridModalContent = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: start;
  grid-gap: 16px;
`;

const GerberFileContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const GerberFileUploaded = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 16px;
`;

const InputFile = styled.input`
  display: none;
`;

const LabelFile = styled.label`
  display: flex;
  padding: 6px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid var(--Primary-03-Main, #1975ff);
  cursor: pointer;

  color: #1975ff;
  font-weight: 600;
  line-height: 1.25;
`;

const WrapperUpdatedButton = styled.div`
  margin-top: 16px;
`;

const getProductSerializer = (productName: string, reference: string) => {
  switch (productName) {
    case 'pcb':
      return PCBSerializer(reference);
    case 'study':
      return StudySerializer(reference);
    case 'cao':
      return CAOSerializer(reference);
    case 'stencil':
      return StencilSerializer(reference);
    case 'component':
      return ComponentSerializer(reference);
    case 'mechanic':
      return MechanicSerializer(reference);
    case 'wiring':
      return WiringSerializer(reference);
    default:
      return null;
  }
};

type ReferenceProps = {
  quotationItem?: QuotationItem;
  productName?: string;
  setItem?: React.Dispatch<React.SetStateAction<ItemRendered>>;
  handleSelectRef: (newQuotationItem: QuotationItem) => void;
};

const ReferenceCustomSelect: React.FC<ReferenceProps> = ({
  quotationItem,
  productName = '',
  setItem,
  handleSelectRef
}) => {
  const [referenceName, setReferenceName] = useState<Option>();
  const [referenceOption, setReferenceOption] = useState<Option[]>([]);
  const [currentValue, setCurrentValue] = useState<string>();
  const { get, patch, post } = useApi();

  const handleInputChange = (inputValue: string, test: { action: string }) => {
    if (test.action !== 'menu-close' && test.action !== `input-blur`) {
      setCurrentValue(inputValue);
    }
    if (test.action === 'menu-close') {
      setCurrentValue('');
      setReferenceOption([]);
    }
    return inputValue;
  };

  const handleSelectClick = async (e: Option) => {
    setReferenceName(e);

    if (e.value === -1) {
      const productItem = await post(postItem, getProductSerializer(productName, e.filterLabel));
      const newItem = await patch(
        patchQuotationItem(
          quotationItem.company,
          quotationItem.contact,
          quotationItem.quotation,
          quotationItem.quotation_version,
          quotationItem.id
        ),
        {
          item: productItem.data.id
        }
      );
      setItem(newItem.data.item_rendered);
      handleSelectRef(newItem.data);
    } else {
      const newItem = await patch(
        patchQuotationItem(
          quotationItem.company,
          quotationItem.contact,
          quotationItem.quotation,
          quotationItem.quotation_version,
          quotationItem.id
        ),
        {
          item: e.value
        }
      );
      setItem(newItem.data.item_rendered);
      handleSelectRef(newItem.data);
    }
  };

  useEffect(() => {
    if (currentValue) {
      (async () => {
        const itemsResult = await get(
          getItemSearch() +
            `?company_id=${quotationItem.company}&ref=${currentValue}&product_type=${productName}`
        );

        const isValid = await get(
          getItemValidRef() +
            `?company_id=${quotationItem.company}&ref=${currentValue}&product_type=${productName}`,
          'json',
          false
        );

        const newOptions = await itemsResult.data.map((item: ItemRendered) => {
          return {
            label: (
              <div>
                <div>
                  Reference: <b>{item.reference}</b>
                </div>
                <div>Internal reference: {item.internal_reference}</div>
              </div>
            ),
            value: item.id,
            filterLabel: `${item.reference} ${item.internal_reference}`
          };
        });

        if (isValid.status === 204) {
          newOptions.push({
            label: (
              <div>
                <div>
                  Reference: <b>{currentValue}</b>
                </div>
                <div>(New Item)</div>
              </div>
            ),
            value: -1,
            filterLabel: `${currentValue}`
          });
        }

        setReferenceOption(newOptions);
      })();
    }
  }, [currentValue]);

  const customFilterOption = (option: any, inputValue: string) => {
    return option.data.filterLabel?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  return (
    <SelectWrapper>
      <SelectedTitle>Reference</SelectedTitle>
      <CustomSelect
        placeholder="XXXX"
        value={referenceName}
        options={referenceOption}
        onInputChange={handleInputChange}
        onChange={handleSelectClick}
        isMulti={false}
        isSearchable={true}
        isDisabled={!!referenceName || !productName}
        styles={getSelectStyles(null, '250px', '50px')}
        getOptionLabel={(option) => option.label}
        filterOption={customFilterOption}
      />
    </SelectWrapper>
  );
};

interface ClickableZoneProps {
  pcbRef: any;
  isDisabled?: boolean;
  handleOpenModal: () => void;
}

const ClickablePanelZone: React.FC<ClickableZoneProps> = ({
  pcbRef,
  isDisabled,
  handleOpenModal
}) => {
  return (
    <div
      onClick={handleOpenModal}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
    >
      <ClickableZone isDisabled={isDisabled}>
        <TitleGrid>Measure Section</TitleGrid>
        {pcbRef?.product_information.multiple_design === 'no' && (
          <>
            <text>UOM</text>
            <DoubleColumnValue>{pcbRef?.product_information.uom}</DoubleColumnValue>
            <text>Delivery Format</text>
            <DoubleColumnValue>{pcbRef?.product_information.delivery_format}</DoubleColumnValue>
            <text></text>
            <text>X</text>
            <text>Y</text>
            <text>PCB Size</text>
            <TextValue>{pcbRef?.product_information.pcb_size_x}</TextValue>
            <TextValue>{pcbRef?.product_information.pcb_size_y}</TextValue>
          </>
        )}
        {pcbRef?.product_information.delivery_format === 'panel' && (
          <>
            {pcbRef?.product_information.multiple_design === 'no' ? (
              <>
                <text>PCB Qty</text>
                <TextValue>{pcbRef?.product_information.pcb_quantity_x}</TextValue>
                <TextValue>{pcbRef?.product_information.pcb_quantity_y}</TextValue>
                <text>Array size</text>
                <TextValue>{pcbRef?.product_information.array_size_x}</TextValue>
                <TextValue>{pcbRef?.product_information.array_size_y}</TextValue>
              </>
            ) : (
              <>
                <text>Delivery Format</text>
                <DoubleColumnValue>{pcbRef?.product_information.delivery_format}</DoubleColumnValue>
                <text></text>
                <text>X</text>
                <text>Y</text>
                <text>Panel size</text>
                <TextValue>{pcbRef?.product_information.array_size_x}</TextValue>
                <TextValue>{pcbRef?.product_information.array_size_y}</TextValue>
                <text>Number of designs</text>
                <DoubleColumnValue>
                  {pcbRef?.product_information.multi_design_nb_designs}
                </DoubleColumnValue>
              </>
            )}
          </>
        )}
        <IconColumn>{Edit()}</IconColumn>
      </ClickableZone>
    </div>
  );
};

const ClickableMaterialZone: React.FC<ClickableZoneProps> = ({
  pcbRef,
  isDisabled,
  handleOpenModal
}) => {
  return (
    <div
      onClick={handleOpenModal}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
    >
      <ClickableZone isDisabled={isDisabled}>
        <TitleGrid>Stack up and Surface treatment Section</TitleGrid>
        <text>base material</text>
        <DoubleColumnValue>{pcbRef.product_information.base_material}</DoubleColumnValue>
        <text>Cu layers</text>
        <DoubleColumnValue>{pcbRef.product_information.cut_layers}</DoubleColumnValue>
        <text>PCB thickness</text>
        <DoubleColumnValue>{pcbRef.product_information.pcb_thickness}</DoubleColumnValue>
        <text>Final Cu Outer</text>
        <DoubleColumnValue>{pcbRef.product_information.final_cu_outer}</DoubleColumnValue>
        <text>Final Cu Inner</text>
        <DoubleColumnValue>{pcbRef.product_information.final_cu_inner}</DoubleColumnValue>
        <text>Surface Finish</text>
        <DoubleColumnValue>{pcbRef.product_information.surface_finish}</DoubleColumnValue>
        <IconColumn>{Edit()}</IconColumn>
      </ClickableZone>
    </div>
  );
};

const ClickableMaskZone: React.FC<ClickableZoneProps> = ({
  pcbRef,
  isDisabled,
  handleOpenModal
}) => {
  return (
    <div
      onClick={handleOpenModal}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
    >
      <ClickableZone isDisabled={isDisabled}>
        <TitleGrid>Mask Section</TitleGrid>
        <text>Mask Color</text>
        <DoubleColumnValue>{pcbRef.product_information.mask_color}</DoubleColumnValue>
        <text>Solder Mask</text>
        <DoubleColumnValue>{pcbRef.product_information.solder_mask}</DoubleColumnValue>
        <text>Mask Type</text>
        <DoubleColumnValue>{pcbRef.product_information.mask_type}</DoubleColumnValue>
        <IconColumn>{Edit()}</IconColumn>
      </ClickableZone>
    </div>
  );
};

const ClickableSilkScreenZone: React.FC<ClickableZoneProps> = ({
  pcbRef,
  isDisabled,
  handleOpenModal
}) => {
  return (
    <div
      onClick={handleOpenModal}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
    >
      <ClickableZone isDisabled={isDisabled}>
        <TitleGrid>Silkscreen Section</TitleGrid>
        <text>Legend</text>
        <DoubleColumnValue>{pcbRef.product_information.legend}</DoubleColumnValue>
        <text>Legend Color</text>
        <DoubleColumnValue>{pcbRef.product_information.legend_color}</DoubleColumnValue>
        <IconColumn>{Edit()}</IconColumn>
      </ClickableZone>
    </div>
  );
};

const ClickableAdditionalZone: React.FC<ClickableZoneProps> = ({
  pcbRef,
  isDisabled,
  handleOpenModal
}) => {
  return (
    <div
      onClick={handleOpenModal}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
    >
      <ClickableZone isDisabled={isDisabled}>
        <TitleGrid>Additional Section</TitleGrid>
        {pcbRef.product_information.via_masking === 'yes' && (
          <>
            <text>Via Masking</text>
            <DoubleColumnValue>{pcbRef.product_information.via_masking}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.via_resign_plug === 'yes' && (
          <>
            <text>Via Resign Plug</text>
            <DoubleColumnValue>{pcbRef.product_information.via_resign_plug}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.min_space !== '' && (
          <>
            <text>Min trace / space</text>
            <DoubleColumnValue>{pcbRef.product_information.min_space}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.min_drill_size !== '' && (
          <>
            <text>Min Drill Size</text>
            <DoubleColumnValue>{pcbRef.product_information.min_drill_size}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.edge_plating === 'yes' && (
          <>
            <text>Edge Plating</text>
            <DoubleColumnValue>{pcbRef.product_information.edge_plating}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.number_of_edges && (
          <>
            <text>Number of Edges</text>
            <DoubleColumnValue>{pcbRef.product_information.number_of_edges}</DoubleColumnValue>
          </>
        )}

        {pcbRef.product_information.imped_ctrl === 'yes' && (
          <>
            <text>Imped Ctrl</text>
            <DoubleColumnValue>{pcbRef.product_information.imped_ctrl}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.carbon_print === 'yes' && (
          <>
            <text>Carbon Print</text>
            <DoubleColumnValue>{pcbRef.product_information.carbon_print}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.coll_track_board === 'yes' && (
          <>
            <text>Coil track board</text>
            <DoubleColumnValue>{pcbRef.product_information.coll_track_board}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.stackup !== '' && (
          <>
            <text>Stackup</text>
            <DoubleColumnValue>{pcbRef.product_information.stackup}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.non_std_stackup_core !== '' && (
          <>
            <text>Non Std Stackup Core</text>
            <DoubleColumnValue>{pcbRef.product_information.non_std_stackup_core}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.press_fit === 'yes' && (
          <>
            <text>Press Fit</text>
            <DoubleColumnValue>{pcbRef.product_information.press_fit}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.peelable_mask === 'yes' && (
          <>
            <text>Peelable Mask</text>
            <DoubleColumnValue>{pcbRef.product_information.peelable_mask}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.abnormal_hole === 'yes' && (
          <>
            <text>Abnormal Hole</text>
            <DoubleColumnValue>{pcbRef.product_information.abnormal_hole}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.z_routing === 'yes' && (
          <>
            <text>Z Routing</text>
            <DoubleColumnValue>{pcbRef.product_information.z_routing}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.gold_finger_thickness !== 0 && (
          <>
            <text>Gold Finger Thickness</text>
            <DoubleColumnValue>
              {pcbRef.product_information.gold_finger_thickness}
            </DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.gold_finger_quantity !== 0 && (
          <>
            <text>Gold Finger Quantity</text>
            <DoubleColumnValue>{pcbRef.product_information.gold_finger_quantity}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.counter_sunk === 'yes' && (
          <>
            <text>Counter sink</text>
            <DoubleColumnValue>{pcbRef.product_information.counter_sunk}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.non_std_stackup_core && (
          <>
            <text>Sink Quantity</text>
            <DoubleColumnValue>
              {pcbRef.product_information.counter_sunk_quantity}
            </DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.via_resign_plug === 'yes' && (
          <>
            <text>Slots</text>
            <DoubleColumnValue>{pcbRef.product_information.slots}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.hdi === 'yes' && (
          <>
            <text>HDI</text>
            <DoubleColumnValue>{pcbRef.product_information.hdi}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.print_serial_number === 'yes' && (
          <>
            <text>Print Serial Number</text>
            <DoubleColumnValue>{pcbRef.product_information.print_serial_number}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.coupons === 'yes' && (
          <>
            <text>Impedance coupons</text>
            <DoubleColumnValue>{pcbRef.product_information.coupons}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.solder_sample === 'yes' && (
          <>
            <text>Solder Sample</text>
            <DoubleColumnValue>{pcbRef.product_information.solder_sample}</DoubleColumnValue>
          </>
        )}
        <IconColumn>{Edit()}</IconColumn>
      </ClickableZone>
    </div>
  );
};

const ClickableHomologationZone: React.FC<ClickableZoneProps> = ({
  pcbRef,
  isDisabled,
  handleOpenModal
}) => {
  return (
    <div
      onClick={handleOpenModal}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
    >
      <ClickableZone isDisabled={isDisabled}>
        <TitleGrid>Homologation Section</TitleGrid>
        <text>IPC Class</text>
        <DoubleColumnValue>{pcbRef.product_information.ipc_class}</DoubleColumnValue>
        <IconColumn>{Edit()}</IconColumn>
      </ClickableZone>
    </div>
  );
};

const ClickableLogoZone: React.FC<ClickableZoneProps> = ({
  pcbRef,
  isDisabled,
  handleOpenModal
}) => {
  return (
    <div
      onClick={handleOpenModal}
      role="button"
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          handleOpenModal();
        }
      }}
      tabIndex={0}
    >
      <ClickableZone isDisabled={isDisabled}>
        <TitleGrid>Logo Section</TitleGrid>
        {pcbRef.product_information.ul_logo === 'yes' && (
          <>
            <text>Ul Logo</text>
            <DoubleColumnValue>{pcbRef.product_information.ul_logo}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.rohs === 'yes' && (
          <>
            <text>Rohs</text>
            <DoubleColumnValue>{pcbRef.product_information.rohs}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.etest === 'yes' && (
          <>
            <text>Etest</text>
            <DoubleColumnValue>{pcbRef.product_information.etest}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.date_code === 'yes' && (
          <>
            <text>Date Code</text>
            <DoubleColumnValue>{pcbRef.product_information.date_code}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.pcb_electronics_logo === 'yes' && (
          <>
            <text>PCB Electronics Logo</text>
            <DoubleColumnValue>{pcbRef.product_information.pcb_electronics_logo}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.batch_number === 'yes' && (
          <>
            <text>Batch Number</text>
            <DoubleColumnValue>{pcbRef.product_information.batch_number}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.print_serial_number === 'yes' && (
          <>
            <text>Serial Number</text>
            <DoubleColumnValue>{pcbRef.product_information.print_serial_number}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.customized_text !== '' && (
          <>
            <text>Customized Text</text>
            <DoubleColumnValue>{pcbRef.product_information.customized_text}</DoubleColumnValue>
          </>
        )}
        {pcbRef.product_information.layers_choice === '' && (
          <>
            <text>Layers Choice</text>
            <DoubleColumnValue>{pcbRef.product_information.layers_choice}</DoubleColumnValue>
          </>
        )}
        <IconColumn>{Edit()}</IconColumn>
      </ClickableZone>
    </div>
  );
};

const findOption = (ArrayOptions: Array<any>, findedOption: string) => {
  return ArrayOptions.find((option) => option.value === findedOption);
};

type PCBProps = {
  item: any;
  setDetails: React.Dispatch<any>;
  isDisabled: boolean;
};

const PCBMechanic: React.FC<PCBProps> = ({ item, isDisabled, setDetails }) => {
  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    setDetails({ ...item, product_information: { ...item.product_information, [name]: value } });
  };
  return (
    <div>
      <CustomTextArea
        title="Additional information"
        value={item.product_information.additional_comment}
        placeholder="---"
        callBack={() => handleInputChangeV2('additional_comment')}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const PCBComponent: React.FC<PCBProps> = ({ item, isDisabled, setDetails }) => {
  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    setDetails({ ...item, product_information: { ...item.product_information, [name]: value } });
  };
  return (
    <div>
      <CustomTextArea
        title="Additional information"
        value={item.product_information.additional_comment}
        placeholder="---"
        callBack={() => handleInputChangeV2('additional_comment')}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const PCBStencil: React.FC<PCBProps> = ({ item, isDisabled, setDetails }) => {
  const [thickness, setThickness] = useState<Option>(
    findOption(thicknessOption, item.product_information.thickness)
  );

  const handleSelectChange = (setter: (e: Option) => void, name: string, e: Option) => {
    setter(e);
    setDetails({ ...item, product_information: { ...item.product_information, [name]: e.value } });
  };

  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    setDetails({ ...item, product_information: { ...item.product_information, [name]: value } });
  };

  return (
    <div>
      <Row>
        <SelectWrapper>
          <SelectedTitle>Thickness</SelectedTitle>
          <CustomSelect
            placeholder="0.1 mm"
            value={thickness}
            options={thicknessOption}
            onChange={(e) => {
              handleSelectChange(setThickness, 'thickness', e);
            }}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '250px', '50px')}
            isDisabled={isDisabled}
          />
        </SelectWrapper>
      </Row>
      <CustomTextArea
        title="Additional information"
        value={item.product_information.additional_comment}
        placeholder="---"
        callBack={() => handleInputChangeV2('additional_comment')}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const PCBWiring: React.FC<PCBProps> = ({ item, isDisabled, setDetails }) => {
  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    setDetails({ ...item, product_information: { ...item.product_information, [name]: value } });
  };
  return (
    <div>
      <CustomTextArea
        title="Additional information"
        value={item.product_information.additional_comment}
        placeholder="---"
        callBack={() => handleInputChangeV2('additional_comment')}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const PCBCao: React.FC<PCBProps> = ({ item, isDisabled, setDetails }) => {
  const [sourceFile, setSourceFile] = useState<Option>(
    findOption(yesNoOption, item.product_information.source_files_provided)
  );
  const [outputFile, setOutputFile] = useState<Option>(
    findOption(gerberOption, item.product_information.output_files)
  );

  const handleSelectChange = (setter: (e: Option) => void, name: string, e: Option) => {
    setter(e);
    setDetails({ ...item, product_information: { ...item.product_information, [name]: e.value } });
  };

  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    setDetails({ ...item, product_information: { ...item.product_information, [name]: value } });
  };

  return (
    <div>
      <Row>
        <SelectWrapper>
          <SelectedTitle>Source files provided</SelectedTitle>
          <CustomSelect
            placeholder="No"
            value={sourceFile}
            options={yesNoOption}
            onChange={(e) => {
              handleSelectChange(setSourceFile, 'source_files_provided', e);
            }}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '250px', '50px')}
            isDisabled={isDisabled}
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectedTitle>Output file</SelectedTitle>
          <CustomSelect
            placeholder="Gerber only"
            value={outputFile}
            options={gerberOption}
            onChange={(e) => {
              handleSelectChange(setOutputFile, 'output_files', e);
            }}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '250px', '50px')}
            isDisabled={isDisabled}
          />
        </SelectWrapper>
      </Row>
      <CustomTextArea
        title="Additional information"
        value={item.product_information.additional_comment}
        placeholder="---"
        callBack={() => handleInputChangeV2('additional_comment')}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const PCBStudy: React.FC<PCBProps> = ({ item, isDisabled, setDetails }) => {
  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    setDetails({ ...item, product_information: { ...item.product_information, [name]: value } });
  };
  return (
    <div>
      <CustomTextArea
        title="Special specifications"
        value={item.product_information.special_specifications}
        placeholder="---"
        callBack={() => handleInputChangeV2('special_specifications')}
        isDisabled={isDisabled}
      />
      <CustomTextArea
        title="Study and development costing includes"
        value={item.product_information.study_and_development_costing_includes}
        placeholder="---"
        callBack={() => handleInputChangeV2('study_and_development_costing_includes')}
        isDisabled={isDisabled}
      />
      <CustomTextArea
        title="Costing Remarks"
        value={item.product_information.costing_remarks}
        placeholder="---"
        callBack={() => handleInputChangeV2('costing_remarks')}
        isDisabled={isDisabled}
      />
    </div>
  );
};

const PCBReferenceModal: React.FC<
  PCBProps & {
    handleClosePcbModal: (modalName: string) => void;
    handleSaveButtonClick: () => void;
    isPanelModalDisplayed: boolean;
    isMaterialModalDisplayed: boolean;
    isMaskModalDisplayed: boolean;
    isSilkScreenModalDisplayed: boolean;
    isAdditionalModalDisplayed: boolean;
    isHomologationModalDisplayed: boolean;
    isLogoModalDisplayed: boolean;
  }
> = ({
  item,
  setDetails,
  handleClosePcbModal,
  handleSaveButtonClick,
  isPanelModalDisplayed,
  isMaterialModalDisplayed,
  isMaskModalDisplayed,
  isSilkScreenModalDisplayed,
  isAdditionalModalDisplayed,
  isHomologationModalDisplayed,
  isLogoModalDisplayed
}) => {
  const [pcbRef, setPcbRef] = useState<any>({ ...item });
  const [delivery, setDelivery] = useState<Option>(
    findOption(deliveryOption, pcbRef.product_information.delivery_format)
  );
  const [pcbSeparationX, setPcbSeparationX] = useState<Option>(
    findOption(pcbSeparationOption, pcbRef.product_information.pcb_separation_x)
  );
  const [pcbSeparationY, setPcbSeparationY] = useState<Option>(
    findOption(pcbSeparationOption, pcbRef.product_information.pcb_separation_y)
  );
  const [xOuts, setXOuts] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.x_outs)
  );
  const [multipleDesign, setMultipleDesign] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.multiple_design)
  );
  const [cuLayers, setCuLayers] = useState<Option>(
    findOption(cuLayersOption, pcbRef.product_information.cut_layers)
  );
  const [pcbThickness, setPcbThickness] = useState<Option>(
    findOption(pcbThicknessOption, pcbRef.product_information.pcb_thickness)
  );
  const [baseMaterial, setBaseMaterial] = useState<Option>(
    findOption(materialOption, pcbRef.product_information.base_material)
  );
  const [finalCuOuter, setFinalCuOuter] = useState<Option>(
    findOption(finalCuOption, pcbRef.product_information.final_cu_outer)
  );
  const [finalCuInner, setFinalCuInner] = useState<Option>(
    findOption(finalCuOption, pcbRef.product_information.final_cu_inner)
  );
  const [surfaceFinish, setSurfaceFinish] = useState<Option>(
    findOption(surfaceFinishOption, pcbRef.product_information.surface_finish)
  );
  const [solderMask, setSolderMask] = useState<Option>(
    findOption(solderMaskAndLegendOption, pcbRef.product_information.solder_mask)
  );
  const [legend, setLegend] = useState<Option>(
    findOption(solderMaskAndLegendOption, pcbRef.product_information.legend)
  );
  const [maskColor, setMaskColor] = useState<Option>(
    findOption(maskColorOption, pcbRef.product_information.mask_color)
  );
  const [legendColor, setLegendColor] = useState<Option>(
    findOption(legendColorOption, pcbRef.product_information.legend_color)
  );
  const [resignPlug, setResignPlug] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.via_resign_plug)
  );
  const [drillSize, setDrillSize] = useState<Option>(
    findOption(drillSizeOption, pcbRef.product_information.min_drill_size)
  );
  const [minSpace, setMinSpace] = useState<Option>(
    findOption(minSpaceOption, pcbRef.product_information.min_space)
  );
  const [edgePlating, setEdgePlating] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.edge_plating)
  );
  const [impedControl, setImpedControl] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.imped_ctrl)
  );
  const [carbonPrint, setCarbonPrint] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.carbon_print)
  );
  const [collTrackBoard, setCollTrackBoard] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.press_fit)
  );
  const [stackup, setStackup] = useState<Option>(
    findOption(stackupOption, pcbRef.product_information.stackup)
  );
  const [pressFit, setPressFit] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.press_fit)
  );
  const [peelableMask, setPeelableMask] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.peelable_mask)
  );
  const [abnormalHole, setAbnormalHole] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.abnormal_hole)
  );
  const [goldFinger, setGoldFinger] = useState<Option>(
    findOption(goldFingerThicknessOption, pcbRef.product_information.gold_finger_thickness)
  );
  const [zRouting, setZRouting] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.z_routing)
  );
  const [counterSunk, setCounterSunk] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.counter_sunk)
  );
  const [slots, setSlots] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.slots)
  );
  const [rohs, setRohs] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.rohs)
  );
  const [ulLogo, setUlLogo] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.ul_logo)
  );
  const [etest, setEtest] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.etest)
  );
  const [dateCode, setDateCode] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.date_code)
  );
  const [pcbElectronicsLogo, setPcbElectronicsLogo] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.pcb_electronics_logo)
  );
  const [batchNumber, setBatchNumber] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.batch_number)
  );
  const [layersChoice, setLayersChoice] = useState<Option>(
    findOption(layerChoiseOption, pcbRef.product_information.layers_choice)
  );
  const [printSerialNumber, setPrintSerialNumber] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.print_serial_number)
  );
  const [microSecReport, setMicroSecReport] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.micro_sec_report)
  );
  const [ipcClass, setIpcClass] = useState<Option>(
    findOption(IPCClassOption, pcbRef.product_information.ipc_class)
  );
  const [coupons, setCoupons] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.coupons)
  );
  const [solderSample, setSolderSample] = useState<Option>(
    findOption(yesNoOption, pcbRef.product_information.solder_sample)
  );
  const [goldFingerQuantity, setGoldFingerQuantity] = useState<Option>(
    findOption(goldFingerOption, pcbRef.product_information.gold_finger_quantity)
  );
  const [viaMasking, setViaMasking] = useState<Option>(
    findOption(viaMaskingOption, pcbRef.product_information.via_masking)
  );
  const [hdi, setHdi] = useState<Option>(findOption(hdiOption, pcbRef.product_information.hdi));
  const [isPanelModal, setIsPanelModal] = useState<boolean>(false);
  const [isMaterialModal, setIsMaterialModal] = useState<boolean>(false);
  const [isMaskModal, setIsMaskModal] = useState<boolean>(false);
  const [isSilkScreenModal, setIsSilkScreenModal] = useState<boolean>(false);
  const [isAdditionalModal, setIsAdditionalModal] = useState<boolean>(false);
  const [isHomologationModal, setIsHomologationModal] = useState<boolean>(false);
  const [isLogoModal, setIsLogoModal] = useState<boolean>(false);

  useEffect(() => {
    setIsPanelModal(isPanelModalDisplayed);
    setIsMaterialModal(isMaterialModalDisplayed);
    setIsMaskModal(isMaskModalDisplayed);
    setIsSilkScreenModal(isSilkScreenModalDisplayed);
    setIsAdditionalModal(isAdditionalModalDisplayed);
    setIsHomologationModal(isHomologationModalDisplayed);
    setIsLogoModal(isLogoModalDisplayed);
  }, [
    isPanelModalDisplayed,
    isMaterialModalDisplayed,
    isMaskModalDisplayed,
    isSilkScreenModalDisplayed,
    isAdditionalModalDisplayed,
    isHomologationModalDisplayed,
    isLogoModalDisplayed
  ]);

  useEffect(() => {
    setPcbRef(item);
  }, [item]);

  const handleClosePanelModal = () => {
    handleClosePcbModal('isPanelModal');
  };

  const handleCloseMaterialModal = () => {
    handleClosePcbModal('isMaterialModal');
  };

  const handleCloseMaskModal = () => {
    handleClosePcbModal('isMaskModal');
  };

  const handleCloseSilkScreenModal = () => {
    handleClosePcbModal('isSilkScreenModal');
  };

  const handleCloseAdditionalModal = () => {
    handleClosePcbModal('isAdditionalModal');
  };

  const handleCloseHomologationModal = () => {
    handleClosePcbModal('isHomologationModal');
  };

  const handleCloseLogoModal = () => {
    handleClosePcbModal('isLogoModal');
  };

  const handleSelectChangeV2 = (name: string, e: Option, setter: React.Dispatch<any>) => {
    setter(e);
    const newProductInformation = {
      ...pcbRef.product_information,
      [name]: e.value
    };

    const newItemRendered: ItemRendered = {
      ...pcbRef,
      product_information: newProductInformation
    };
    setPcbRef(newItemRendered);
    setDetails(newItemRendered);
  };

  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    const newProductInformation = {
      ...pcbRef.product_information,
      [name]: value
    };

    const newItemRendered: ItemRendered = {
      ...pcbRef,
      product_information: newProductInformation
    };
    setPcbRef(newItemRendered);
    setDetails(newItemRendered);
  };

  return (
    <>
      {isPanelModal && (
        <ModalSidebar
          isOpen={isPanelModal}
          onClose={handleClosePanelModal}
          title="Measure Section"
          footer={
            <Button variant={ButtonVariant.PRIMARY} text="Back" onClick={handleClosePanelModal} />
          }
        >
          <ContentModalContainer>
            <GridModalContent>
              <SelectWrapper>
                <SelectedTitle>Delivery Format</SelectedTitle>
                <CustomSelect
                  placeholder="Single"
                  value={delivery}
                  options={deliveryOption}
                  onChange={(e) => {
                    handleSelectChangeV2('delivery_format', e, setDelivery);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>

              <>
                {delivery.value === 'single' && (
                  <>
                    <SelectWrapper>
                      <CustomInput
                        title="PCB Size X"
                        placeholder="XXX"
                        defaultValue={pcbRef?.product_information.pcb_size_x}
                        callBack={() => handleInputChangeV2('pcb_size_x')}
                        width="150px"
                        type="number"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="PCB Size Y"
                        placeholder="XXX"
                        defaultValue={pcbRef?.product_information.pcb_size_y}
                        callBack={() => handleInputChangeV2('pcb_size_y')}
                        width="150px"
                        type="number"
                      />
                    </SelectWrapper>
                  </>
                )}
                {delivery.value === 'panel' && (
                  <>
                    <SelectWrapper>
                      <SelectedTitle>XOuts</SelectedTitle>
                      <CustomSelect
                        placeholder="XOuts"
                        value={xOuts}
                        options={yesNoOption}
                        onChange={(e) => {
                          handleSelectChangeV2('x_outs', e, setXOuts);
                        }}
                        isMulti={false}
                        isSearchable={true}
                        styles={getSelectStyles(null, '150px', '50px')}
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <SelectedTitle>Multiple Design</SelectedTitle>
                      <CustomSelect
                        placeholder="Multiple Design"
                        value={multipleDesign}
                        options={yesNoOption}
                        onChange={(e) => {
                          handleSelectChangeV2('multiple_design', e, setMultipleDesign);
                        }}
                        isMulti={false}
                        isSearchable={true}
                        styles={getSelectStyles(null, '150px', '50px')}
                      />
                    </SelectWrapper>
                  </>
                )}
                {delivery.value === 'panel' && multipleDesign.value === 'yes' && (
                  <>
                    <SelectWrapper>
                      <CustomInput
                        title="Panel size X"
                        placeholder="X"
                        type="number"
                        defaultValue={pcbRef?.product_information?.array_size_x}
                        callBack={() => handleInputChangeV2('array_size_x')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Panel size Y"
                        placeholder="Y"
                        type="number"
                        defaultValue={pcbRef?.product_information?.array_size_y}
                        callBack={() => handleInputChangeV2('array_size_y')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Number of designs"
                        placeholder="0"
                        type="number"
                        defaultValue={pcbRef?.product_information?.multi_design_nb_designs}
                        callBack={() => handleInputChangeV2('multi_design_nb_designs')}
                        width="150px"
                      />
                    </SelectWrapper>
                  </>
                )}
              </>
            </GridModalContent>
            {delivery.value === 'panel' && multipleDesign.value === 'no' && (
              <PCBGeneratorContainer>
                <PCBGenerator
                  circuitsX={pcbRef?.product_information.pcb_quantity_x}
                  circuitsY={pcbRef?.product_information.pcb_quantity_y}
                  outerWidth={pcbRef?.product_information.pcb_size_x}
                  outerHeight={pcbRef?.product_information.pcb_size_y}
                  outerMarginLeft={pcbRef?.product_information?.space_border_left}
                  outerMarginBottom={pcbRef?.product_information?.space_border_bottom}
                  outerMarginRight={pcbRef?.product_information?.space_border_right}
                  outerMarginTop={pcbRef?.product_information?.space_border_top}
                  innerMarginX={pcbRef?.product_information?.space_between_pcb_x}
                  innerMarginY={pcbRef?.product_information?.space_between_pcb_y}
                  pcbSeparationX={pcbRef?.product_information.pcb_separation_x}
                  pcbSeparationY={pcbRef?.product_information.pcb_separation_y}
                />
                <PCBGeneratorContainer>
                  <Column>
                    <SelectWrapper>
                      <CustomInput
                        title="PCB Size X"
                        placeholder="XXX"
                        defaultValue={pcbRef?.product_information.pcb_size_x}
                        callBack={() => handleInputChangeV2('pcb_size_x')}
                        width="150px"
                        type="number"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="PCB Qty X"
                        placeholder="X dm2"
                        type="number"
                        defaultValue={pcbRef?.product_information.pcb_quantity_x}
                        callBack={() => handleInputChangeV2('pcb_quantity_x')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <SelectedTitle>PCB Separation X</SelectedTitle>
                      <CustomSelect
                        placeholder="Scoring"
                        value={pcbSeparationX}
                        options={pcbSeparationOption}
                        onChange={(e) => {
                          handleSelectChangeV2('pcb_separation_x', e, setPcbSeparationX);
                        }}
                        isMulti={false}
                        isSearchable={true}
                        styles={getSelectStyles(null, '150px', '50px')}
                      />
                    </SelectWrapper>
                    {(pcbRef?.product_information.pcb_separation_x === PCBSeparation.Milling ||
                      pcbRef?.product_information.pcb_separation_x ===
                        PCBSeparation.MillingScoring) && (
                      <SelectWrapper>
                        <CustomInput
                          title="Milling X"
                          placeholder="X dm2"
                          type="number"
                          defaultValue={pcbRef?.product_information.pcb_separation_length_x}
                          callBack={() => handleInputChangeV2('pcb_separation_length_x')}
                          width="150px"
                          isDisabled={true}
                        />
                      </SelectWrapper>
                    )}
                    <SelectWrapper>
                      <CustomInput
                        title="Space between pcb X"
                        placeholder="0"
                        type="number"
                        defaultValue={pcbRef?.product_information?.space_between_pcb_x}
                        callBack={() => handleInputChangeV2('space_between_pcb_x')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Border left"
                        placeholder="0"
                        type="number"
                        defaultValue={pcbRef?.product_information?.space_border_left}
                        callBack={() => handleInputChangeV2('space_border_left')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Border right"
                        placeholder="0"
                        type="number"
                        defaultValue={pcbRef?.product_information?.space_border_right}
                        callBack={() => handleInputChangeV2('space_border_right')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Panel size X"
                        placeholder="X"
                        type="number"
                        defaultValue={pcbRef?.product_information?.array_size_x}
                        callBack={() => handleInputChangeV2('array_size_x')}
                        width="150px"
                        isDisabled={true}
                      />
                    </SelectWrapper>
                    <WrapperUpdatedButton>
                      <Button
                        variant={ButtonVariant.PRIMARY}
                        sizing={ButtonSizing.SMALL}
                        text="Update"
                        onClick={handleSaveButtonClick}
                      />
                    </WrapperUpdatedButton>
                  </Column>
                  <Column>
                    <SelectWrapper>
                      <CustomInput
                        title="PCB Size Y"
                        placeholder="XXX"
                        defaultValue={pcbRef?.product_information.pcb_size_y}
                        callBack={() => handleInputChangeV2('pcb_size_y')}
                        width="150px"
                        type="number"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="PCB Qty Y"
                        placeholder="X dm2"
                        type="number"
                        defaultValue={pcbRef?.product_information.pcb_quantity_y}
                        callBack={() => handleInputChangeV2('pcb_quantity_y')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <SelectedTitle>PCB Separation Y</SelectedTitle>
                      <CustomSelect
                        placeholder="Scoring"
                        value={pcbSeparationY}
                        options={pcbSeparationOption}
                        onChange={(e) => {
                          handleSelectChangeV2('pcb_separation_y', e, setPcbSeparationY);
                        }}
                        isMulti={false}
                        isSearchable={true}
                        styles={getSelectStyles(null, '150px', '50px')}
                      />
                    </SelectWrapper>
                    {(pcbRef?.product_information.pcb_separation_y === PCBSeparation.Milling ||
                      pcbRef?.product_information.pcb_separation_y ===
                        PCBSeparation.MillingScoring) && (
                      <SelectWrapper>
                        <CustomInput
                          title="Milling Y"
                          placeholder="X dm2"
                          type="number"
                          defaultValue={pcbRef?.product_information.pcb_separation_length_y}
                          callBack={() => handleInputChangeV2('pcb_separation_length_y')}
                          width="150px"
                          isDisabled={true}
                        />
                      </SelectWrapper>
                    )}
                    <SelectWrapper>
                      <CustomInput
                        title="Space between pcb Y"
                        placeholder="0"
                        type="number"
                        defaultValue={pcbRef?.product_information?.space_between_pcb_y}
                        callBack={() => handleInputChangeV2('space_between_pcb_y')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Border top"
                        placeholder="0"
                        type="number"
                        defaultValue={pcbRef?.product_information?.space_border_top}
                        callBack={() => handleInputChangeV2('space_border_top')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Border bottom"
                        placeholder="0"
                        type="number"
                        defaultValue={pcbRef?.product_information?.space_border_bottom}
                        callBack={() => handleInputChangeV2('space_border_bottom')}
                        width="150px"
                      />
                    </SelectWrapper>
                    <SelectWrapper>
                      <CustomInput
                        title="Panel size Y"
                        placeholder="Y"
                        type="number"
                        defaultValue={pcbRef?.product_information?.array_size_y}
                        callBack={() => handleInputChangeV2('array_size_y')}
                        width="150px"
                        isDisabled={true}
                      />
                    </SelectWrapper>
                  </Column>
                </PCBGeneratorContainer>
              </PCBGeneratorContainer>
            )}
          </ContentModalContainer>
        </ModalSidebar>
      )}
      {isMaterialModal && (
        <ModalSidebar
          isOpen={isMaterialModal}
          onClose={handleCloseMaterialModal}
          title="Stack up and Surface treatment Section"
          footer={
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Back"
              onClick={handleCloseMaterialModal}
            />
          }
        >
          <>
            <GridModalContent>
              <SelectWrapper>
                <SelectedTitle>Base Material</SelectedTitle>
                <CustomSelect
                  placeholder="Base Material"
                  value={baseMaterial}
                  options={materialOption}
                  onChange={(e) => {
                    handleSelectChangeV2('base_material', e, setBaseMaterial);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Cu Layers</SelectedTitle>
                <CustomSelect
                  placeholder="Cu Layers"
                  value={cuLayers}
                  options={cuLayersOption}
                  onChange={(e) => {
                    handleSelectChangeV2('cut_layers', e, setCuLayers);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>PCB Thickness</SelectedTitle>
                <CustomSelect
                  placeholder="PCB Thickness"
                  value={pcbThickness}
                  options={pcbThicknessOption}
                  onChange={(e) => {
                    handleSelectChangeV2('pcb_thickness', e, setPcbThickness);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Final Cu Outer</SelectedTitle>
                <CustomSelect
                  placeholder="Final Cu Outer"
                  value={finalCuOuter}
                  options={finalCuOption}
                  onChange={(e) => {
                    handleSelectChangeV2('final_cu_outer', e, setFinalCuOuter);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              {pcbRef.product_information.cut_layers < 1 && (
                <SelectWrapper>
                  <SelectedTitle>Final Cu Inner</SelectedTitle>
                  <CustomSelect
                    placeholder="Final Cu Inner"
                    value={finalCuInner}
                    options={finalCuOption}
                    onChange={(e) => {
                      handleSelectChangeV2('final_cu_inner', e, setFinalCuInner);
                    }}
                    isMulti={false}
                    isSearchable={true}
                    styles={getSelectStyles(null, '150px', '50px')}
                  />
                </SelectWrapper>
              )}
              <SelectWrapper>
                <SelectedTitle>Surface Finish</SelectedTitle>
                <CustomSelect
                  placeholder="Surface Finish"
                  value={surfaceFinish}
                  options={surfaceFinishOption}
                  onChange={(e) => {
                    handleSelectChangeV2('surface_finish', e, setSurfaceFinish);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
            </GridModalContent>
          </>
        </ModalSidebar>
      )}
      {isMaskModal && (
        <ModalSidebar
          isOpen={isMaskModal}
          onClose={handleCloseMaskModal}
          title="Mask Section"
          footer={
            <Button variant={ButtonVariant.PRIMARY} text="Back" onClick={handleCloseMaskModal} />
          }
        >
          <>
            <GridModalContent>
              <SelectWrapper>
                <SelectedTitle>Solder Mask</SelectedTitle>
                <CustomSelect
                  placeholder="Solder Mask"
                  value={solderMask}
                  options={solderMaskAndLegendOption}
                  onChange={(e) => {
                    handleSelectChangeV2('solder_mask', e, setSolderMask);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Mask Color</SelectedTitle>
                <CustomSelect
                  placeholder="Mask Color"
                  value={maskColor}
                  options={maskColorOption}
                  onChange={(e) => {
                    handleSelectChangeV2('mask_color', e, setMaskColor);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <CustomInput
                  title="Mask Type"
                  placeholder="Mask Type"
                  defaultValue={pcbRef?.product_information.mask_type}
                  callBack={() => handleInputChangeV2('mask_type')}
                  width="150px"
                />
              </SelectWrapper>
            </GridModalContent>
          </>
        </ModalSidebar>
      )}
      {isSilkScreenModal && (
        <ModalSidebar
          isOpen={isSilkScreenModal}
          onClose={handleCloseSilkScreenModal}
          title="Silkscreen Section"
          footer={
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Back"
              onClick={handleCloseSilkScreenModal}
            />
          }
        >
          <>
            <GridModalContent>
              <SelectWrapper>
                <SelectedTitle>Legend</SelectedTitle>
                <CustomSelect
                  placeholder="Legend"
                  value={legend}
                  options={solderMaskAndLegendOption}
                  onChange={(e) => {
                    handleSelectChangeV2('legend', e, setLegend);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Legend Color</SelectedTitle>
                <CustomSelect
                  placeholder="Legend Color"
                  value={legendColor}
                  options={legendColorOption}
                  onChange={(e) => {
                    handleSelectChangeV2('legend_color', e, setLegendColor);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
            </GridModalContent>
          </>
        </ModalSidebar>
      )}
      {isHomologationModal && (
        <ModalSidebar
          isOpen={isHomologationModal}
          onClose={handleCloseHomologationModal}
          title="Homologation Section"
          footer={
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Back"
              onClick={handleCloseHomologationModal}
            />
          }
        >
          <>
            <GridModalContent>
              <SelectWrapper>
                <SelectedTitle>IPC Class</SelectedTitle>
                <CustomSelect
                  placeholder="IPC Class"
                  value={ipcClass}
                  options={IPCClassOption}
                  onChange={(e) => {
                    handleSelectChangeV2('ipc_class', e, setIpcClass);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
            </GridModalContent>
          </>
        </ModalSidebar>
      )}
      {isLogoModal && (
        <ModalSidebar
          isOpen={isLogoModal}
          onClose={handleCloseLogoModal}
          title="Logo Section"
          footer={
            <Button variant={ButtonVariant.PRIMARY} text="Back" onClick={handleCloseLogoModal} />
          }
        >
          <>
            <GridModalContent>
              <SelectWrapper>
                <SelectedTitle>RoHS</SelectedTitle>
                <CustomSelect
                  placeholder="RoHS"
                  value={rohs}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('rohs', e, setRohs);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>

              <SelectWrapper>
                <SelectedTitle>Etest</SelectedTitle>
                <CustomSelect
                  placeholder="Etest"
                  value={etest}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('etest', e, setEtest);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Date Code</SelectedTitle>
                <CustomSelect
                  placeholder="Date Code"
                  value={dateCode}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('date_code', e, setDateCode);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Ul Logo</SelectedTitle>
                <CustomSelect
                  placeholder="Ul Logo"
                  value={ulLogo}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('ul_logo', e, setUlLogo);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Pcb Elect Logo</SelectedTitle>
                <CustomSelect
                  placeholder="Pcb Elect Logo"
                  value={pcbElectronicsLogo}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('pcb_electronics_logo', e, setPcbElectronicsLogo);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Batch Number</SelectedTitle>
                <CustomSelect
                  placeholder="Batch Number"
                  value={batchNumber}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('batch_number', e, setBatchNumber);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <CustomInput
                title="Customized Text"
                placeholder=""
                defaultValue={pcbRef?.product_information.customized_text}
                callBack={() => handleInputChangeV2('customized_text')}
                width="150px"
              />
              <SelectWrapper>
                <SelectedTitle>Layers Choice</SelectedTitle>
                <CustomSelect
                  placeholder="layers choice"
                  value={layersChoice}
                  options={layerChoiseOption}
                  onChange={(e) => {
                    handleSelectChangeV2('layers_choice', e, setLayersChoice);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
            </GridModalContent>
          </>
        </ModalSidebar>
      )}
      {isAdditionalModal && (
        <ModalSidebar
          isOpen={isAdditionalModal}
          onClose={handleCloseAdditionalModal}
          title="Additional Section"
          footer={
            <Button
              variant={ButtonVariant.PRIMARY}
              text="Back"
              onClick={handleCloseAdditionalModal}
            />
          }
        >
          <>
            <GridModalContent>
              <SelectWrapper>
                <SelectedTitle>Via Masking</SelectedTitle>
                <CustomSelect
                  placeholder="Via Masking"
                  value={viaMasking}
                  options={viaMaskingOption}
                  onChange={(e) => {
                    handleSelectChangeV2('via_masking', e, setViaMasking);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Via Resign Plug</SelectedTitle>
                <CustomSelect
                  placeholder="Via Resign Plug"
                  value={resignPlug}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('via_resign_plug', e, setResignPlug);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Min trace / space</SelectedTitle>
                <CustomSelect
                  placeholder="Min trace / space"
                  value={minSpace}
                  options={minSpaceOption}
                  onChange={(e) => {
                    handleSelectChangeV2('min_space', e, setMinSpace);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Min Drill Size</SelectedTitle>
                <CustomSelect
                  placeholder="Min Drill Size"
                  value={drillSize}
                  options={drillSizeOption}
                  onChange={(e) => {
                    handleSelectChangeV2('min_drill_size', e, setDrillSize);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Edge Plating</SelectedTitle>
                <CustomSelect
                  placeholder="Edge Plating"
                  value={edgePlating}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('edge_plating', e, setEdgePlating);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <CustomInput
                  title="Number of Edges"
                  placeholder="Number of Edges"
                  defaultValue={pcbRef?.product_information.number_of_edges}
                  type="number"
                  callBack={() => handleInputChangeV2('number_of_edges')}
                  width="150px"
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Imped Ctrl</SelectedTitle>
                <CustomSelect
                  placeholder="Imped Ctrl"
                  value={impedControl}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('imped_ctrl', e, setImpedControl);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Carbon Print</SelectedTitle>
                <CustomSelect
                  placeholder="Carbon Print"
                  value={carbonPrint}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('carbon_print', e, setCarbonPrint);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Coil track board</SelectedTitle>
                <CustomSelect
                  placeholder="Coil track board"
                  value={collTrackBoard}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('coll_track_board', e, setCollTrackBoard);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Stackup</SelectedTitle>
                <CustomSelect
                  placeholder="Stackup"
                  value={stackup}
                  options={stackupOption}
                  onChange={(e) => {
                    handleSelectChangeV2('stackup', e, setStackup);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <CustomInput
                  title="Non Std Stackup Core"
                  placeholder="Non Std Stackup Core"
                  defaultValue={pcbRef?.product_information.non_std_stackup_core}
                  callBack={() => handleInputChangeV2('non_std_stackup_core')}
                  width="150px"
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Press Fit</SelectedTitle>
                <CustomSelect
                  placeholder="Press Fit"
                  value={pressFit}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('press_fit', e, setPressFit);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Peelable Mask</SelectedTitle>
                <CustomSelect
                  placeholder="Peelable Mask"
                  value={peelableMask}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('peelable_mask', e, setPeelableMask);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Abnormal Hole</SelectedTitle>
                <CustomSelect
                  placeholder="Abnormal Hole"
                  value={abnormalHole}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('abnormal_hole', e, setAbnormalHole);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Z Routing</SelectedTitle>
                <CustomSelect
                  placeholder="Z Routing"
                  value={zRouting}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('z_routing', e, setZRouting);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Gold Finger Thickness</SelectedTitle>
                <CustomSelect
                  placeholder="Gold Finger Thickness"
                  value={goldFinger}
                  options={goldFingerThicknessOption}
                  onChange={(e) => {
                    handleSelectChangeV2('gold_finger_thickness', e, setGoldFinger);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Gold Finger Quantity</SelectedTitle>
                <CustomSelect
                  placeholder="Gold Finger Qts"
                  value={goldFingerQuantity}
                  options={goldFingerOption}
                  onChange={(e) => {
                    handleSelectChangeV2('gold_finger_quantity', e, setGoldFingerQuantity);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Counter sink</SelectedTitle>
                <CustomSelect
                  placeholder="Counter sink"
                  value={counterSunk}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('counter_sunk', e, setCounterSunk);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              {pcbRef?.product_information.counter_sunk === 'yes' && (
                <SelectWrapper>
                  <CustomInput
                    title="Sink Quantity"
                    placeholder="Sink Quantity"
                    type="number"
                    defaultValue={pcbRef?.product_information.counter_sunk_quantity}
                    callBack={() => handleInputChangeV2('counter_sunk_quantity')}
                    width="150px"
                  />
                </SelectWrapper>
              )}
              <SelectWrapper>
                <SelectedTitle>Slots</SelectedTitle>
                <CustomSelect
                  placeholder="Slots"
                  value={slots}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('slots', e, setSlots);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>HDI</SelectedTitle>
                <CustomSelect
                  placeholder="HDI"
                  value={hdi}
                  options={hdiOption}
                  onChange={(e) => {
                    handleSelectChangeV2('hdi', e, setHdi);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Print Serial Number</SelectedTitle>
                <CustomSelect
                  placeholder="Print Serial Number"
                  value={printSerialNumber}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('print_serial_number', e, setPrintSerialNumber);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>

              <SelectWrapper>
                <SelectedTitle>Micro Sec Report</SelectedTitle>
                <CustomSelect
                  placeholder="Micro Sec Report"
                  value={microSecReport}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('micro_sec_report', e, setMicroSecReport);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Impedance coupons</SelectedTitle>
                <CustomSelect
                  placeholder="Impedance coupons"
                  value={coupons}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('coupons', e, setCoupons);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
              <SelectWrapper>
                <SelectedTitle>Solder Sample</SelectedTitle>
                <CustomSelect
                  placeholder="Solder Sample"
                  value={solderSample}
                  options={yesNoOption}
                  onChange={(e) => {
                    handleSelectChangeV2('solder_sample', e, setSolderSample);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '150px', '50px')}
                />
              </SelectWrapper>
            </GridModalContent>
          </>
        </ModalSidebar>
      )}
    </>
  );
};

const PCBReference: React.FC<
  PCBProps & {
    handleOpenPcbModal: (modalName: string) => void;
  }
> = ({ item, isDisabled, setDetails, handleOpenPcbModal }) => {
  const [pcbRef, setPcbRef] = useState<any>({ ...item });

  useEffect(() => {
    setPcbRef(item);
  }, [item]);

  const handleInputChangeV2 = (name: string) => (value: string | number) => {
    const newProductInformation = {
      ...pcbRef.product_information,
      [name]: value
    };

    const newItemRendered: ItemRendered = {
      ...pcbRef,
      product_information: newProductInformation
    };
    setPcbRef(newItemRendered);
    setDetails(newItemRendered);
  };

  const handleOpenPanelModal = () => {
    if (isDisabled) {
      return;
    }
    handleOpenPcbModal('isPanelModal');
  };

  const handleOpenMaterialModal = () => {
    if (isDisabled) {
      return;
    }
    handleOpenPcbModal('isMaterialModal');
  };

  const handleOpenMaskModal = () => {
    if (isDisabled) {
      return;
    }
    handleOpenPcbModal('isMaskModal');
  };

  const handleOpenSilkScreenModal = () => {
    if (isDisabled) {
      return;
    }
    handleOpenPcbModal('isSilkScreenModal');
  };

  const handleOpenAdditionalModal = () => {
    if (isDisabled) {
      return;
    }
    handleOpenPcbModal('isAdditionalModal');
  };

  const handleOpenHomologationModal = () => {
    if (isDisabled) {
      return;
    }
    handleOpenPcbModal('isHomologationModal');
  };

  const handleOpenLogoModal = () => {
    if (isDisabled) {
      return;
    }
    handleOpenPcbModal('isLogoModal');
  };

  return (
    <ClickableZoneWrapper>
      <ClickablePanelZone
        pcbRef={item}
        handleOpenModal={handleOpenPanelModal}
        isDisabled={isDisabled}
      />
      <ClickableMaterialZone
        pcbRef={item}
        handleOpenModal={handleOpenMaterialModal}
        isDisabled={isDisabled}
      />
      <ClickableMaskZone
        pcbRef={item}
        handleOpenModal={handleOpenMaskModal}
        isDisabled={isDisabled}
      />
      <ClickableSilkScreenZone
        pcbRef={item}
        handleOpenModal={handleOpenSilkScreenModal}
        isDisabled={isDisabled}
      />
      <ClickableHomologationZone
        pcbRef={item}
        handleOpenModal={handleOpenHomologationModal}
        isDisabled={isDisabled}
      />
      <ClickableAdditionalZone
        pcbRef={item}
        handleOpenModal={handleOpenAdditionalModal}
        isDisabled={isDisabled}
      />
      <ClickableLogoZone
        pcbRef={item}
        handleOpenModal={handleOpenLogoModal}
        isDisabled={isDisabled}
      />
      <CustomTextArea
        title="Additionnal comment"
        value={item?.product_information?.additional_comment}
        placeholder="Additionnal comment"
        callBack={() => handleInputChangeV2('additional_comment')}
        isDisabled={isDisabled}
      />
    </ClickableZoneWrapper>
  );
};

type QuotationReferenceModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  item: QuotationItem | OrderItem;
  callback?: (newQuotationItem: QuotationItem) => void;
};

const QuotationReferenceModal: React.FC<QuotationReferenceModalProps> = ({
  isDisplayed,
  onClose,
  item,
  callback
}) => {
  const [isPanelModalDisplayed, setIsPanelModal] = useState<boolean>(false);
  const [isMaterialModalDisplayed, setIsMaterialModal] = useState<boolean>(false);
  const [isMaskModalDisplayed, setIsMaskModal] = useState<boolean>(false);
  const [isSilkScreenModalDisplayed, setIsSilkScreenModal] = useState<boolean>(false);
  const [isAdditionalModalDisplayed, setIsAdditionalModal] = useState<boolean>(false);
  const [isHomologationModalDisplayed, setIsHomologationModal] = useState<boolean>(false);
  const [isLogoModalDisplayed, setIsLogoModal] = useState<boolean>(false);
  const [files, setFiles] = useState<GerberFile[]>(item.files);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [details, setDetails] = useState<any>();
  const [productName, setProductName] = useState<string>();
  const { patch, get, post } = useApi();

  useEffect(() => {
    setIsLoading(true);
    if (item.item) {
      (async () => {
        const itemDetail = await get(getItem(item.item));
        setDetails(itemDetail.data);
        setProductName(itemDetail.data.product_name);
        setIsLoading(false);
      })();
    } else {
      setDetails(null);
      setIsLoading(false);
    }
  }, [item]);

  const handleSaveButtonClick = async (closed: boolean) => {
    if (details?.internal_reference) {
      if (callback) {
        callback({
          ...item,
          item: details.id,
          item_rendered: {
            ...details.item_rendered
          }
        });
      }
    } else {
      const newItem = await patch(patchItem(details.id), details);
      if (callback) {
        callback({
          ...item,
          item: newItem.data.id,
          item_rendered: {
            ...item.item_rendered,
            ...newItem.data.item_rendered
          }
        });
      }
      setDetails(newItem.data);
    }

    if (closed) {
      onClose();
    }
  };

  const handleInputChangeV2 = (name: string) => async (value: string | number) => {
    setDetails({
      ...details,
      product_information: { ...details.product_information, [name]: value }
    });
  };

  useEffect(() => {
    setFiles(item.files);
  }, [item]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const customerFiles = event.target.files;
    if (customerFiles && customerFiles.length > 0) {
      const file = customerFiles[0];
      const formData = new FormData();
      formData.append('file', file);
      const newGerber = await post(
        postUploadGerFile(
          item.company,
          item.contact,
          item.quotation,
          item.quotation_version,
          item.id
        ),
        formData
      );
      const newFiles = [...files];
      newFiles.push(newGerber.data);
      setFiles(newFiles);
      handleSaveButtonClick(false);
    }
  };

  const getFileName = (file: GerberFile) => {
    return file.file.split('/').reverse()[0];
  };

  const getGerberFileDocument = async (file: GerberFile) => {
    const response = await get(
      getGerFile(
        item.company,
        item.contact,
        item.quotation,
        item.quotation_version,
        item.id,
        file.id
      ),
      'blob'
    );

    const blob = new Blob([response.data], { type: 'application/zip' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', getFileName(file));
    document.body.appendChild(link);

    link.click();
    window.URL.revokeObjectURL(url);
  };

  const handleDuplicateFromExistingItemButtonClick = async () => {
    const newItem = await post(postDuplicateExistingItem(details.id), {});
    setDetails(newItem.data);
  };

  const handleOpenPcbModal = (modalName: string) => {
    if (modalName === 'isPanelModal') setIsPanelModal(true);
    else if (modalName === 'isMaterialModal') setIsMaterialModal(true);
    else if (modalName === 'isMaskModal') setIsMaskModal(true);
    else if (modalName === 'isSilkScreenModal') setIsSilkScreenModal(true);
    else if (modalName === 'isAdditionalModal') setIsAdditionalModal(true);
    else if (modalName === 'isHomologationModal') setIsHomologationModal(true);
    else if (modalName === 'isLogoModal') setIsLogoModal(true);
  };

  const handleClosePcbModal = (modalName: string) => {
    if (modalName === 'isPanelModal') setIsPanelModal(false);
    else if (modalName === 'isMaterialModal') setIsMaterialModal(false);
    else if (modalName === 'isMaskModal') setIsMaskModal(false);
    else if (modalName === 'isSilkScreenModal') setIsSilkScreenModal(false);
    else if (modalName === 'isAdditionalModal') setIsAdditionalModal(false);
    else if (modalName === 'isHomologationModal') setIsHomologationModal(false);
    else if (modalName === 'isLogoModal') setIsLogoModal(false);
  };

  const isEditable = !details?.internal_reference;

  return (
    <MainContainer>
      {isDisplayed && !isLoading && (
        <ModalSidebar
          isOpen={isDisplayed}
          onClose={onClose}
          title="Product parameters"
          footer={
            <>
              {isEditable && (
                <Button
                  variant={ButtonVariant.SECONDARY}
                  text={`Save`}
                  onClick={() => handleSaveButtonClick(true)}
                />
              )}
            </>
          }
        >
          <Content>
            <Row>
              <SelectWrapper>
                <SelectedTitle>Type</SelectedTitle>
                <CustomSelect
                  placeholder="Type"
                  value={findOption(productTypeOptions, productName)}
                  options={productTypeOptions}
                  isDisabled={!!details?.product_information?.reference}
                  styles={getSelectStyles(null, '200px', '50px')}
                  onChange={(e) => {
                    setProductName(e.value);
                  }}
                />
              </SelectWrapper>
              {details?.internal_reference || details?.reference ? (
                <SelectWrapper>
                  <CustomInput
                    placeholder="PCB-XXXX"
                    defaultValue={details?.product_information?.reference}
                    isDisabled={!!details?.internal_reference}
                    title="Reference"
                    callBack={() => handleInputChangeV2('reference')}
                  />
                </SelectWrapper>
              ) : (
                <ReferenceCustomSelect
                  quotationItem={item}
                  productName={productName}
                  setItem={setDetails}
                  handleSelectRef={callback}
                />
              )}
            </Row>
            {details?.internal_reference && (
              <Row>
                <SelectWrapper>
                  <CustomInput
                    placeholder="PCB-XXXX"
                    defaultValue={details?.internal_reference}
                    isDisabled={true}
                    title="Internal reference"
                  />
                </SelectWrapper>
                <Button
                  variant={ButtonVariant.SECONDARY}
                  text="Duplicate from existing item"
                  sizing={ButtonSizing.SMALL}
                  onClick={handleDuplicateFromExistingItemButtonClick}
                />
              </Row>
            )}
            {details && (
              <>
                <GerberFileUploaded>
                  {files?.map((file) => (
                    <Button
                      key={file.id}
                      text={getFileName(file)}
                      variant={ButtonVariant.PRIMARY_ELECTRIC}
                      sizing={ButtonSizing.SMALL}
                      onClick={() => getGerberFileDocument(file)}
                    />
                  ))}
                </GerberFileUploaded>
                {isEditable && (
                  <GerberFileContainer>
                    <>
                      Upload Gerber file <b>(zip or pdf only)</b>
                    </>
                    <LabelFile htmlFor={`inputTag-${item.id}`}>
                      Upload <Download />
                      <InputFile
                        id={`inputTag-${item.id}`}
                        type="file"
                        onChange={handleFileChange}
                        accept=".zip, .pdf"
                      />
                    </LabelFile>
                  </GerberFileContainer>
                )}
                {details.product_name === 'pcb' && (
                  <PCBReference
                    item={details}
                    setDetails={setDetails}
                    handleOpenPcbModal={handleOpenPcbModal}
                    isDisabled={!isEditable}
                  />
                )}
                {details.product_name === 'study' && (
                  <PCBStudy item={details} setDetails={setDetails} isDisabled={!isEditable} />
                )}
                {details.product_name === 'cao' && (
                  <PCBCao item={details} setDetails={setDetails} isDisabled={!isEditable} />
                )}
                {details.product_name === 'wiring' && (
                  <PCBWiring item={details} setDetails={setDetails} isDisabled={!isEditable} />
                )}
                {details.product_name === 'stencil' && (
                  <PCBStencil item={details} setDetails={setDetails} isDisabled={!isEditable} />
                )}
                {details.product_name === 'component' && (
                  <PCBComponent item={details} setDetails={setDetails} isDisabled={!isEditable} />
                )}
                {details.product_name === 'mechanic' && (
                  <PCBMechanic item={details} setDetails={setDetails} isDisabled={!isEditable} />
                )}
              </>
            )}
          </Content>
        </ModalSidebar>
      )}
      {isDisplayed && !isLoading && details && details.product_name === 'pcb' && (
        <PCBReferenceModal
          isDisabled={!isEditable}
          item={details}
          handleSaveButtonClick={() => handleSaveButtonClick(false)}
          setDetails={setDetails}
          handleClosePcbModal={handleClosePcbModal}
          isPanelModalDisplayed={isPanelModalDisplayed}
          isMaterialModalDisplayed={isMaterialModalDisplayed}
          isMaskModalDisplayed={isMaskModalDisplayed}
          isSilkScreenModalDisplayed={isSilkScreenModalDisplayed}
          isAdditionalModalDisplayed={isAdditionalModalDisplayed}
          isHomologationModalDisplayed={isHomologationModalDisplayed}
          isLogoModalDisplayed={isLogoModalDisplayed}
        />
      )}
    </MainContainer>
  );
};

export default QuotationReferenceModal;
