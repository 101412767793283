import React from 'react';
import styled from 'styled-components';

import { Checkbox } from 'components/common';

enum SortingState {
  Ascendant = 'ascendant',
  Descendant = 'descendant',
  Neutral = 'neutral'
}

const StyledTh = styled.th<{ isSorted: boolean }>`
  cursor: pointer;
  padding: ${(props) => props.theme.spacing.xs}px;
  gap: ${(props) => props.theme.spacing.xxxs}px;
  text-align: start;
  background: #f9fafc;
  border-bottom: 1px solid ${(props) => props.theme.colors.primary.lighter};
  border-top: 1px solid ${(props) => props.theme.colors.grey.dark};

  font-size: 16px;
  line-height: 1.5;

  ${(props) =>
    props.isSorted &&
    `
    color: ${props.theme.colors.primary.main};
  `}
`;

type Column = {
  name: string;
  property: string;
};

type HeaderProps = {
  isSelected: boolean;
  setIsSelected: () => void;
  template: Column[];
  checkboxDisplayed: boolean;
  sorted: boolean;
  sortingState: ColumnSortingState;
  setSortingState: React.Dispatch<React.SetStateAction<ColumnSortingState>>;
};

const Header: React.FC<HeaderProps> = ({
  isSelected,
  setIsSelected,
  template,
  checkboxDisplayed,
  sorted,
  sortingState,
  setSortingState
}) => {
  const toggleColumnSorting = (property: string) => {
    setSortingState((prev) => {
      const currentSorting = prev[property];

      let nextSorting: SortingState;
      switch (currentSorting) {
        case SortingState.Descendant:
          nextSorting = SortingState.Ascendant;
          break;
        case SortingState.Ascendant:
          nextSorting = SortingState.Neutral;
          break;
        case SortingState.Neutral:
        default:
          nextSorting = SortingState.Descendant;
          break;
      }

      return {
        ...prev,
        [property]: nextSorting
      };
    });
  };

  return (
    <thead>
      <tr>
        {checkboxDisplayed && (
          <StyledTh isSorted={false}>
            <Checkbox isSelected={isSelected} onClick={setIsSelected} />
          </StyledTh>
        )}
        {template.map((column, index) => {
          const isSorted =
            sortingState[column.property] !== SortingState.Neutral &&
            sortingState[column.property] &&
            sorted;
          return (
            <StyledTh
              key={`header-${index}`}
              isSorted={isSorted}
              onClick={() => toggleColumnSorting(column.property)}
            >
              {column.name}
              {isSorted && (
                <>
                  {sortingState[column.property] === SortingState.Ascendant && ' ⬆'}
                  {sortingState[column.property] === SortingState.Descendant && ' ⬇'}
                </>
              )}
            </StyledTh>
          );
        })}
      </tr>
    </thead>
  );
};

export default Header;
