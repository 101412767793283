import { createContext } from 'react';

const initialState: Provider[] = [];

const reducer = (state: Provider[], action: ProvidersDispatchAction): Provider[] => {
  switch (action.type) {
    case 'UPDATE_ALL_DATA':
      return action.payload;
    case 'SET_ALL_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type ProvidersContextType = {
  state: Provider[];
  dispatch: React.Dispatch<ProvidersDispatchAction>;
};

const Context = createContext<ProvidersContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'AllProviders';

export { Context, initialState, reducer };
