import { createContext } from 'react';

const initialState: CompaniesContextState = {
  count: 0,
  next: null,
  previous: null,
  results: []
};

const reducer = (
  state: CompaniesContextState,
  action: CompaniesDispatchAction
): CompaniesContextState => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return action.payload;
    case 'SET_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type CompaniesContextType = {
  state: CompaniesContextState;
  dispatch: React.Dispatch<CompaniesDispatchAction>;
};

const Context = createContext<CompaniesContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'Companies';

export { Context, initialState, reducer };
