import { createContext } from 'react';

const initialState: SuccessContextState = {
  isSuccessModalDisplayed: false,
  text: null
};

const reducer = (
  state: SuccessContextState,
  action: SuccessDispatchAction
): SuccessContextState => {
  switch (action.type) {
    case 'SHOW_SUCCESS':
      return {
        isSuccessModalDisplayed: true,
        text: action.payload
      };
    case 'HIDE_SUCCESS':
      return {
        isSuccessModalDisplayed: false,
        text: null
      };
    default:
      throw new Error('unexpected action type');
  }
};

type SuccessContextType = {
  state: SuccessContextState;
  dispatch: React.Dispatch<SuccessDispatchAction>;
};

const Context = createContext<SuccessContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'Successs';

export { Context, initialState, reducer };
