import React from 'react';

const Copy = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.82688 5.74499V4.695C5.82688 4.24546 6.0045 3.81433 6.32067 3.49645C6.63684 3.17858 7.06565 3 7.51278 3H19.3141C19.3141 3 20.1499 3.13822 20.5062 3.49645C20.8625 3.85469 21 4.695 21 4.695V16.56C21 17.0095 20.8224 17.4407 20.5062 17.7586C20.19 18.0764 19.7612 18.255 19.3141 18.255H18.1731M5.82688 5.74499V16.56C5.82688 17.0095 6.0045 17.4407 6.32067 17.7586C6.63684 18.0764 7.06565 18.255 7.51278 18.255H18.1731M5.82688 5.74499H4.6859C4.23877 5.74499 3.80996 5.92357 3.49379 6.24145C3.17762 6.55932 3 6.99045 3 7.43999V19.305C3 19.7545 3.17762 20.1857 3.49379 20.5035C3.80996 20.8214 4.23877 21 4.6859 21H16.4872C16.9343 21 17.3632 20.8214 17.6793 20.5035C17.9955 20.1857 18.1731 19.7545 18.1731 19.305V18.255"
        stroke="#1A2128"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Copy;
