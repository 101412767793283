import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { defaultTheme, GlobalStyles } from './styles';
import PCBE from './PCBE';

const root = ReactDOM.createRoot(document.getElementById('pcbe'));

root.render(
  <ThemeProvider theme={defaultTheme}>
    <GlobalStyles />
    <BrowserRouter>
      <PCBE />
    </BrowserRouter>
  </ThemeProvider>
);
