import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useApi from 'services/axios';
import { postCustomerDeliveryNote } from 'services/endpoints';
import { SalesDeliveryNoteRecap } from 'components/array/template';
import { Array } from 'components/array';
import { ButtonVariant } from 'components/common/Button';

import { Button, CustomInput, ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Left = styled(Row)`
  justify-content: flex-end;
`;

type SalesDeliveryNoteProps = {
  isDisplayed: boolean;
  onClose: () => void;
  order: Partial<Order>;
};

const SalesDeliveryNote: React.FC<SalesDeliveryNoteProps> = ({ isDisplayed, onClose, order }) => {
  const [deliveryNote, setDeliveryNote] = useState<Partial<DeliveryNote>>();
  const { post } = useApi();

  const handleChange = (id: number) => (value: number) => {
    const new_customer_order_items: CustomerOrderItem[] = deliveryNote.customer_order_items.map(
      (item) => {
        if (item.id === id) {
          return { ...item, quantity: Number(value) };
        } else {
          return item;
        }
      }
    );

    setDeliveryNote({
      ...deliveryNote,
      customer_order_items: new_customer_order_items
    });
  };

  const handleCreateButtonClick = (item: Partial<DeliveryNote>) => async () => {
    const newDeliveryNote = await post(postCustomerDeliveryNote(), item);
    setDeliveryNote(newDeliveryNote.data);
    onClose();
  };

  const salesDeliveryNotePreprocessing = order?.order_items?.map((item) => {
    return {
      id: item.id,
      product_name: {
        value: item.item_rendered.product_name,
        display: <div>{item.item_rendered.product_name}</div>
      },
      reference: {
        value: item.item_rendered.product_information.reference,
        display: <div>{item.item_rendered.product_information.reference}</div>
      },
      total_delivered_quantity: {
        value: item.quantity,
        display: <div>{item.quantity}</div>
      },
      total_remaining_quantity: {
        value: item.remaining_items_to_deliver,
        display: <div>{item.remaining_items_to_deliver}</div>
      },
      quantity: {
        value: item.quantity,
        display: <div>{item.quantity}</div>
      },
      order_quantity: {
        value: null,
        display: (
          <CustomInput
            defaultValue="0"
            placeholder="0"
            callBack={() => handleChange(item.id)}
            width="70px"
          />
        )
      }
    };
  });

  useEffect(() => {
    if (order) {
      setDeliveryNote({
        company: order.company,
        customer_order_id: order.id,
        customer_order_items: order?.order_items?.map((orderItems) => {
          return {
            id: orderItems.id,
            quantity: 0
          };
        })
      });
    }
  }, [order]);

  return (
    <MainContainer>
      {isDisplayed && order && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title="Delivery note settings">
          <Content>
            <div>Select the products and quantities concerned by the delivery note</div>
            <Array
              template={SalesDeliveryNoteRecap}
              content={salesDeliveryNotePreprocessing}
              checkboxDisplayed={false}
              isSearchable={false}
            />
            <Left>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Create`}
                onClick={handleCreateButtonClick(deliveryNote)}
              />
            </Left>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default SalesDeliveryNote;
