import React, { useContext, useState } from 'react';
import styled from 'styled-components';

import { CustomInput } from 'components/common';
import { CrossCircle } from 'svgs';
import { patchPurchase } from 'services/endpoints';
import useApi from 'services/axios';
import { CurrencyContext } from 'contexts';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 24px 0;
  padding: 32px;
  gap: 10px;

  background: linear-gradient(248.85deg, #1975ff -1.77%, #20a1ff 104.39%);
  border-radius: 15px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: rgb(255, 255, 255);
  height: 50px;
  display: flex;
  align-items: center;
`;

type PurchaseRecapPriceProps = {
  purchase: Purchases;
  setPurchase: React.Dispatch<React.SetStateAction<Purchases>>;
};

const PurchaseRecapPrice: React.FC<PurchaseRecapPriceProps> = ({ purchase, setPurchase }) => {
  const { state: currencyState } = useContext(CurrencyContext.Context);
  const [downPaymentInputDisplayed, setDownPaymentInputDisplayed] = useState<boolean>(
    !!purchase?.down_payment
  );
  const { patch } = useApi();
  const getValue = (value: number) => (value ? value : 0);

  const handleClickToAddDownPaymentIcon = () => {
    setDownPaymentInputDisplayed(true);
  };

  const onChangeDownPayment = (name: string) => async (value: string | number) => {
    if (value) {
      patch(patchPurchase(purchase.id), {
        ...purchase,
        [name]: value
      });
      setPurchase({
        ...purchase,
        [name]: value
      });
    }
  };

  const usedCurrency = currencyState.results.find(
    (result) => purchase.provider_currency === result.name
  );

  return (
    <Container>
      <div>
        <Title>Total in {usedCurrency?.name}</Title>
        <Value>
          {usedCurrency?.symbol} {getValue(purchase?.total_buying_price).toFixed(2)}
        </Value>
      </div>
      <div>
        <Title>Down payment</Title>
        {downPaymentInputDisplayed ? (
          <Value>
            <CustomInput
              defaultValue={purchase?.down_payment?.toString()}
              callBack={() => onChangeDownPayment('down_payment')}
              placeholder="0"
              inputStyle={0}
              width="120px"
            />
          </Value>
        ) : (
          <Value onClick={handleClickToAddDownPaymentIcon}>
            <CrossCircle />
          </Value>
        )}
      </div>
      <div>
        <Title>Discount</Title>
        <Value>
          {usedCurrency?.symbol} {getValue(purchase?.discount).toFixed(2)}
        </Value>
      </div>
      <div>
        <Title>Net to pay</Title>
        <Value>
          {usedCurrency?.symbol} {getValue(purchase?.net_to_pay).toFixed(2)}
        </Value>
      </div>
    </Container>
  );
};

export default PurchaseRecapPrice;
