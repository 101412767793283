import React, { useState } from 'react';
import styled from 'styled-components';

import useApi from 'services/axios';
import { postLogIn } from 'services/endpoints';

import pcdConductor from '../assets/pcb-conductors.png';
import pcdElectronics from '../assets/pcb-electorinics.png';

import { setToken } from './Tools';
import { Button, CustomInput, InputStyle } from './common';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.common.white};
  padding: ${({ theme }) => theme.spacing.m}px;
  gap: ${({ theme }) => theme.spacing.xs}px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const ImageContainer = styled.div`
  flex: 1;
  background-image: url(${pcdConductor});
  background-size: cover;
  background-position: center;
`;

const Title = styled.h2`
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: ${({ theme }) => theme.spacing.s}px;
  color: ${({ theme }) => theme.colors.text.primary};
`;

const ErrorMsg = styled.div`
  color: red;
  font-size: 14px;
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.s}px;
`;

const LoginPage: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { post } = useApi();

  const handleLogin = async () => {
    try {
      const newToken = await post(postLogIn(), {
        username,
        password
      });
      const { access: accessToken } = newToken.data;
      setToken(accessToken);
      location.reload();
    } catch (error) {
      setErrorMessage('Invalid username or password. Please try again.');
    }
  };

  const handleChangeUsername = () => (value: string | number) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setUsername(value as string);
  };
  const handleChangePassword = () => (value: string | number) => {
    if (errorMessage) {
      setErrorMessage(null);
    }
    setPassword(value as string);
  };

  return (
    <Container>
      <FormContainer>
        <img src={pcdElectronics} alt="img"></img>
        <Title>Welcome to your PCB Electronics spaces</Title>
        <CustomInput
          type="username"
          title="Username"
          inputStyle={errorMessage ? InputStyle.ERROR : InputStyle.NORMAL}
          defaultValue={username}
          placeholder="your.username@gmail.com"
          callBack={handleChangeUsername}
          width="250px"
        />
        <CustomInput
          type="password"
          title="Password"
          inputStyle={errorMessage ? InputStyle.ERROR : InputStyle.NORMAL}
          defaultValue={password}
          placeholder="********"
          callBack={handleChangePassword}
          width="250px"
        />
        {errorMessage && <ErrorMsg>{errorMessage}</ErrorMsg>}
        <ButtonContainer>
          <Button text="Sign in" onClick={handleLogin} variant={1} sizing={1} />
        </ButtonContainer>
      </FormContainer>
      <ImageContainer />
    </Container>
  );
};

export default LoginPage;
