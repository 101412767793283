import { createContext } from 'react';

const initialState: QuotationsContextState = {
  count: 0,
  next: null,
  previous: null,
  results: []
};

const reducer = (
  state: QuotationsContextState,
  action: QuotationsDispatchAction
): QuotationsContextState => {
  switch (action.type) {
    case 'UPDATE_DATA':
      return action.payload;
    case 'SET_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type QuotationsContextType = {
  state: QuotationsContextState;
  dispatch: React.Dispatch<QuotationsDispatchAction>;
};

const Context = createContext<QuotationsContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'Quotations';

export { Context, initialState, reducer };
