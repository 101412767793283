import React from 'react';
import styled from 'styled-components';

import Button, { ButtonSizing, ButtonVariant } from 'components/common/Button';

import { Modal } from '../common';
import { MainContainer } from '../styledComponent';

const ButtonContainer = styled.div`
  display: flex;
  gap: 32px;
  padding: 16px;
  justify-content: center;
`;

type NewQuotationVersionModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  callback?: () => Promise<void>;
};

const NewQuotationVersionModal: React.FC<NewQuotationVersionModalProps> = ({
  isDisplayed,
  onClose,
  callback
}) => {
  const handleClickYesButton = () => {
    callback();
    onClose();
  };
  return (
    <MainContainer>
      {isDisplayed && (
        <Modal
          isOpen={isDisplayed}
          onClose={onClose}
          title={
            <p>
              You are sure about to create a new version of your quote. <br /> <br />
              This new version will be editable. <br />
              ⚠️ Do you wish to confirm? ⚠️
            </p>
          }
        >
          <ButtonContainer>
            <Button
              variant={ButtonVariant.PRIMARY}
              sizing={ButtonSizing.SMALL}
              text="No"
              onClick={onClose}
            />
            <Button
              variant={ButtonVariant.SECONDARY}
              sizing={ButtonSizing.SMALL}
              text="Yes"
              onClick={handleClickYesButton}
            />
          </ButtonContainer>
        </Modal>
      )}
    </MainContainer>
  );
};

export default NewQuotationVersionModal;
