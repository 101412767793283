import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AdminsContext, QuotationsContext } from 'contexts';
import { Copy, Cross, Download, Trash } from 'svgs';
import { Button, SmallLoader, Tag } from 'components/common';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import { TagColor } from 'components/common/Tag';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import { delQuotation, getQuotations } from 'services/endpoints';
import { DuplicateQuotationModal } from 'components/modal';

import { Array, salesQuotations } from '../array';
import { MainContainer, Title } from '../styledComponent';
import PictureTag from '../PictrureTag';
import { Overflow, capitalizeFirstLetter, getInitial, useCompaniesState } from '../Tools';

enum StatusType {
  DRAFT = 'draft',
  RFQ = 'rfq',
  READY = 'ready',
  SENT = 'sent',
  ACCEPTED = 'accepted',
  CLOSED = 'closed',
  LOST = 'lost',
  LOCKED = 'locked'
}

const getStatusColor = (status: string): TagColor => {
  switch (status) {
    case StatusType.DRAFT:
    case StatusType.RFQ:
      return TagColor.BLUE;

    case StatusType.READY:
    case StatusType.SENT:
      return TagColor.GREEN;

    case StatusType.ACCEPTED:
    case StatusType.CLOSED:
      return TagColor.DARKBLUE;

    case StatusType.LOST:
      return TagColor.RED;

    case StatusType.LOCKED:
      return TagColor.ORANGE;

    default:
      return TagColor.BLUE;
  }
};

const Quotations: React.FC = () => {
  const [selectedQuotations, setSelectedQuotations] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [duplicateQuotationModal, setDuplicateQuotationModal] = useState<boolean>(false);
  const { findCompanyById } = useCompaniesState();
  const { state: quotationsState, dispatch: quotationsAction } = useContext(
    QuotationsContext.Context
  );
  const { state: adminsState } = useContext(AdminsContext.Context);
  const navigate = useNavigate();
  const { del, get } = useApi();

  const goToQuotation = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const company = event.currentTarget.id.split('-')[2];
    const contact = event.currentTarget.id.split('-')[3];
    navigate(
      `/Sales-Quotations/Quotation?quotationId=${id}&companyId=${company}&contactId=${contact}&quotationView=updates`
    );
  };

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };
  const getCompanyName = (companyId: number) => findCompanyById(companyId)?.company_name;

  const quotationsPreProcessing = quotationsState.results.map((quotation) => ({
    id: `${quotation.id}-${quotation.company_id}-${quotation.contact_id}`,
    company_name: {
      value: getCompanyName(quotation.company_id),
      display: <div>{getCompanyName(quotation.company_id)}</div>
    },
    activities: {
      value: 'Quotation ' + quotation.reference,
      display: <BlueBoldTextContainer>{'Quotation ' + quotation.reference}</BlueBoldTextContainer>
    },
    status: {
      value: capitalizeFirstLetter(quotation.status),
      display: (
        <Tag
          text={capitalizeFirstLetter(quotation.status)}
          color={getStatusColor(quotation.status)}
        />
      )
    },
    date: {
      value: quotation.created_at,
      display: <div>{new Date(quotation.created_at).toLocaleDateString('fr-FR')}</div>
    },
    sales_person: {
      value: getAdminInital(quotation.sales_person),
      display: <PictureTag text={getAdminInital(quotation.sales_person)} />
    },
    admin: {
      value: getAdminInital(quotation.admin),
      display: <PictureTag text={getAdminInital(quotation.admin)} />
    },
    price: {
      value: quotation.total_price_with_tax.toFixed(2),
      display: <div>{quotation.total_price_with_tax.toFixed(2)}</div>
    }
  }));

  const createQuotation = () => {
    navigate(`/Sales-Quotations/Quotation?quotationView=create`);
  };

  const unSelectedQuotations = (id: number) => {
    setSelectedQuotations((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteQuotation = () => {
    selectedQuotations.map((QuotationId) => {
      const deletedQuotation = quotationsState.results.find(
        (quotation) => quotation.id === QuotationId
      );
      del(
        delQuotation(deletedQuotation.company_id, deletedQuotation.contact_id, deletedQuotation.id)
      );
      unSelectedQuotations(QuotationId);
    });
    const newQuotationsState = quotationsState.results.filter(
      (quotation) => !selectedQuotations.includes(quotation.id)
    );

    quotationsAction({
      type: 'UPDATE_DATA',
      payload: {
        ...quotationsState,
        count: quotationsState.count - 1,
        results: newQuotationsState
      }
    });
  };

  const updateCustomerQuotation = (newPage: any) => {
    quotationsAction({
      type: 'UPDATE_DATA',
      payload: newPage
    });
  };

  const handleDuplicateButtonClick = () => {
    setDuplicateQuotationModal(true);
  };

  const quotationMoreOptionToolTip: JSX.Element[] = [
    <Button
      key="moreOption-tooltip"
      variant={ButtonVariant.BLACK}
      text="Duplicate"
      leftIcon={Copy()}
      sizing={ButtonSizing.EXTRA_SMALL}
      onClick={handleDuplicateButtonClick}
    />
  ];

  const handleLineSelected = (id: string, isUnSelected: boolean) => {
    const quotationId = Number(id.split('-')[0]);
    if (isUnSelected) {
      unSelectedQuotations(quotationId);
    } else {
      if (!selectedQuotations.includes(quotationId)) {
        setSelectedQuotations((prevSelected) => [...prevSelected, quotationId]);
      }
    }
  };

  const handleCloseDuplicateQuotationModal = () => {
    setDuplicateQuotationModal(false);
  };

  const openNewWindowButtonClick = (id: string) => {
    const values = id.split('-');
    window.open(
      `/Sales-Quotations/Quotation?quotationId=${values[0]}&companyId=${values[1]}&contactId=${values[2]}&quotationView=updates`,
      '_blank'
    );
  };

  useEffect(() => {
    (async () => {
      const quotations = await get(getQuotations);
      quotationsAction({ type: 'SET_DATA', payload: quotations.data });
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Quotations</Title>
      <Overflow>
        <Array
          template={salesQuotations}
          content={quotationsPreProcessing}
          lineClicked={goToQuotation}
          lineSelected={handleLineSelected}
          pagination={quotationsState}
          updatedArrayData={updateCustomerQuotation}
          url={getQuotations}
          newWindowUrl={openNewWindowButtonClick}
          tools={
            <>
              <Button
                variant={ButtonVariant.PRIMARY}
                text="Quote"
                leftIcon={Cross()}
                onClick={createQuotation}
              />
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
              {!!selectedQuotations.length && (
                <Button
                  variant={ButtonVariant.ERROR}
                  text={`Delete (${selectedQuotations.length}) rows`}
                  leftIcon={Trash()}
                  onClick={deleteQuotation}
                />
              )}
            </>
          }
          moreOptions={quotationMoreOptionToolTip}
        />
      </Overflow>
      <DuplicateQuotationModal
        isDisplayed={duplicateQuotationModal}
        onClose={handleCloseDuplicateQuotationModal}
      />
    </MainContainer>
  );
};

export default Quotations;
