import React, { useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { MainContainer, Title } from 'components/styledComponent';
import {
  Button,
  CustomDatePicker,
  CustomInput,
  CustomSelect,
  CustomTextArea
} from 'components/common';
import { getSelectStyles } from 'utils/style';
import {
  paymentMethodOption,
  taxeRatesOption,
  termPaymentOption,
  addressSelectedTemplate,
  contactSelectedTemplate,
  orderStatusOption,
  useOrderHandler
} from 'utils/sales';
import { AdminsContext, AllCompaniesContactContext, AllCompaniesContext } from 'contexts';
import useApi from 'services/axios';
import {
  getSpecificOrder,
  patchGeneralComment,
  patchOrder,
  postCustomerInvoices,
  postCustomerOrderPDF,
  postCustomerProformaInvoiceOrderPDF,
  putOrderItem
} from 'services/endpoints';
import { Dashboard, Eye, WhiteCross } from 'svgs';
import QuotationRecapPrice from 'components/QuotationRecapPrice';
import SettingCard from 'components/SettingCard';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import {
  OrderUpdateModal,
  CreateSalesDeliveryNoteModal,
  AdvanceInvoicesModal,
  RelatedDocumentModal
} from 'components/modal';

import OrderItems from './OrderItems';

const InputRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CustomContainer = styled.div`
  gap: 4px;
  display: flex;
  flex-direction: column;
`;

const TitleSelect = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #434d56;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 0;
`;

const ArraySectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 16px;
  gap 24px;
`;

const StyledButton = styled(Button)`
  z-index: 10000;
`;

const Order: React.FC = () => {
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { state: allCompaniesContext } = useContext(AllCompaniesContext.Context);
  const { state: allCompaniesContactState } = useContext(AllCompaniesContactContext.Context);
  const [createdDate, setCreatedDate] = useState<string>();
  const [orderDate, setOrderDate] = useState<string>();
  const [unlockItems, setUnlockItems] = useState<number>(0);
  const [clientOrderNumber, setClientOrderNumber] = useState<string>();
  const [reference, setReference] = useState<string>();
  const [order, setOrder] = useState<Order | any>({});
  const [quotationStatus, setQuotationStatus] = useState<Option>();
  const [companyOption, setCompanyOption] = useState<Option>();
  const [salesMan, setSalesMan] = useState<Option>();
  const [admin, setAdmin] = useState<Option>();
  const [paymentMethod, setPaymentMethod] = useState<Option>();
  const [termsPayment, setTermsPayment] = useState<NumberOption>();
  const [taxeRates, setTaxeRates] = useState<NumberOption>();
  const [shippingAddress, setShippingAddress] = useState<Option>();
  const [billingAddress, setBillingAddress] = useState<Option>();
  const [contact, setContact] = useState<Option>();
  const [addressOption, setAddressOption] = useState<Company>();
  const [quotationVersion] = useState<number>();
  const location = useLocation();
  const [orderId, setOrderId] = useState<string>();
  const [modalOrderUpdatedDisplayed, setModalOrderUpdatedDisplayed] = useState<boolean>(false);
  const [modalAdvanceInvoicesDisplayed, setModalAdvanceInvoicesDisplayed] =
    useState<boolean>(false);
  const [modalSalesDeliveryNoteDisplayed, setModalSalesDeliveryNoteDisplayed] =
    useState<boolean>(false);
  const [modalRelatedDocumentDisplayed, setModalRelatedDocument] = useState<boolean>(false);
  const navigate = useNavigate();
  const { put, get, post, patch } = useApi();
  const onChangeOrder = useOrderHandler();

  useEffect(() => {
    setOrderId(new URLSearchParams(window.location.search).get('OrderId'));
  }, [location]);

  const adminFormatted = useMemo(
    () =>
      adminsState.map((admin) => ({
        label: `${admin.last_name} ${admin.first_name}`,
        value: admin.id
      })),
    [adminsState]
  );

  const companyFormatted = useMemo(
    () =>
      allCompaniesContext.map((company) => ({
        label: company.company_name,
        value: company.id
      })),
    [allCompaniesContext]
  );

  const companiesContactFormatted = useMemo(
    () =>
      allCompaniesContactState
        .filter((contact) => contact.company_id === order.company)
        .map((contact) => ({
          label: contactSelectedTemplate(contact),
          value: contact.id
        })),
    [allCompaniesContactState, order]
  );

  const shippingAddressOptionFormatted = useMemo(
    () =>
      addressOption?.addresses
        ? addressOption.addresses
            .filter((address) => address.address_type === 'shipping')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [addressOption]
  );

  const billingAddressOptionFormatted = useMemo(
    () =>
      addressOption?.addresses
        ? addressOption.addresses
            .filter((address) => address.address_type === 'billing')
            .map((address) => ({
              label: addressSelectedTemplate(address),
              value: address.id
            }))
        : [],
    [addressOption]
  );

  const onChangeReference = (name: string) => async (value: string | number) => {
    if (value) {
      const newOrder = await patch(patchOrder(order.id), {
        ...order,
        [name]: value
      });
      setOrder(newOrder.data);
    }
  };

  const handleChangeStatus = onChangeOrder('status', order, setOrder, setQuotationStatus);

  const handleChangeSaleMan = onChangeOrder('sales_person', order, setOrder, setSalesMan);

  const handleChangeAdmin = onChangeOrder('admin', order, setOrder, setAdmin);

  const handleChangeShippingAddress = onChangeOrder(
    'delivery_address',
    order,
    setOrder,
    setShippingAddress
  );

  const handleChangeBillingAddress = onChangeOrder(
    'billing_address',
    order,
    setOrder,
    setBillingAddress
  );

  const handleChangeOrderDate = onChangeOrder('order_date', order, setOrder, setOrderDate);

  const handleCloseOrderUpdatedModal = () => {
    setModalOrderUpdatedDisplayed(false);
  };

  const handleCloseModalAdvanceInvoices = () => {
    setModalAdvanceInvoicesDisplayed(false);
  };

  const handleSalesDeliveryNoteModal = () => {
    setModalSalesDeliveryNoteDisplayed(false);
  };

  const handleCloseRelatedDocumentModal = () => {
    setModalRelatedDocument(false);
  };

  const handleOrderUpdatedModalButtonClick = () => {
    setModalOrderUpdatedDisplayed((status) => !status);
  };

  const orderUpdatedModalClick = async () => {
    const newOrder = await put(putOrderItem(order.id));
    setOrder(newOrder.data);
    setUnlockItems((value) => value + 1);
  };

  const handleCreateAdvanceInvoicesModalClick = async () => {
    await post(postCustomerInvoices(), {
      invoice_type: 'advance',
      company: order.company,
      customer_order: order.id
    });
    navigate('/Sales-Invoices');
  };

  const handleClickCreditNoteButton = () => {
    navigate('/Sales-CreditNotes');
  };

  const handleClickDeliveryNoteButton = () => {
    setModalSalesDeliveryNoteDisplayed(true);
  };

  const handleClickAdvanceInvoicesButton = () => {
    setModalAdvanceInvoicesDisplayed(true);
  };

  const handleDocumentButtonClick = () => {
    setModalRelatedDocument(true);
  };

  const handleChangeGeneralComment = () => {
    const updateGeneralComment = (newGeneralComment: string) => {
      patch(patchGeneralComment() + `?customer_order_id=${order.id}`, {
        content: newGeneralComment
      });
    };

    return updateGeneralComment;
  };

  const handleProformaInvoicesButtonClick = async () => {
    const proformaInvoiceOrderItemPDF = await post(
      postCustomerProformaInvoiceOrderPDF(Number(orderId)),
      {},
      'blob'
    );
    const blob = new Blob([proformaInvoiceOrderItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `proforma-invoice-order-${order.reference}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const handlePDFButtonClick = async () => {
    const orderItemPDF = await post(postCustomerOrderPDF(Number(orderId)), {}, 'blob');
    const blob = new Blob([orderItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `order-${order.reference}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const salesOrderDocumentsToolTip: JSX.Element[] = [
    <StyledButton
      key="delivery-tooltip"
      variant={ButtonVariant.SECONDARY}
      text="Delivery note"
      leftIcon={WhiteCross()}
      sizing={ButtonSizing.EXTRA_SMALL}
      onClick={handleClickDeliveryNoteButton}
    />,
    <Button
      key="performa-tooltip"
      variant={ButtonVariant.SECONDARY}
      text="Advance invoice"
      leftIcon={WhiteCross()}
      sizing={ButtonSizing.EXTRA_SMALL}
      onClick={handleClickAdvanceInvoicesButton}
    />,
    <Button
      key="proforma-invoice-tooltip"
      variant={ButtonVariant.SECONDARY}
      text="Proforma invoice"
      leftIcon={WhiteCross()}
      sizing={ButtonSizing.EXTRA_SMALL}
      onClick={handleProformaInvoicesButtonClick}
    />,
    <Button
      key="credit-tooltip"
      variant={ButtonVariant.SECONDARY}
      text="Credit Note"
      leftIcon={WhiteCross()}
      sizing={ButtonSizing.EXTRA_SMALL}
      onClick={handleClickCreditNoteButton}
    />
  ];

  useEffect(() => {
    setAddressOption(allCompaniesContext.find((company) => company.id === order.company));
  }, [order]);

  useEffect(() => {
    if (Object.keys(order).length) {
      if (order.reference_id_from_customer) {
        setClientOrderNumber(order.reference_id_from_customer);
      }
      if (order.reference) {
        setReference(order.reference);
      }
      if (order.company) {
        setCompanyOption(companyFormatted.find((company) => company.value === order.company));
      }
      if (order.contact) {
        setContact(companiesContactFormatted.find((contact) => contact.value === order.contact));
      }
      if (order.sales_person) {
        setSalesMan(adminFormatted.find((admin) => admin.value === order.sales_person));
      }
      if (order.admin) {
        setAdmin(adminFormatted.find((admin) => admin.value === order.admin));
      }
      if (order.billing_address) {
        setBillingAddress(
          billingAddressOptionFormatted.find((address) => address.value === order.billing_address)
        );
      }
      if (order.delivery_address) {
        setShippingAddress(
          shippingAddressOptionFormatted.find((address) => address.value === order.delivery_address)
        );
      }
      if (order.terms_of_payment) {
        setTermsPayment(
          termPaymentOption.find((option) => option.value === order.terms_of_payment)
        );
      }
      if (order.payment_method) {
        setPaymentMethod(
          paymentMethodOption.find((option) => option.value === order.payment_method)
        );
      }
      if (order.tax_rate) {
        setTaxeRates(taxeRatesOption.find((option) => option.value === order.tax_rate));
      }

      if (order.status) {
        setQuotationStatus(orderStatusOption.find((status) => status.value === order.status));
      }

      if (order.order_date) {
        setOrderDate(order.order_date);
      }

      if (order.created_at) {
        setCreatedDate(order.created_at);
      }
    }
  }, [order, billingAddressOptionFormatted, shippingAddressOptionFormatted]);

  const refreshOrder = async () => {
    const orderDetail = await get(getSpecificOrder(Number(orderId)));
    setOrder(orderDetail.data);
  };

  useEffect(() => {
    if (orderId) {
      (async () => {
        const orderDetail = await get(getSpecificOrder(Number(orderId)));
        setOrder(orderDetail.data);
      })();
    }
  }, [orderId]);

  return (
    <MainContainer>
      <Header>
        <InputRowContainer>
          <StyledTitle>{order.reference ? `Order #${order.reference}` : 'Order'}</StyledTitle>
        </InputRowContainer>
        <InputRowContainer>
          <Button
            variant={ButtonVariant.SECONDARY}
            sizing={ButtonSizing.EXTRA_SMALL}
            text="Unlock items"
            onClick={handleOrderUpdatedModalButtonClick}
            disabled={order.is_last_quotation_version_editable}
          />
          <CustomSelect
            placeholder="Draft"
            value={quotationStatus}
            options={orderStatusOption}
            onChange={handleChangeStatus}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '200px', '50px')}
          />
        </InputRowContainer>
      </Header>
      <InputRowContainer>
        <CustomContainer>
          <CustomInput
            title="Client order number"
            defaultValue={clientOrderNumber}
            placeholder="#00000"
            callBack={() => onChangeReference('reference_id_from_customer')}
          />
        </CustomContainer>
        <CustomContainer>
          <CustomInput
            title="Reference"
            defaultValue={reference}
            placeholder="#00000"
            callBack={() => onChangeReference('reference')}
          />
        </CustomContainer>
      </InputRowContainer>
      <InputRowContainer>
        <CustomContainer>
          <TitleSelect>Company</TitleSelect>
          <CustomSelect
            placeholder="Company"
            value={companyOption}
            options={companyFormatted}
            isDisabled={true}
            styles={getSelectStyles(null, '200px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Salesman</TitleSelect>
          <CustomSelect
            placeholder="Salesman"
            value={salesMan}
            options={adminFormatted}
            onChange={handleChangeSaleMan}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '200px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Admin</TitleSelect>
          <CustomSelect
            placeholder="Admin"
            value={admin}
            options={adminFormatted}
            onChange={handleChangeAdmin}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '150px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Creation date</TitleSelect>
          <CustomDatePicker value={createdDate} isDisabled={true} />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Order Date</TitleSelect>
          <CustomDatePicker
            value={orderDate}
            updatedDate={handleChangeOrderDate}
            isDisabled={true}
          />
        </CustomContainer>
      </InputRowContainer>
      <InputRowContainer>
        <CustomContainer>
          <TitleSelect>Method of payment</TitleSelect>
          <CustomSelect
            placeholder="Method of payment"
            value={paymentMethod}
            options={paymentMethodOption}
            isMulti={false}
            isSearchable={true}
            isDisabled={true}
            styles={getSelectStyles(null, '250px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Terms of payment</TitleSelect>
          <CustomSelect
            placeholder="Terms of payment"
            value={termsPayment}
            options={termPaymentOption}
            isMulti={false}
            isSearchable={true}
            isDisabled={true}
            styles={getSelectStyles(null, '250px', '50px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Taxe rate</TitleSelect>
          <CustomSelect
            placeholder="Taxe rate"
            value={taxeRates}
            options={taxeRatesOption}
            isDisabled={true}
            styles={getSelectStyles(null, '200px', '50px')}
          />
        </CustomContainer>
      </InputRowContainer>
      <InputRowContainer>
        <CustomContainer>
          <TitleSelect>Select billing address</TitleSelect>
          <CustomSelect
            placeholder="Billing address (by Name)"
            value={billingAddress}
            options={billingAddressOptionFormatted}
            onChange={handleChangeBillingAddress}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '250px', '85px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Select shipping address</TitleSelect>
          <CustomSelect
            placeholder="Shipping address (by Name)"
            value={shippingAddress}
            options={shippingAddressOptionFormatted}
            onChange={handleChangeShippingAddress}
            isMulti={false}
            isSearchable={true}
            styles={getSelectStyles(null, '250px', '85px')}
          />
        </CustomContainer>
        <CustomContainer>
          <TitleSelect>Contact</TitleSelect>
          <CustomSelect
            placeholder="Contact"
            value={contact}
            options={companiesContactFormatted}
            isDisabled={true}
            styles={getSelectStyles(null, '250px', '85px')}
          />
        </CustomContainer>
        <CustomTextArea
          title="Billing information"
          value={order.billing_information}
          placeholder="add comment"
          isDisabled={true}
          debounceTime={1000}
        />
      </InputRowContainer>
      <ArraySectionContainer>
        <SettingCard
          title="Order detail"
          icon={Dashboard()}
          buttons={
            <>
              <Button
                variant={ButtonVariant.SECONDARY}
                text="Edit document"
                leftIcon={WhiteCross()}
                sizing={ButtonSizing.EXTRA_SMALL}
                tooltips={salesOrderDocumentsToolTip}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                text="Print client PDF"
                leftIcon={WhiteCross()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handlePDFButtonClick}
              />
              <Button
                variant={ButtonVariant.PRIMARY_LIGHT}
                text="View documents"
                leftIcon={Eye()}
                sizing={ButtonSizing.EXTRA_SMALL}
                onClick={handleDocumentButtonClick}
              />
            </>
          }
        >
          {Object.keys(order).length && (
            <OrderItems orderDetails={order} unlockItems={unlockItems} />
          )}
        </SettingCard>
        <QuotationRecapPrice
          quotationVersion={quotationVersion}
          quotationDetails={order}
          order={order}
          setter={setOrder}
          refresh={refreshOrder}
        />
        <CustomTextArea
          title="General comment"
          value={order.general_comment}
          callBack={handleChangeGeneralComment}
          placeholder="Click to add comment"
          debounceTime={1000}
          isBold={true}
        />
      </ArraySectionContainer>
      <OrderUpdateModal
        isDisplayed={modalOrderUpdatedDisplayed}
        onClose={handleCloseOrderUpdatedModal}
        callback={orderUpdatedModalClick}
      />
      <AdvanceInvoicesModal
        isDisplayed={modalAdvanceInvoicesDisplayed}
        onClose={handleCloseModalAdvanceInvoices}
        callback={handleCreateAdvanceInvoicesModalClick}
      />
      <RelatedDocumentModal
        title="Order's related document"
        queryParam={`customer_order_id=${orderId}`}
        isDisplayed={modalRelatedDocumentDisplayed}
        onClose={handleCloseRelatedDocumentModal}
      />

      {Object.keys(order).length && (
        <CreateSalesDeliveryNoteModal
          isDisplayed={modalSalesDeliveryNoteDisplayed}
          onClose={handleSalesDeliveryNoteModal}
          order={order}
        />
      )}
    </MainContainer>
  );
};

export default Order;
