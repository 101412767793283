import React, { forwardRef, ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

const CustomButton = styled.button`
  max-width: 100%;
  overflow: hidden;

  border: none;
  outline: none;
  box-shadow: none;
  background-color: transparent;

  padding: 12px;
  border: 1px solid #d9dee2;
  border-radius: 10px;

  &:disabled {
    cursor: no-drop;
  }
`;

interface DatePickerHeaderProps {
  isDisabled: boolean;
  value?: string;
  onClick?: ButtonHTMLAttributes<HTMLButtonElement>['onClick'];
}

const DatePickerHeader = forwardRef<HTMLButtonElement, DatePickerHeaderProps>(
  ({ value, onClick, isDisabled }, ref) => (
    <CustomButton
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
      disabled={isDisabled}
    >
      {value}
    </CustomButton>
  )
);

DatePickerHeader.displayName = 'DatePickerHeader';

export default DatePickerHeader;
