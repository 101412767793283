import React, { useEffect, useState } from 'react';

import { Download, Trash, Money } from 'svgs';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import { delProviderPayment, getProviderPayment } from 'services/endpoints';
import { ProviderPaymentModal } from 'components/modal';
import { bankOption } from 'utils/sales';
import { Overflow, capitalizeFirstLetter, useProvidersState } from 'components/Tools';
import Tag, { TagColor } from 'components/common/Tag';

import { MainContainer, Title } from '../styledComponent';
import { Array, providersPayments } from '../array';

enum PaymentMethod {
  TRANSFER = 'transfer',
  CARD = 'card',
  CHECK = 'check'
}

const getProviderPaymentMethodColor = (method: string): TagColor => {
  switch (method) {
    case PaymentMethod.TRANSFER:
      return TagColor.BLUE;

    case PaymentMethod.CARD:
      return TagColor.GREEN;

    case PaymentMethod.CHECK:
      return TagColor.ORANGE;

    default:
      return TagColor.DARKBLUE; // Default color, which can be adjusted if necessary
  }
};

const ProviderPayment: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalProviderPaymentDisplayed, setModalProviderPaymentDisplayed] =
    useState<boolean>(false);
  const [providerPayments, setProviderPayments] = useState<ProviderPaymentContextState>();
  const [providerPaymentsDetailModal, setProviderPaymentsDetailModal] =
    useState<ProviderPayment>(null);
  const [selectedProviderPayments, setSelectedProviderPayments] = useState<number[]>([]);
  const { findProviderById } = useProvidersState();
  const { get, del } = useApi();
  const getProviderName = (providerId: number) => findProviderById(providerId)?.company_name;
  const getBankName = (bank: string) => bankOption.find((option) => option.value === bank)?.label;

  const providerPaymentsPreProcessing = providerPayments?.results.map((providerPayment) => ({
    id: providerPayment.id,
    company: {
      value: getProviderName(providerPayment?.provider),
      display: <div>{getProviderName(providerPayment?.provider)}</div>
    },
    payment_method: {
      value: providerPayment.payment_method,
      display: (
        <Tag
          text={capitalizeFirstLetter(providerPayment.payment_method)}
          color={getProviderPaymentMethodColor(providerPayment.payment_method)}
        />
      )
    },
    bank: {
      value: getBankName(providerPayment.bank),
      display: <div>{getBankName(providerPayment.bank)}</div>
    },
    amount: { value: providerPayment.amount, display: <div>{providerPayment.amount}</div> },
    credit_note_amount: {
      value: providerPayment.credit_note_amount,
      display: <div>{providerPayment.credit_note_amount}</div>
    },
    created_at: {
      value: providerPayment.created_at,
      display: <div>{new Date(providerPayment.created_at).toLocaleDateString('fr-FR')}</div>
    },
    internal_note: {
      value: providerPayment.internal_note,
      display: <div>{providerPayment.internal_note}</div>
    },
    invoice_reference: {
      value: providerPayment.payments.map((payment) => payment.invoice_reference).join('\n'),
      display: (
        <BlueBoldTextContainer>
          {providerPayment.payments.map((payment) => payment.invoice_reference).join('\n')}
        </BlueBoldTextContainer>
      )
    }
  }));

  const unselectedProviderPayments = (id: number) => {
    setSelectedProviderPayments((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deleteProviderPayments = () => {
    selectedProviderPayments.map((CreditId) => {
      del(delProviderPayment(CreditId));
      unselectedProviderPayments(CreditId);
    });
    const newOrderState = providerPayments.results.filter(
      (credit) => !selectedProviderPayments.includes(credit.id)
    );
    providerPayments.results = newOrderState;
    setProviderPayments(providerPayments);
  };

  const updateProviderPayment = (newProviderPayment: ProviderPayment, isNewNote: boolean) => {
    let newProviderPaymentResult;

    if (isNewNote) {
      providerPayments.results.unshift(newProviderPayment);
      newProviderPaymentResult = providerPayments.results;
    } else {
      newProviderPaymentResult = providerPayments.results.map((note) =>
        note.id === newProviderPayment.id ? newProviderPayment : note
      );
    }

    setProviderPayments({ ...providerPayments, results: newProviderPaymentResult });
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    const newProviderPaymentDetail = providerPayments?.results.find(
      (notes) => notes.id == Number(id)
    );
    setProviderPaymentsDetailModal(newProviderPaymentDetail);
    setModalProviderPaymentDisplayed(true);
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedProviderPayments(id);
    } else {
      if (!selectedProviderPayments.includes(id)) {
        setSelectedProviderPayments((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const updateProviderPayments = (newPage: any) => {
    setProviderPayments(newPage);
  };

  const handleClickRegisterProviderPaymentButton = () => {
    setProviderPaymentsDetailModal(null);
    setModalProviderPaymentDisplayed(true);
  };

  const handleCloseSalesProviderPaymentModal = () => {
    setProviderPaymentsDetailModal(null);
    setModalProviderPaymentDisplayed(false);
  };

  useEffect(() => {
    (async () => {
      const providerPayments = await get(getProviderPayment);
      setProviderPayments(providerPayments.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Provider Payment</Title>
      <Overflow>
        <Array
          template={providersPayments}
          content={providerPaymentsPreProcessing}
          lineSelected={handleLineSelected}
          lineClicked={handleLineClicked}
          extended={true}
          pagination={providerPayments}
          updatedArrayData={updateProviderPayments}
          url={getProviderPayment}
          tools={
            <>
              {!!selectedProviderPayments.length && (
                <>
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedProviderPayments.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteProviderPayments}
                  />
                </>
              )}
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Register Payment`}
                leftIcon={Money()}
                onClick={handleClickRegisterProviderPaymentButton}
              />
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
      <ProviderPaymentModal
        isDisplayed={modalProviderPaymentDisplayed}
        onClose={handleCloseSalesProviderPaymentModal}
        payments={providerPaymentsDetailModal}
        callback={updateProviderPayment}
      />
    </MainContainer>
  );
};

export default ProviderPayment;
