import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { Download, Trash } from 'svgs';
import useApi from 'services/axios';
import { delPurchase, getPurchase } from 'services/endpoints';
import { providersPurchases } from 'components/array/template';
import { Array } from 'components/array';
import Tag, { TagColor } from 'components/common/Tag';
import PictureTag from 'components/PictrureTag';
import { AdminsContext } from 'contexts';
import { Overflow, capitalizeFirstLetter, getInitial, useProvidersState } from 'components/Tools';
import { BlueBoldTextContainer } from 'utils/array';

import { MainContainer, Title } from '../../styledComponent';

import PurchaseItems from './PurchaseItems';

enum PurchaseOrderStatus {
  DRAFT = 'draft',
  ACCEPTED = 'accepted',
  NOT_INVOICED = 'not invoiced',
  INVOICED = 'invoiced',
  NOT_PAID = 'not paid',
  PAID = 'paid'
}
enum PurchaseOrderDeliveryStatus {
  NOT_DELIVERED = 'not delivered',
  PARTIAL_DELIVERY = 'partial delivery',
  DELIVERED = 'delivered'
}

const getPurchaseOrderDeliveryStatusColor = (status: string): TagColor => {
  switch (status) {
    case PurchaseOrderDeliveryStatus.NOT_DELIVERED:
      return TagColor.BLUE;

    case PurchaseOrderDeliveryStatus.PARTIAL_DELIVERY:
      return TagColor.ORANGE;

    case PurchaseOrderDeliveryStatus.DELIVERED:
      return TagColor.GREEN;

    default:
      return TagColor.DARKBLUE;
  }
};

const getPurchaseOrderStatusColor = (status: string): TagColor => {
  switch (status) {
    case PurchaseOrderStatus.DRAFT:
      return TagColor.BLUE;

    case PurchaseOrderStatus.ACCEPTED:
      return TagColor.DARKBLUE;

    case PurchaseOrderStatus.NOT_INVOICED:
    case PurchaseOrderStatus.NOT_PAID:
      return TagColor.RED;

    case PurchaseOrderStatus.INVOICED:
      return TagColor.ORANGE;

    case PurchaseOrderStatus.PAID:
      return TagColor.GREEN;

    default:
      return TagColor.DARKBLUE;
  }
};

const Purchases: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [purchases, setPurchases] = useState<PurchasesContext>();
  const [selectedPurchases, setSelectedPurchases] = useState<number[]>([]);
  const { findProviderById } = useProvidersState();
  const { state: adminsState } = useContext(AdminsContext.Context);
  const navigate = useNavigate();
  const { del, get } = useApi();

  const goToPurchase = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];
    navigate(`/Purchases-Purchases/Purchase?PurchaseId=${id}`);
  };

  const getProviderName = (providerId: number) => findProviderById(providerId)?.company_name;

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const purchasesPreProcessing = purchases?.results.map((purchase: Purchases) => ({
    id: purchase.id,
    provider: {
      value: getProviderName(purchase.provider),
      display: <div>{getProviderName(purchase.provider)}</div>
    },
    reference: {
      value: purchase?.reference,
      display: <BlueBoldTextContainer>{purchase?.reference}</BlueBoldTextContainer>
    },
    customer_order: {
      value: purchase?.customer_order?.reference,
      display: <BlueBoldTextContainer>{purchase?.customer_order?.reference}</BlueBoldTextContainer>
    },
    created_at: {
      value: purchase.created_at,
      display: <div>{new Date(purchase.created_at).toLocaleDateString('fr-FR')}</div>
    },
    provider_currency: {
      value: purchase.provider_currency,
      display: <div>{purchase.provider_currency}</div>
    },
    admin: {
      value: getAdminInital(purchase?.admin),
      display: <PictureTag text={getAdminInital(purchase?.admin)} />
    },
    total_price: { value: purchase.net_to_pay, display: purchase.net_to_pay },
    status: {
      value: capitalizeFirstLetter(purchase.status),
      display: (
        <Tag
          text={capitalizeFirstLetter(purchase.status)}
          color={getPurchaseOrderStatusColor(purchase.status)}
        />
      )
    },
    delivery_status: {
      value: capitalizeFirstLetter(purchase.delivery_status),
      display: (
        <Tag
          text={capitalizeFirstLetter(purchase.delivery_status)}
          color={getPurchaseOrderDeliveryStatusColor(purchase.delivery_status)}
        />
      )
    },
    extended: <PurchaseItems purchase={purchase} />
  }));

  const unselectedRequestForQuotations = (id: number) => {
    setSelectedPurchases((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedRequestForQuotations(id);
    } else {
      if (!selectedPurchases.includes(id)) {
        setSelectedPurchases((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const updateProviderPurchases = (newPage: any) => {
    setPurchases(newPage);
  };

  const deletePurchases = () => {
    selectedPurchases.map((purchaseId) => {
      del(delPurchase(purchaseId));
      unselectedRequestForQuotations(purchaseId);
    });
    const newPuchases = purchases.results.filter(
      (purchase) => !selectedPurchases.includes(purchase.id)
    );
    purchases.results = newPuchases;
    setPurchases(purchases);
  };

  const openNewWindowButtonClick = (id: string) => {
    window.open(`/Purchases-Purchases/Purchase?PurchaseId=${id}`, '_blank');
  };

  useEffect(() => {
    (async () => {
      const data = await get(getPurchase);
      setPurchases(data.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Purchases</Title>
      <Overflow>
        <Array
          template={providersPurchases}
          content={purchasesPreProcessing}
          extended={true}
          lineSelected={handleLineSelected}
          lineClicked={goToPurchase}
          pagination={purchases}
          updatedArrayData={updateProviderPurchases}
          url={getPurchase}
          newWindowUrl={openNewWindowButtonClick}
          tools={
            <>
              {!!selectedPurchases.length && (
                <Button
                  variant={ButtonVariant.ERROR}
                  text={`Delete (${selectedPurchases.length}) rows`}
                  leftIcon={Trash()}
                  onClick={deletePurchases}
                />
              )}
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
    </MainContainer>
  );
};

export default Purchases;
