import React from 'react';
import styled, { keyframes } from 'styled-components';

import { DoubleArrow } from 'svgs';

const slideIn = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 0.5;
  }
  to {
    opacity: 0;
  }
`;

const Sidebar = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  min-width: 580px;
  background-color: white;
  padding: 20px;
  overflow-y: auto;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  animation: ${(props) => (props.open ? slideIn : slideOut)} 0.3s forwards;
  z-index: 10001;
`;

const Background = styled.div<{ open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease-in-out;
  animation: ${(props) => (props.open ? fadeIn : fadeOut)} 0.3s forwards;
  z-index: 10000;
`;

const Block = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 29px;
  line-height: 1.5;
  margin-bottom: 4px;
`;

const BackButton = styled.button`
  appearance: none;
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
  outline: none;
`;

const FooterContainer = styled.div`
  margin-top: 16px;
  & > * {
    width: 100%;
  }
`;

interface SidebarProps {
  isOpen: boolean;
  onClose: () => void;
  children: JSX.Element;
  footer?: JSX.Element;
  title?: string;
}

const ModalSidebar: React.FC<SidebarProps> = ({ isOpen, onClose, children, title, footer }) => {
  const handleClickOutside = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.currentTarget === e.target) {
      onClose();
    }
  };

  return (
    open && (
      <>
        <Background open={isOpen} onClick={handleClickOutside} />
        <Sidebar open={isOpen}>
          <BackButton onClick={onClose}>
            <DoubleArrow />
          </BackButton>
          <Block>
            {title && <Title>{title}</Title>}
            {children}
          </Block>
          {footer && <FooterContainer>{footer}</FooterContainer>}
        </Sidebar>
      </>
    )
  );
};

export default ModalSidebar;
