import React from 'react';
import styled from 'styled-components';

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 55px;
  padding: 18px 28px;

  background: ${(props) => props.theme.colors.grey.lighter};
  border-radius: 10px 10px 0px 0px;
`;

const Title = styled.h1`
  margin-left: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;

  color: ${(props) => props.theme.colors.text.primary};
`;

const Content = styled.div`
  padding: 8px 8px;
  background: ${(props) => props.theme.colors.common.white};
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow-x: auto;
`;

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  margin-left: 54px;
`;

type SettingCardProps = {
  title: string;
  icon: JSX.Element;
  children: JSX.Element;
  buttons?: JSX.Element;
};

const SettingCard: React.FC<SettingCardProps> = ({ title, icon, children, buttons }) => {
  return (
    <Container>
      <Header>
        {icon}
        <Title>{title}</Title>
        {buttons && <Buttons>{buttons}</Buttons>}
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export default SettingCard;
