import styled from 'styled-components';

export const MainContainer = styled.div`
  padding: ${(props) => props.theme.spacing.xs}px;
`;

export const Title = styled.h1`
  margin-top: 0px;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.text.primary};
`;

export const SubTitle = styled.h2`
  margin-top: 0px;
  font-weight: 600;
  font-size: 29px;
  line-height: 1.5;
  margin-bottom: 4px;
  color: ${(props) => props.theme.colors.text.primary};
`;
