import { createContext } from 'react';

const initialState: Contact[] = [];

const reducer = (state: Contact[], action: CompaniesContactDispatchAction): Contact[] => {
  switch (action.type) {
    case 'UPDATE_ALL_DATA':
      return action.payload;
    case 'SET_ALL_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type CompaniesContactContextType = {
  state: Contact[];
  dispatch: React.Dispatch<CompaniesContactDispatchAction>;
};

const Context = createContext<CompaniesContactContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'allCompaniesContact';

export { Context, initialState, reducer };
