import React from 'react';

const DoubleArrow = () => {
  return (
    <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.89854 14L15.6055 7.5L8.89854 1"
        stroke="#232C34"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.67588 14L8.38281 7.5L1.67588 1"
        stroke="#232C34"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DoubleArrow;
