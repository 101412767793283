import React from 'react';
import styled from 'styled-components';

export enum TagColor {
  BLUE,
  GREEN,
  ORANGE,
  DARKBLUE,
  RED
}

type colorStyled = {
  color: string;
  background: string;
};

const Container = styled.div<{
  tagColor: colorStyled;
}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: fit-content;

  padding: ${(props) => props.theme.spacing.xxxs}px ${(props) => props.theme.spacing.xs}px;
  gap: ${(props) => props.theme.spacing.xxxs}px;
  border-radius: 40px;

  color: ${(props) => props.tagColor.color};
  background: ${(props) => props.tagColor.background};

  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
`;

const getTagColor = (tagColor: TagColor) => {
  switch (tagColor) {
    case TagColor.DARKBLUE:
      return {
        color: '#1975FF',
        background: 'rgba(25, 117, 255, 0.08)'
      };
    case TagColor.BLUE:
      return {
        color: '#20A1FF',
        background: 'rgba(32, 161, 255, 0.08)'
      };
    case TagColor.GREEN:
      return {
        color: '#65D84C',
        background: 'rgba(101, 216, 60, 0.08)'
      };
    case TagColor.ORANGE:
      return {
        color: '#FFC225',
        background: 'rgba(255, 194, 37, 0.08)'
      };
    case TagColor.RED:
      return {
        color: '#FF4242',
        background: 'rgba(255, 66, 66, 0.08)'
      };

    default:
      return {
        color: '#1A2128',
        background: '#EEF1F3'
      };
  }
};

type TagProps = {
  text: string;
  color: TagColor;
};

const Tag = ({ text, color }: TagProps) => {
  const tagColor = getTagColor(color);

  return <Container tagColor={tagColor}>{text}</Container>;
};

export default Tag;
