import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useApi from 'services/axios';
import { ArrowLeft, ArrowRight } from 'svgs';
import { CustomSelect } from 'components/common';
import { getSelectStyles } from 'utils/style';
import { elementsDisplayedOption } from 'utils/array';

const Row = styled.div`
  display: flex;
  gap: 8px;
  margin: 8px 0;
`;

const StyledLink = styled.a<{
  isSelected?: boolean;
}>`
  color: ${(props) => (props.isSelected ? '#20a1ff' : '#1a2128')};
  text-decoration: ${(props) => (props.isSelected ? '#20a1ff underline' : 'unset')};
  outline: none;
  background: none;
  cursor: pointer;
  border: none;
  display: flex;
  align-items: center;

  &:active {
    color: #20a1ff;
    text-decoration: #20a1ff underline;
  }
`;

const generatePaginationLinks = (currentPage: number, totalPages: number): (number | string)[] => {
  if (totalPages <= 5) return Array.from({ length: totalPages }, (_, i) => i + 1);

  if (currentPage <= 3) {
    return [...Array.from({ length: 4 }, (_, i) => i + 1), '...', totalPages];
  }

  if (currentPage >= totalPages - 2) {
    return [1, '...', ...Array.from({ length: 4 }, (_, i) => totalPages - 3 + i)];
  }

  return [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', totalPages];
};

type FooterProps = {
  pagination: any;
  updatedArrayData: (newPage: any) => void;
  colSpan: number;
  arraySize: Option;
  setArraySize: React.Dispatch<React.SetStateAction<Option>>;
  url?: string;
  searchField?: string;
};

const Footer = ({
  pagination,
  updatedArrayData,
  colSpan,
  arraySize,
  setArraySize,
  url,
  searchField = ''
}: FooterProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [baseUrl, setBaseUrl] = useState<string>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const { get } = useApi();

  const updateSearchQuery = (searchString: string, pageNumber: number) => {
    const searchParams = new URLSearchParams(searchString);
    if (searchParams.has('page')) {
      searchParams.set('page', pageNumber.toString());
    }
    return `?${decodeURIComponent(searchParams.toString())}`;
  };

  const injectBackendToURL = (url: string, directNumber?: number, size?: number) => {
    const urlObj = new URL(url);
    const domain = `${urlObj.protocol.replace('http', 'https')}//${urlObj.host}`;
    if (size !== undefined) {
      urlObj.searchParams.set('page_size', size.toString());
    }
    const search = directNumber ? updateSearchQuery(urlObj.search, directNumber) : urlObj.search;
    const pathAndQuery = urlObj.pathname + search;
    return `${domain}/backend${pathAndQuery}`;
  };

  const handlePreviousLinkClicked = async () => {
    if (pagination.previous) {
      const newData = await get(injectBackendToURL(pagination.previous, null, arraySize.value));
      updatedArrayData(newData.data);
      setCurrentPage((page) => page - 1);
    }
  };

  const handleLinkClicked = async (id: number) => {
    if (id !== currentPage) {
      const newData = await get(injectBackendToURL(baseUrl, id, arraySize.value));
      updatedArrayData(newData.data);
      setCurrentPage(id);
    }
  };

  const handleNextLinkClicked = async () => {
    if (pagination.next) {
      const newData = await get(injectBackendToURL(pagination.next, null, arraySize.value));
      updatedArrayData(newData.data);
      setCurrentPage((page) => page + 1);
    }
  };

  const handleChangeArraySize = async (e: Option) => {
    setArraySize(e);
    const newData = await get(url + '?page_size=' + e.value + '&search_query=' + searchField);
    updatedArrayData(newData.data);
  };

  useEffect(() => {
    if (pagination) {
      setTotalPage(Math.ceil(pagination.count / arraySize.value));
      setBaseUrl(pagination.previous || pagination.next);
    }
  }, [pagination, arraySize.value]);

  return (
    <tfoot>
      <tr>
        <td colSpan={colSpan}>
          <Row>
            <Row>
              <StyledLink href="#previous" onClick={handlePreviousLinkClicked}>
                <ArrowLeft />
              </StyledLink>
              {generatePaginationLinks(currentPage, totalPage).map((link, index) => {
                if (typeof link === 'number') {
                  return (
                    <StyledLink
                      href={`#${link}-${index}`}
                      key={link.toString()}
                      onClick={() => handleLinkClicked(link)}
                      isSelected={link === currentPage}
                    >
                      {link}
                    </StyledLink>
                  );
                } else {
                  return (
                    <StyledLink key={`#${link}-${index}`} href={`#${link}-${index}`}>
                      {link}
                    </StyledLink>
                  );
                }
              })}
              <StyledLink href="#next" onClick={handleNextLinkClicked}>
                <ArrowRight />
              </StyledLink>
            </Row>
            {url && (
              <CustomSelect
                placeholder="20"
                value={arraySize}
                options={elementsDisplayedOption}
                onChange={handleChangeArraySize}
                isMulti={false}
                isSearchable={true}
                styles={getSelectStyles(null, '100px')}
                usePortalStyles={true}
                menuPlacement="top"
              />
            )}
          </Row>
        </td>
      </tr>
    </tfoot>
  );
};

export default Footer;
