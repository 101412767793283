import React from 'react';

const Reload = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.899 17C15.6364 18.2372 13.9073 19 12 19C8.13401 19 5 15.866 5 12C5 8.13401 8.13401 5 12 5C13.9073 5 15.6364 5.76281 16.899 7"
        stroke="#1975ff"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.9998 8.00037L18.4639 6.00037L17.7318 8.73242L14.9998 8.00037Z"
        fill="#1975ff"
        stroke="#1975ff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Reload;
