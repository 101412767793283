import React, { useEffect, useState } from 'react';

import { Download, Trash, Money } from 'svgs';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import { delCustomerPayment, getCustomerPayment } from 'services/endpoints';
import PaymentModal from 'components/modal/PaymentModal';
import { bankOption } from 'utils/sales';
import { Overflow, capitalizeFirstLetter, useCompaniesState } from 'components/Tools';
import Tag, { TagColor } from 'components/common/Tag';

import { Array, salesPayments } from '../array';
import { MainContainer, Title } from '../styledComponent';

enum PaymentMethod {
  TRANSFER = 'transfer',
  CARD = 'card',
  CHECK = 'check'
}

const getPaymentMethodColor = (method: string): TagColor => {
  switch (method) {
    case PaymentMethod.TRANSFER:
      return TagColor.BLUE;

    case PaymentMethod.CARD:
      return TagColor.GREEN;

    case PaymentMethod.CHECK:
      return TagColor.ORANGE;

    default:
      return TagColor.DARKBLUE; // Default color, which can be adjusted if necessary
  }
};

const Payment: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [modalPaymentDisplayed, setModalPaymentDisplayed] = useState<boolean>(false);
  const [payments, setPayments] = useState<PaymentContextState>();
  const [paymentsDetailModal, setPaymentsDetailModal] = useState<Payment>(null);
  const [selectedPayments, setSelectedPayments] = useState<number[]>([]);
  const { findCompanyById } = useCompaniesState();
  const { get, del } = useApi();
  const getCompanyName = (companyId: number) => findCompanyById(companyId)?.company_name;
  const getBankName = (bank: string) => bankOption.find((option) => option.value === bank)?.label;

  const paymentsPreProcessing = payments?.results.map((payment) => ({
    id: payment.id,
    company: {
      value: getCompanyName(payment.company),
      display: <div>{getCompanyName(payment.company)}</div>
    },
    payment_method: {
      value: capitalizeFirstLetter(payment.payment_method),
      display: (
        <Tag
          text={capitalizeFirstLetter(payment.payment_method)}
          color={getPaymentMethodColor(payment.payment_method)}
        />
      )
    },
    bank: { value: getBankName(payment.bank), display: <div>{getBankName(payment.bank)}</div> },
    amount: { value: payment.amount, display: <div>{payment.amount}</div> },
    credit_note_amount: {
      value: payment.credit_note_amount,
      display: <div>{payment.credit_note_amount}</div>
    },
    created_at: {
      value: payment.created_at,
      display: <div>{new Date(payment.created_at).toLocaleDateString('fr-FR')}</div>
    },
    internal_note: { value: payment.internal_note, display: <div>{payment.internal_note}</div> },
    invoice_reference: {
      value: payment.payments.map((payment) => payment.invoice_reference).join('\n'),
      display: (
        <BlueBoldTextContainer>
          {payment.payments.map((payment) => payment.invoice_reference).join('\n')}
        </BlueBoldTextContainer>
      )
    }
  }));

  const unselectedPayments = (id: number) => {
    setSelectedPayments((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const deletePayments = () => {
    selectedPayments.map((CreditId) => {
      del(delCustomerPayment(CreditId));
      unselectedPayments(CreditId);
    });
    const newOrderState = payments.results.filter(
      (credit) => !selectedPayments.includes(credit.id)
    );
    payments.results = newOrderState;
    setPayments(payments);
  };

  const updatePayment = (newPayment: Payment, isNewNote: boolean) => {
    let newPaymentResult;

    if (isNewNote) {
      payments.results.unshift(newPayment);
      newPaymentResult = payments.results;
    } else {
      newPaymentResult = payments.results.map((note) =>
        note.id === newPayment.id ? newPayment : note
      );
    }

    setPayments({ ...payments, results: newPaymentResult });
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];

    const newPaymentDetail = payments?.results.find((notes) => notes.id == Number(id));
    setPaymentsDetailModal(newPaymentDetail);
    setModalPaymentDisplayed(true);
  };

  const updateCustomerPayments = (newPage: any) => {
    setPayments(newPage);
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedPayments(id);
    } else {
      if (!selectedPayments.includes(id)) {
        setSelectedPayments((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const handleClickRegisterPaymentButton = () => {
    setPaymentsDetailModal(null);
    setModalPaymentDisplayed(true);
  };

  const handleCloseSalesPaymentModal = () => {
    setPaymentsDetailModal(null);
    setModalPaymentDisplayed(false);
  };

  useEffect(() => {
    (async () => {
      const payments = await get(getCustomerPayment);
      setPayments(payments.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Payment</Title>
      <Overflow>
        <Array
          template={salesPayments}
          content={paymentsPreProcessing}
          lineSelected={handleLineSelected}
          lineClicked={handleLineClicked}
          extended={true}
          pagination={payments}
          url={getCustomerPayment}
          updatedArrayData={updateCustomerPayments}
          tools={
            <>
              {!!selectedPayments.length && (
                <>
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedPayments.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deletePayments}
                  />
                </>
              )}
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Register payment`}
                leftIcon={Money()}
                onClick={handleClickRegisterPaymentButton}
              />
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
      <PaymentModal
        isDisplayed={modalPaymentDisplayed}
        onClose={handleCloseSalesPaymentModal}
        payments={paymentsDetailModal}
        callback={updatePayment}
      />
    </MainContainer>
  );
};

export default Payment;
