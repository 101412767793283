import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { CrossCircle, Reload } from 'svgs';
import useApi from 'services/axios';
import { patchOrder, patchQuotationVersion } from 'services/endpoints';
import { ButtonSizing, ButtonVariant } from 'components/common/Button';

import { Button, CustomInput } from './common';

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 32px;
  gap: 10px;

  background: linear-gradient(248.85deg, #1975ff -1.77%, #20a1ff 104.39%);
  border-radius: 15px;
`;

const AbsoluteButton = styled.div`
  position: absolute;
  top: 4px;
  left: 4px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 1.5;
  color: #ffffff;
  margin-bottom: 8px;
`;

const Value = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: rgb(255, 255, 255);
  height: 50px;
  display: flex;
  align-items: center;
`;

type QuotationRecapPriceProps = {
  quotationDetails: Partial<Quotation>;
  quotationVersion?: number;
  isEditable?: boolean;
  order?: QuotationVersion | Order;
  setter?: React.Dispatch<any>;
  refresh?: () => Promise<void>;
};

const QuotationRecapPrice: React.FC<QuotationRecapPriceProps> = ({
  quotationVersion,
  quotationDetails,
  isEditable = false,
  order,
  setter,
  refresh
}) => {
  const [quotation, setQuotation] = useState<QuotationVersion | Order>();
  const [downPaymentInputDisplayed, setDownPaymentInputDisplayed] = useState<boolean>(false);
  const { patch } = useApi();

  const getValue = (value: number) => (value ? value : 0);

  const taxeRate = quotation?.tax_rate ? quotation?.tax_rate * 100 : 0;

  const handleClickToAddDownPaymentIcon = () => {
    setDownPaymentInputDisplayed(true);
  };

  const onChangeDownPayment = (name: string) => async (value: string | number) => {
    if (value) {
      const newOrder = await patch(patchOrder(order.id), {
        ...order,
        [name]: value
      });

      setter(newOrder.data);
    }
  };

  const onChangeDiscount = (name: string) => async (value: string | number) => {
    if (value) {
      await patch(
        patchQuotationVersion(
          quotationDetails.company_id,
          quotationDetails.contact_id,
          quotationDetails.id,
          quotationVersion
        ),
        { ...quotationDetails, [name]: value }
      );
    }
  };

  useEffect(() => {
    if (order?.down_payment) {
      setDownPaymentInputDisplayed(true);
    }
  }, [order]);

  useEffect(() => {
    if (order) {
      setQuotation(order);
    } else {
      setQuotation(
        quotationDetails?.quotation_versions?.find((version) => version.id === quotationVersion)
      );
    }
  }, [quotationDetails, quotationVersion, order]);

  return (
    <Container>
      {refresh && (
        <AbsoluteButton>
          <Button
            variant={ButtonVariant.PRIMARY_LIGHT}
            leftIcon={Reload()}
            onClick={refresh}
            sizing={ButtonSizing.ICON}
          />
        </AbsoluteButton>
      )}
      <div>
        <Title>Total before tax</Title>
        <Value>{getValue(quotation?.total_price_without_tax).toFixed(2)}</Value>
      </div>
      <div>
        <Title>Total incl. VAT</Title>
        <Value>{getValue(quotation?.total_price_with_tax).toFixed(2)}</Value>
      </div>
      <div>
        <Title>Total VAT {getValue(taxeRate)}%</Title>
        <Value>{getValue(quotation?.total_tax).toFixed(2)}</Value>
      </div>
      {order && (
        <div>
          <Title>Down Payment (HT)</Title>
          {downPaymentInputDisplayed ? (
            <Value>
              <CustomInput
                defaultValue={quotation?.down_payment}
                callBack={() => onChangeDownPayment('down_payment')}
                inputStyle={0}
                width="120px"
                type="number"
                debounceTime={1000}
              />
            </Value>
          ) : (
            <Value onClick={handleClickToAddDownPaymentIcon}>
              <CrossCircle />
            </Value>
          )}
        </div>
      )}
      <div>
        <Title>Discount</Title>
        {order ? (
          <Value>{getValue(quotation?.discount).toFixed(2)}</Value>
        ) : (
          <Value>
            <CustomInput
              defaultValue={quotation?.discount}
              callBack={() => onChangeDiscount('discount')}
              inputStyle={0}
              width="120px"
              isDisabled={isEditable}
              type="number"
              debounceTime={1000}
            />
          </Value>
        )}
      </div>

      <div>
        <Title>Net to pay</Title>
        <Value>{getValue(quotation?.net_to_pay).toFixed(2)}</Value>
      </div>
    </Container>
  );
};

export default QuotationRecapPrice;
