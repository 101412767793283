import React from 'react';
import styled from 'styled-components';

import { ButtonSizing, ButtonVariant } from 'components/common/Button';
import useApi from 'services/axios';
import { postPurchase } from 'services/endpoints';
import { useProvidersState } from 'components/Tools';

import { Button, Modal } from '../common';
import { MainContainer } from '../styledComponent';

const Item = styled.span`
  font-weight: 700;
  color: ${(props) => props.theme.colors.primary.main};
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 32px;
  padding: 16px;
  justify-content: center;
`;

const CustomMessage = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.5;
`;

enum GroupByKey {
  QUOTATION_ITEM = 'quotation_item',
  REFERENCE = 'reference'
}

type CreatePurchaseModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  orderItems: OrderItem[];
  orderItemsSelected: number[];
};

const CreatePurchaseModal: React.FC<CreatePurchaseModalProps> = ({
  orderItems,
  orderItemsSelected,
  isDisplayed,
  onClose
}) => {
  const { post } = useApi();
  const { findProviderById } = useProvidersState();

  const getProviderName = (ItemProviderId: number) =>
    findProviderById(ItemProviderId)?.company_name;

  const groupByProvider = (
    orderItems: OrderItem[],
    orderItemsSelected: (string | number)[],
    key: GroupByKey
  ) => {
    return orderItemsSelected.reduce(
      (acc: Record<string, (string | number)[]>, selectedItem: string | number) => {
        const foundItem = orderItems.find((item) => item.quotation_item === selectedItem);

        if (foundItem && foundItem.provider) {
          const provider = foundItem.provider;
          const value =
            key === GroupByKey.QUOTATION_ITEM
              ? foundItem.quotation_item
              : foundItem.item_rendered?.product_information?.reference;

          if (value) {
            acc[provider] = acc[provider] || [];
            acc[provider].push(value);
          }
        }

        return acc;
      },
      {}
    );
  };

  const groupedByProviderQuotationId = groupByProvider(
    orderItems,
    orderItemsSelected,
    GroupByKey.QUOTATION_ITEM
  );
  const groupedByProvider = groupByProvider(orderItems, orderItemsSelected, GroupByKey.REFERENCE);

  const handleClickYesButton = async () => {
    const quotationDetail = orderItems.find(
      (item) => item.quotation_item === orderItemsSelected[0]
    );

    const providers = Object.keys(groupedByProviderQuotationId);

    providers.map((provider) => {
      post(postPurchase, {
        quotation_id: quotationDetail.quotation,
        provider: Number(provider),
        quotation_items: groupedByProviderQuotationId[provider]
      });
    });

    onClose();
  };

  const renderConfirmationText = () => {
    const providers = Object.keys(groupedByProvider);

    return providers.map((provider) => (
      <p key={provider}>
        {getProviderName(Number(provider))}: [<Item>{groupedByProvider[provider].join(', ')}</Item>]
        <br />
      </p>
    ));
  };

  return (
    <MainContainer>
      {isDisplayed && (
        <Modal
          isOpen={isDisplayed}
          onClose={onClose}
          title={<p>You are about to create a purchase order for these items:</p>}
        >
          <>
            <CustomMessage>
              {renderConfirmationText()}
              ⚠️ Do you wish to confirm? ⚠️
            </CustomMessage>
            <ButtonContainer>
              <Button
                variant={ButtonVariant.PRIMARY}
                sizing={ButtonSizing.SMALL}
                text="No"
                onClick={onClose}
              />
              <Button
                variant={ButtonVariant.SECONDARY}
                sizing={ButtonSizing.SMALL}
                text="Yes"
                onClick={handleClickYesButton}
              />
            </ButtonContainer>
          </>
        </Modal>
      )}
    </MainContainer>
  );
};

export default CreatePurchaseModal;
