import { createContext } from 'react';

const initialState: ProviderContact[] = [];

const reducer = (
  state: ProviderContact[],
  action: ProvidersContactDispatchAction
): ProviderContact[] => {
  switch (action.type) {
    case 'UPDATE_ALL_DATA':
      return action.payload;
    case 'SET_ALL_DATA':
      return action.payload;
    default:
      throw new Error('unexpected action type');
  }
};

type ProvidersContactContextType = {
  state: ProviderContact[];
  dispatch: React.Dispatch<ProvidersContactDispatchAction>;
};

const Context = createContext<ProvidersContactContextType>({
  state: initialState,
  dispatch: () => null
});

Context.displayName = 'AllProvidersContact';

export { Context, initialState, reducer };
