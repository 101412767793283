import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import pcdOldLogo from '../assets/pcb-old-logo.png';

import { DropDown } from './common';

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 300px;
  min-height: 100vh;
  overflow-y: scroll;
  height: 100%;
  background: ${(props) => props.theme.colors.common.white};

  padding: ${(props) => props.theme.spacing.xs}px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

const NavigationSectionContainer = styled.div``;

const NavigationSectionTitle = styled.h3`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`;

const DropDowns = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.xs}px;
`;

const NavigationLink = styled(Link)``;

const StyledLi = styled.li<{ isSelected?: boolean }>`
  cursor: pointer;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
  color: ${(props) => (props.isSelected ? 'blue' : 'black')};

  ${NavigationLink} {
    cursor: pointer;
    height: 25px;
    font-weight: ${(props) => (props.isSelected ? 700 : 400)};
    font-size: ${(props) => (props.isSelected ? `14px` : `16px`)};
    line-height: ${(props) => (props.isSelected ? `18px` : `24px`)};
    text-decoration: none;
    color: ${(props) =>
      props.isSelected ? props.theme.colors.primary.main : props.theme.colors.common.black};

    ::before {
      content: '•';
      color: ${(props) =>
        props.isSelected ? props.theme.colors.primary.main : props.theme.colors.common.black};
      display: inline-block;
      width: ${(props) => (props.isSelected ? '8px' : '4px')};
      margin-right: 1em;
    }
  }
`;

const Elements = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.theme.spacing.xxxs}px;
  list-style: none;
  margin-bottom: 0;
`;

const NavigationBar: React.FC = () => {
  const location = useLocation();
  const currentUrl = location.pathname;

  const isClientsCompanies = currentUrl.includes('Clients-Companies');
  const isClientsContacts = currentUrl.includes('Clients-Contacts');
  const isProvidersFactories = currentUrl.includes('Providers-Factories');
  const isProvidersContacts = currentUrl.includes('Providers-Contacts');

  const isSalesQuotations = currentUrl.includes('Sales-Quotations');
  const isSalesOrders = currentUrl.includes('Sales-Orders');
  const isSalesDeliveryNotes = currentUrl.includes('Sales-DeliveryNotes');
  const isSalesInvoices = currentUrl.includes('Sales-Invoices');
  const isSalesCreditNotes = currentUrl.includes('Sales-CreditNotes');
  const isSalesPayments = currentUrl.includes('Sales-Payments');

  const isPurchasesRequestForQuotation = currentUrl.includes('Purchases-RequestForQuotation');
  const isPurchasesPurchases = currentUrl.includes('Purchases-Purchases');
  const isPurchasesDeliveryNotes = currentUrl.includes('Purchases-DeliveryNotes');
  const isPurchasesInvoices = currentUrl.includes('Purchases-Invoices');
  const isPurchasesCreditNotes = currentUrl.includes('Purchases-CreditNotes');
  const isPurchasesPayments = currentUrl.includes('Purchases-Payments');

  return (
    <Container>
      <img src={pcdOldLogo} alt="img" width="45" height="45"></img>
      <NavigationSectionContainer>
        <NavigationSectionTitle>Contacts</NavigationSectionTitle>
        <DropDowns>
          <DropDown title="Clients" showContent={isClientsCompanies || isClientsContacts}>
            <Elements>
              <StyledLi isSelected={isClientsCompanies}>
                <NavigationLink to="/Clients-Companies">Companies</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isClientsContacts}>
                <NavigationLink to="/Clients-Contacts">Companies contacts</NavigationLink>
              </StyledLi>
            </Elements>
          </DropDown>
          <DropDown title="Providers" showContent={isProvidersFactories || isProvidersContacts}>
            <Elements>
              <StyledLi isSelected={isProvidersFactories}>
                <NavigationLink to="/Providers-Factories">Providers factories</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isProvidersContacts}>
                <NavigationLink to="/Providers-Contacts">Providers contacts</NavigationLink>
              </StyledLi>
            </Elements>
          </DropDown>
        </DropDowns>
      </NavigationSectionContainer>
      <NavigationSectionContainer>
        <NavigationSectionTitle>Business</NavigationSectionTitle>
        <DropDowns>
          <DropDown
            title="Sales"
            showContent={
              isSalesQuotations ||
              isSalesOrders ||
              isSalesDeliveryNotes ||
              isSalesInvoices ||
              isSalesCreditNotes ||
              isSalesPayments
            }
          >
            <Elements>
              <StyledLi isSelected={isSalesQuotations}>
                <NavigationLink to="/Sales-Quotations">Quotations</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isSalesOrders}>
                <NavigationLink to="/Sales-Orders">Orders</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isSalesDeliveryNotes}>
                <NavigationLink to="/Sales-DeliveryNotes">Delivery notes</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isSalesInvoices}>
                <NavigationLink to="/Sales-Invoices">Invoices</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isSalesCreditNotes}>
                <NavigationLink to="/Sales-CreditNotes">Credit notes</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isSalesPayments}>
                <NavigationLink to="/Sales-Payments">Payments</NavigationLink>
              </StyledLi>
            </Elements>
          </DropDown>
          <DropDown
            title="Purchases"
            showContent={
              isPurchasesRequestForQuotation ||
              isPurchasesPurchases ||
              isPurchasesDeliveryNotes ||
              isPurchasesInvoices ||
              isPurchasesCreditNotes ||
              isPurchasesPayments
            }
          >
            <Elements>
              <StyledLi isSelected={isPurchasesRequestForQuotation}>
                <NavigationLink to="/Purchases-RequestForQuotation">
                  Request for quotation
                </NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isPurchasesPurchases}>
                <NavigationLink to="/Purchases-Purchases">Purchases</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isPurchasesDeliveryNotes}>
                <NavigationLink to="/Purchases-DeliveryNotes">Delivery notes</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isPurchasesInvoices}>
                <NavigationLink to="/Purchases-Invoices">Invoices</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isPurchasesCreditNotes}>
                <NavigationLink to="/Purchases-CreditNotes">Credit notes</NavigationLink>
              </StyledLi>
              <StyledLi isSelected={isPurchasesPayments}>
                <NavigationLink to="/Purchases-Payments">Payments</NavigationLink>
              </StyledLi>
            </Elements>
          </DropDown>
        </DropDowns>
      </NavigationSectionContainer>
    </Container>
  );
};

export default NavigationBar;
