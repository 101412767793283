import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { getSelectStyles } from 'utils/style';
import { AllProvidersContext } from 'contexts';
import useApi from 'services/axios';
import { patchProviderAddress, postCreateProviderAddress } from 'services/endpoints';
import { AddressTypeOption } from 'utils/provider';

import { Button, CustomInput, CustomSelect, CustomTextArea, ModalSidebar } from '../common';
import { MainContainer } from '../styledComponent';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Content = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;

const SelectedTitle = styled.div`
  font-size: 14px;
  line-height: 1.5;
  color: #434d56;
`;

const SelectWrapper = styled.div`
  max-width: 250px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

type ProviderAddressModalProps = {
  isDisplayed: boolean;
  onClose: () => void;
  addressDetail: any;
};

const ProviderAddressModal: React.FC<ProviderAddressModalProps> = ({
  addressDetail,
  isDisplayed,
  onClose
}) => {
  const [address, setAddress] = useState<any>({});
  const [addressType, setAddressType] = useState<Option>();
  const { state: allProvidersState, dispatch: allProvidersAction } = useContext(
    AllProvidersContext.Context
  );
  const { post, patch } = useApi();
  const providerId = new URLSearchParams(window.location.search).get('providerId');

  const modalTitle = addressDetail.id ? 'Update provider address' : 'New provider address';

  const createProviderAddress = async () => {
    const newAddress = await post(postCreateProviderAddress(Number(providerId)), {
      ...address
    });

    const newProvidersState = [...allProvidersState];

    newProvidersState
      .find((provider) => provider.id === Number(providerId))
      .addresses.unshift(newAddress.data);

    allProvidersAction({
      type: 'UPDATE_ALL_DATA',
      payload: newProvidersState
    });

    onClose();
  };

  const updateProviderAddress = async () => {
    const newAddress = await patch(patchProviderAddress(Number(providerId), addressDetail.id), {
      ...address
    });

    const newProvidersState = allProvidersState.map((provider) =>
      provider.id === Number(providerId)
        ? {
            ...provider,
            addresses: provider.addresses.map((currentAddress) =>
              currentAddress.id === addressDetail.id ? newAddress.data : currentAddress
            )
          }
        : provider
    );

    allProvidersAction({
      type: 'UPDATE_ALL_DATA',
      payload: newProvidersState
    });

    onClose();
  };

  const handleSaveButton = addressDetail.id ? updateProviderAddress : createProviderAddress;

  const handleChangeSelect = (
    name: string,
    option: Option,
    updateState: (option: Option) => void
  ) => {
    setAddress({
      ...address,
      [name]: option.value
    });
    updateState(option);
  };

  const handleChange = (name: string) => (value: string | number) => {
    setAddress({
      ...address,
      [name]: value
    });
  };

  useEffect(() => {
    setAddress(addressDetail);
    if (addressDetail.address_type) {
      setAddressType(
        AddressTypeOption.find((option) => option.value === addressDetail.address_type)
      );
    }
  }, [addressDetail]);

  return (
    <MainContainer>
      {isDisplayed && (
        <ModalSidebar isOpen={isDisplayed} onClose={onClose} title={modalTitle}>
          <Content>
            <Row>
              <SelectWrapper>
                <SelectedTitle>Type</SelectedTitle>
                <CustomSelect
                  placeholder="Type"
                  value={addressType}
                  options={AddressTypeOption}
                  onChange={(e) => {
                    handleChangeSelect('address_type', e, setAddressType);
                  }}
                  isMulti={false}
                  isSearchable={true}
                  styles={getSelectStyles(null, '250px', '50px')}
                />
              </SelectWrapper>

              <CustomInput
                title="Name"
                defaultValue={addressDetail.address_name}
                placeholder="Address name"
                callBack={() => handleChange('address_name')}
              />
            </Row>
            <CustomTextArea
              title="Address"
              value={addressDetail.address_1}
              placeholder="Address"
              callBack={() => handleChange('address_1')}
            />
            <Row>
              <CustomInput
                title="Zip"
                defaultValue={addressDetail.postal_code}
                placeholder="Postal code"
                callBack={() => handleChange('postal_code')}
              />
              <CustomInput
                title="City"
                defaultValue={addressDetail.city}
                placeholder="City"
                callBack={() => handleChange('city')}
              />
            </Row>
            <Row>
              <CustomInput
                title="State"
                defaultValue={addressDetail.state}
                placeholder="State"
                callBack={() => handleChange('state')}
              />
              <CustomInput
                title="Country"
                defaultValue={addressDetail.country}
                placeholder="Country"
                callBack={() => handleChange('country')}
              />
            </Row>
            <ButtonWrapper>
              <Button text="Save" onClick={handleSaveButton} variant={1} sizing={1} />
            </ButtonWrapper>
          </Content>
        </ModalSidebar>
      )}
    </MainContainer>
  );
};

export default ProviderAddressModal;
