import React, { useEffect } from 'react';
import styled from 'styled-components';

import { CheckMarkGreen, Close } from 'svgs';

const MainContainer = styled.div<{ isDisplayed: boolean }>`
  position: fixed;
  bottom: 32px;
  width: 500px;
  transform: translateX(50%);
  background: #e5f9e7;
  border-radius: 5px;
  padding: 16px;
  text-align: left;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease-in-out, visibility 0.1s, opacity 0.1s;
  transform: ${({ isDisplayed }) => (isDisplayed ? 'translateY(0)' : 'translateY(100%)')};
  opacity: ${({ isDisplayed }) => (isDisplayed ? '1' : '0')};
  visibility: ${({ isDisplayed }) => (isDisplayed ? 'visible' : 'hidden')};
  z-index: 1000;
  display: flex;
  align-items: center;
`;

const CloseButton = styled.div`
  display: flex;
  position: absolute;
  right: 10px;
  cursor: pointer;
  align-items: center;
`;

const Message = styled.div`
  padding: 0 8px;
`;

type SuccessProps = {
  isDisplayed: boolean;
  onClose: () => void;
  text: JSX.Element;
};

const Success: React.FC<SuccessProps> = ({ isDisplayed, onClose, text }) => {
  useEffect(() => {
    if (isDisplayed) {
      const timer = setTimeout(onClose, 3000);
      return () => clearTimeout(timer);
    }
  }, [isDisplayed, onClose]);

  return (
    <MainContainer isDisplayed={isDisplayed}>
      <CheckMarkGreen />
      <Message>{text}</Message>
      <CloseButton onClick={onClose}>
        <Close />
      </CloseButton>
    </MainContainer>
  );
};

export default Success;
