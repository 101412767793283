import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { AdminsContext } from 'contexts';
import { WhiteCross, Download, Trash } from 'svgs';
import { Button, SmallLoader } from 'components/common';
import { ButtonVariant } from 'components/common/Button';
import { BlueBoldTextContainer } from 'utils/array';
import useApi from 'services/axios';
import Tag, { TagColor } from 'components/common/Tag';
import {
  delCreditNote,
  getCustomerCreditNote,
  postCustomerCreditNotePDF
} from 'services/endpoints';
import { CreditNoteModal } from 'components/modal';
import PictureTag from 'components/PictrureTag';
import { capitalizeFirstLetter, getInitial, useCompaniesState } from 'components/Tools';

import { Array, salesCreditNotes } from '../array';
import { MainContainer, Title } from '../styledComponent';

const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow-x: auto;
`;

enum CreditNoteStatus {
  USED = 'used',
  PARTIALLY_USED = 'partially used',
  NOT_USED = 'not used'
}

const getCreditNoteStatusColor = (status: string): TagColor => {
  switch (status) {
    case CreditNoteStatus.USED:
      return TagColor.GREEN;

    case CreditNoteStatus.PARTIALLY_USED:
      return TagColor.ORANGE;

    case CreditNoteStatus.NOT_USED:
      return TagColor.RED;

    default:
      return TagColor.BLUE; // Default color, which can be adjusted as needed
  }
};

const CreditNote: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [creditNotes, setCreditNotes] = useState<CreditNotesContext>();
  const [creditDetailModal, setCreditDetailModal] = useState<CreditNotes>(null);
  const [selectedCreditNotes, setSelectedCreditNotes] = useState<number[]>([]);
  const [modalSalesCreditNoteDisplayed, setModalSalesCreditNoteDisplayed] =
    useState<boolean>(false);
  const { state: adminsState } = useContext(AdminsContext.Context);
  const { findCompanyById } = useCompaniesState();
  const { del, get, post } = useApi();
  const getCompanyName = (companyId: number) => findCompanyById(companyId)?.company_name;

  const handleSalesCreditNoteModal = () => {
    setCreditDetailModal(null);
    setModalSalesCreditNoteDisplayed(false);
  };

  const getAdminInital = (adminId: number) => {
    const admin = adminsState.find((admin: Admin) => admin.id === adminId);

    if (admin) {
      return getInitial(admin.first_name, admin.last_name);
    }

    return `__`;
  };

  const creditNotesPreProcessing = creditNotes?.results.map((creditNote) => ({
    id: creditNote.id,
    company: {
      value: getCompanyName(creditNote.company),
      display: <div>{getCompanyName(creditNote.company)}</div>
    },
    reference: {
      value: creditNote.reference,
      display: <BlueBoldTextContainer>{creditNote.reference}</BlueBoldTextContainer>
    },
    note: { value: creditNote.note, display: <div>{creditNote.note}</div> },
    credit_note_date: {
      value: creditNote.credit_note_date,
      display: <div>{new Date(creditNote.credit_note_date).toLocaleDateString('fr-FR')}</div>
    },
    sales_person: {
      value: getAdminInital(creditNote?.sales_person),
      display: <PictureTag text={getAdminInital(creditNote?.sales_person)} />
    },
    admin: {
      value: getAdminInital(creditNote?.admin),
      display: <PictureTag text={getAdminInital(creditNote?.admin)} />
    },
    status: {
      value: capitalizeFirstLetter(creditNote.status),
      display: (
        <Tag
          text={capitalizeFirstLetter(creditNote.status)}
          color={getCreditNoteStatusColor(creditNote.status)}
        />
      )
    },
    amount: { value: creditNote.amount, display: <div>{creditNote.amount}</div> },
    amount_used: { value: creditNote.amount_used, display: <div>{creditNote.amount_used}</div> }
  }));

  const getCreditNoteDocument = async (id: number) => {
    const CreditNoteItemPDF = await post(postCustomerCreditNotePDF(id), {}, 'blob');
    const blob = new Blob([CreditNoteItemPDF.data], { type: 'application/pdf' });

    if (blob.size > 0) {
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `creditnote-${id}.pdf`;
      a.click();

      URL.revokeObjectURL(url);
    }
  };

  const handlePDFButtonClick = () => {
    selectedCreditNotes.map(async (noteId) => {
      getCreditNoteDocument(noteId);
    });
  };

  const unselectedCreditNotes = (id: number) => {
    setSelectedCreditNotes((prevSelected) => prevSelected.filter((obj) => obj !== id));
  };

  const handleLineClicked = (event: React.MouseEvent<HTMLTableRowElement>) => {
    const id = event.currentTarget.id.split('-')[1];

    const newCreditNotesDetail = creditNotes?.results.find((notes) => notes.id == id);
    setCreditDetailModal(newCreditNotesDetail);
    setModalSalesCreditNoteDisplayed(true);
  };

  const updateCustomerCreditNote = (newPage: any) => {
    setCreditNotes(newPage);
  };

  const updateCreditNote = (newNote: DeliveryNote, isNewNote: boolean) => {
    let newCreditNoteResult;

    if (isNewNote) {
      creditNotes.results.unshift(newNote);
      newCreditNoteResult = creditNotes.results;
    } else {
      newCreditNoteResult = creditNotes.results.map((note) =>
        note.id === newNote.id ? newNote : note
      );
    }

    setCreditNotes({ ...creditNotes, results: newCreditNoteResult });
  };

  const deleteCreditNotes = () => {
    selectedCreditNotes.map((CreditId) => {
      del(delCreditNote(CreditId));
      unselectedCreditNotes(CreditId);
    });
    const newOrderState = creditNotes.results.filter(
      (credit) => !selectedCreditNotes.includes(credit.id)
    );
    creditNotes.results = newOrderState;
    setCreditNotes(creditNotes);
  };

  const handleLineSelected = (id: number, isUnSelected: boolean) => {
    if (isUnSelected) {
      unselectedCreditNotes(id);
    } else {
      if (!selectedCreditNotes.includes(id)) {
        setSelectedCreditNotes((prevSelected) => [...prevSelected, id]);
      }
    }
  };

  const handleClickCreateNoteButton = () => {
    setModalSalesCreditNoteDisplayed(true);
  };

  useEffect(() => {
    (async () => {
      const creditNotes = await get(getCustomerCreditNote);
      setCreditNotes(creditNotes.data);
      setIsLoading(false);
    })();
  }, []);

  if (isLoading) {
    return <SmallLoader />;
  }

  return (
    <MainContainer>
      <Title>Credit note</Title>
      <Overflow>
        <Array
          template={salesCreditNotes}
          content={creditNotesPreProcessing}
          lineSelected={handleLineSelected}
          lineClicked={handleLineClicked}
          extended={true}
          pagination={creditNotes}
          updatedArrayData={updateCustomerCreditNote}
          url={getCustomerCreditNote}
          tools={
            <>
              <Button
                variant={ButtonVariant.SECONDARY}
                text={`Credit note`}
                leftIcon={WhiteCross()}
                onClick={handleClickCreateNoteButton}
              />
              {!!selectedCreditNotes.length && (
                <>
                  <Button
                    variant={ButtonVariant.ERROR}
                    text={`Delete (${selectedCreditNotes.length}) rows`}
                    leftIcon={Trash()}
                    onClick={deleteCreditNotes}
                  />
                  <Button
                    variant={ButtonVariant.PRIMARY}
                    text={`Download Credit Note`}
                    leftIcon={Download()}
                    onClick={handlePDFButtonClick}
                  />
                </>
              )}
              <Button variant={ButtonVariant.PRIMARY} text="Export" leftIcon={Download()} />
            </>
          }
        />
      </Overflow>
      <CreditNoteModal
        isDisplayed={modalSalesCreditNoteDisplayed}
        onClose={handleSalesCreditNoteModal}
        creditNotes={creditDetailModal}
        callback={updateCreditNote}
      />
    </MainContainer>
  );
};

export default CreditNote;
